import React from "react";
import { motion } from "framer-motion";
import { LogIn, UserPlus, Menu } from "lucide-react";
import "./homeHeader.css"; // Import the custom CSS file
import { useNavigate } from "react-router-dom";

const NavLink = ({ href, children }) => (
  <motion.a href={href} className="nav-link" whileHover={{ scale: 1.05 }}>
    {children}
  </motion.a>
);

const MobileNavLink = ({ href, children }) => (
  <motion.a
    href={href}
    className="menu-item"
    whileHover={{ backgroundColor: "#F3F4F6" }}
  >
    {children}
  </motion.a>
);

const HomeHeader = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const navigate = useNavigate();

  return (
    <motion.nav initial={{ y: -100 }} animate={{ y: 0 }} className="navbar">
      <div className="container">
        <div className="header-content">
          <motion.div
            className="logo"
            whileHover={{ scale: 1.05 }}
            onClick={() => navigate("/")}
          >
            <img
              style={{ width: "150px", marginTop: "6px" }}
              src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
              alt="nav-logo"
            />
          </motion.div>
          <div className="nav-links-buttons">
            <div className="nav-links">
              <NavLink href="#home">Home</NavLink>
              <NavLink href="#services">Services</NavLink>
              <NavLink href="#features">Features</NavLink>
              <NavLink href="#pricing">Pricing</NavLink>
              <NavLink href="#support">Support</NavLink>
            </div>

            <div
              className="nav-buttons"
              style={{ display: "flex", gap: "8px" }}
            >
              <motion.button
                onClick={() => {
                  navigate("/login");
                }}
                whileHover={{ scale: 1.05 }}
                className="signin"
              >
                <LogIn size={20} />
                Sign In
              </motion.button>

              <motion.button
                onClick={() => {
                  navigate("/signup");
                }}
                whileHover={{ scale: 1.05 }}
                className="signup"
              >
                <UserPlus size={20} />
                Sign Up
              </motion.button>
            </div>
          </div>

          <div className="menu-icon">
            <button
              style={{
                background: "none",
                border: "2px solid gray",
                borderRadius: "5px",
                paddingTop: "2px",
              }}
              onClick={() => setIsOpen(!isOpen)}
            >
              <Menu size={24} />
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <motion.div
        initial={{ maxHeight: 0, opacity: 0, scale: 0.95 }}
        animate={{
          maxHeight: isOpen ? 1000 : 0, // Expands to maxHeight when open, collapses when closed
          opacity: isOpen ? 1 : 0, // Fade effect
          scale: isOpen ? 1 : 0.95, // Slight scale change when opening/closing
        }}
        transition={{
          maxHeight: { duration: 0.3, ease: "easeInOut" },
          opacity: { duration: 0.2 },
          scale: { duration: 0.3, ease: "easeInOut" },
        }}
        className={`mobile-menu ${isOpen ? "open" : ""}`}
      >
        <div className="px-2 pt-2 pb-3 space-y-1">
          <button
            style={{
              textAlign: "left",
              width: "100%",
              border: "none",
              background: "none",
            }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <MobileNavLink href="#home">Home</MobileNavLink>
          </button>
          <button
            style={{
              textAlign: "left",
              width: "100%",
              border: "none",
              background: "none",
            }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <MobileNavLink href="#services">Services</MobileNavLink>
          </button>
          <button
            style={{
              textAlign: "left",
              width: "100%",
              border: "none",
              background: "none",
            }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <MobileNavLink href="#features">Features</MobileNavLink>
          </button>
          <button
            style={{
              textAlign: "left",
              width: "100%",
              border: "none",
              background: "none",
            }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <MobileNavLink href="#support">Support</MobileNavLink>
          </button>
          <button
            style={{
              textAlign: "left",
              width: "100%",
              border: "none",
              background: "none",
            }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <MobileNavLink href="#pricing">Pricing</MobileNavLink>
          </button>
          <button
            style={{
              textAlign: "left",
              width: "100%",
              border: "none",
              background: "none",
            }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <MobileNavLink href="/login">Sign In</MobileNavLink>
          </button>
          <button
            style={{
              textAlign: "left",
              width: "100%",
              border: "none",
              background: "none",
            }}
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <MobileNavLink href="/signup">Sign Up</MobileNavLink>
          </button>
        </div>
      </motion.div>
    </motion.nav>
  );
};

export default HomeHeader;

import { Suspense, useEffect } from "react";
import axios from "axios";

import { FaSquareInstagram } from "react-icons/fa6";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaSquareYoutube } from "react-icons/fa6";
//Adarsh
import HomeHeader from "components/homeHeader/homeHeader";
import AOS from "aos";
import "aos/dist/aos.css";

import "./homePage.css";
import { useNavigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Hero from "./hero";
import FeatureSection from "./features";
import Services from "./services";
import Contact from "./contact";
import WhiteLabelSolutions from "./whiteLabelSolutions";
import Packages from "./packages";

const HomePage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/jwt/verify/`, { token })
        .then((res) => {
          if (res.status === 200) {
            navigate("/dashboard");
          } else {
            navigate("/login");
          }
        })
        .catch((error) => {
          console.error("Token verification failed:", error);
          navigate("/login");
        });
    }
  }, [navigate]);

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  const renderFooter = () => {
    return (
      <footer className="mt-4 pt-4 text-center pb-3 text-light footer">
        <h1
          style={{ marginBottom: "10px", fontSize: "2rem" }}
          className="section-font-heading"
        >
          Follow Us
        </h1>
        <p className="section-matter" style={{ fontSize: "1rem" }}>
          Stay updated with the latest news and updates by following us on
          social media.
        </p>
        <div
          className="mt-4"
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <a
            className="icon me-1 me-md-4"
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ margin: "0 10px" }}
          >
            <FaSquareInstagram size={20} />
          </a>
          <a
            className="icon me-1 me-md-4"
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ margin: "0 10px" }}
          >
            <FaSquareFacebook size={20} />
          </a>
          <a
            className="icon me-1 me-md-4"
            href="https://www.twitter.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ margin: "0 10px" }}
          >
            <FaSquareXTwitter size={20} />
          </a>
          <a
            className="icon"
            href="https://www.youtube.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ margin: "0 10px" }}
          >
            <FaSquareYoutube size={20} />
          </a>
        </div>

        <p style={{ marginTop: "15px", fontSize: "1rem" }}>
          Maintained By Infinitum Tech
        </p>
        <div
          style={{
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          <a
            href="/terms-of-service"
            target="_blank"
            style={{
              color: "#3b82f6",
              textDecoration: "none",
              fontSize: "1rem",
            }}
          >
            Terms of Service
          </a>
          |
          <a
            href="/privacy-policy"
            target="_blank"
            style={{
              color: "#3b82f6",
              textDecoration: "none",
              fontSize: "1rem",
            }}
          >
            Privacy Policy
          </a>
          |
          <h3 className="section-font" style={{ fontSize: "1rem" }}>
            Copyright © 2024 Infinitum Tech. All rights reserved
          </h3>
        </div>
      </footer>
    );
  };

  const LoadingSpinner = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
      }}
    >
      <div
        style={{
          animation: "spin 1s linear infinite",
          borderRadius: "50%",
          height: "3rem", // 12 in Tailwind is 3rem (48px)
          width: "3rem", // 12 in Tailwind is 3rem (48px)
          borderTop: "2px solid #4C51BF", // Indigo color
          borderBottom: "2px solid #4C51BF", // Indigo color
        }}
      ></div>
    </div>
  );

  return (
    <AnimatePresence>
      <div
        style={{
          minWidth: "370px",
          position: "relative",
        }}
      >
        <HomeHeader />
        <Suspense fallback={<LoadingSpinner />}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <div style={{ paddingTop: "64px" }}>
              <Hero />
              <WhiteLabelSolutions />
              <Services />
              <FeatureSection />
              <Packages />
              <Contact />
              {renderFooter()}
            </div>
          </motion.div>
          {/* <div style={{ paddingTop: "80px" }}>{renderContactUs()}</div>
          {renderFooter()} */}
        </Suspense>
      </div>
    </AnimatePresence>
  );
};

export default HomePage;

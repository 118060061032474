import { useState, useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  CircularProgress,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { routeNames } from "constants";
import { useAppDataContext } from "hooks/useappdatacontext";
import styles from "./streamModal.module.css";
import { useAuthContext } from "../../hooks/useauthcontext";

const StreamModal = ({ selectedRowId, open, handleClose, title, edit }) => {
  const { userData } = useAuthContext();

  const navigate = useNavigate();

  // Contexts:
  const { regions, fetchRegions, handleProceed, userCredits, loading } =
    useAppDataContext();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 2000); // Wait for 1 second before rendering the modal

    return () => clearTimeout(timer); // Cleanup the timer
  }, []);

  useEffect(() => {
    fetchRegions();
  }, []);

  // States:
  const [formState, setFormState] = useState({
    name: title,
    region: "",
    recording: "True",
  });
  const [error, setError] = useState(false);

  useEffect(() => {
    if (regions) {
      const defaultRegion = regions.find((region) => region.name === "Mumbai");
      if (defaultRegion) {
        setFormState((prevState) => ({
          ...prevState,
          region: defaultRegion.name,
        }));
      }
    }
  }, [regions]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCreateChannel = async () => {
    const selectedRegion = regions.find(
      (region) => region.name === formState.region
    );
    const payload = {
      ...formState,
      region: selectedRegion ? selectedRegion.region_id : "",
    };
    if (userCredits?.credits > 0 || userData?.is_admin) {
      const des = await handleProceed(selectedRowId, edit, payload);
      if (des === false) {
        setError(true);
      }
      if (des === true) {
        handleClose();
        setError(false);
      }
    }
  };

  const handleErrorModalClose = () => {
    setError(false);
  };

  const renderStreamModal = () => {
    return (
      <Modal
        open={open}
        onClose={handleClose}
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            width: { xs: "90%", sm: 500 },
            bgcolor: "background.paper",
            p: 4,
            borderRadius: 2,
            boxShadow: 24,
          }}
        >
          {(userCredits && userCredits?.credits > 0) ||
          (userData && userData?.is_admin) ? (
            <>
              <Typography variant="h6" gutterBottom>
                <strong>LIVE SetUp</strong>
              </Typography>
              <Typography variant="h6" gutterBottom>
                Event Name:{" "}
                <strong style={{ textTransform: "capitalize" }}>
                  {formState.name}
                </strong>
              </Typography>

              {showModal ? (
                <FormControl sx={{ width: "400px" }}>
                  <InputLabel id="region-label">Nearest Region</InputLabel>
                  <Select
                    labelId="region-label"
                    name="region"
                    value={formState.region}
                    onChange={handleChange}
                    label="Nearest Region"
                  >
                    {regions?.map((region) => (
                      <MenuItem key={region.region_id} value={region.name}>
                        {region.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <div className={styles.regionFetchingLoader}></div>
              )}
              <div style={{ margin: "20px 0" }}>
                <h3
                  style={{
                    color: "red",
                    fontSize: "1.5rem",
                    marginBottom: "15px",
                  }}
                >
                  Note
                </h3>
                <p
                  style={{
                    backgroundColor: "#f9f9f9",
                    borderLeft: "4px solid #4CAF50",
                    padding: "10px 15px",
                    marginBottom: "10px",
                    fontSize: "0.8rem",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <strong>1.</strong> Streaming will only be available for
                  recorded events during a specific period.
                </p>
                <p
                  style={{
                    backgroundColor: "#f9f9f9",
                    borderLeft: "4px solid #4CAF50",
                    padding: "10px 15px",
                    marginBottom: "10px",
                    fontSize: ".8rem",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <strong>2.</strong> These keys will only be available on the
                  scheduled event date.
                </p>
                <p
                  style={{
                    backgroundColor: "#f9f9f9",
                    borderLeft: "4px solid #4CAF50",
                    padding: "10px 15px",
                    marginBottom: "10px",
                    fontSize: "0.8rem",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <strong>3.</strong> Once streaming starts, you cannot change
                  the source (e.g., RTMP to YouTube).
                </p>
                <p
                  style={{
                    backgroundColor: "#f9f9f9",
                    borderLeft: "4px solid #4CAF50",
                    padding: "10px 15px",
                    marginBottom: "10px",
                    fontSize: "0.8rem",
                    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <strong>4.</strong> Users can take Only Onve Live stream at a
                  time.
                </p>
              </div>

              {/* <FormControl component="fieldset" margin="normal">
                <Typography variant="subtitle1" gutterBottom>
                  Recording
                </Typography>
                <RadioGroup
                  name="recording"
                  value={formState.recording}
                  onChange={handleChange}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <FormControlLabel
                      
                      value="True"
                      control={<Radio />}
                      label="Yes"
                    />
                  </div>
                  <FormControlLabel
                    value="False"
                    control={<Radio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl> */}
              <Button
                variant="contained"
                color="primary"
                onClick={handleCreateChannel}
                fullWidth
                sx={{ mt: 1 }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Proceed"
                )}
              </Button>
            </>
          ) : (
            <div className={styles.noCreditsTextBlock}>
              <h3 className={styles.noCreditsText}>
                Currently you have no credits
              </h3>
              <button
                className={styles.purchaseButton}
                onClick={() => {
                  navigate(routeNames.payments);
                }}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Purchase Credits"
                )}
              </button>
            </div>
          )}
        </Box>
      </Modal>
    );
  };

  const renderStreamErrormodal = () => {
    return (
      error && (
        <div className={styles.streamErrorModal}>
          <div className={styles.streamErrorModalContent}>
            <p>
              Unable to create a Channel in{" "}
              <span className={styles.regionErrorText}>{error}</span>
            </p>
            <button
              onClick={handleErrorModalClose}
              className={styles.streamCloseBtn}
            >
              Close
            </button>
          </div>
        </div>
      )
    );
  };

  return (
    <>
      {renderStreamModal()}
      {renderStreamErrormodal()}
    </>
  );
};

export default StreamModal;

import React, { useState, useEffect, useRef } from "react";
import { CopyAll as CopyIcon } from "@mui/icons-material";
import { Share as ShareIcon } from "@mui/icons-material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
} from "@mui/material";

import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useAppDataContext } from "hooks/useappdatacontext";
import { FaEye } from "react-icons/fa";
import { routeNames } from "constants";
import videojs from "video.js";
import "video.js/dist/video-js.css";
// import Hls from "hls.js";
import "./index.css";
import styles from "./styles.module.css";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  ArrowDownAZ,
  ArrowDownWideNarrow,
  ArrowDownZA,
  ArrowUpNarrowWide,
  Calendar,
  CalendarRange,
  CalendarSearch,
  CircleX,
  Filter,
} from "lucide-react";
import { toast } from "react-toastify";
import { useAuthContext } from "../../hooks/useauthcontext";

const fonts = [
  "Dancing Script",
  "Great Vibes",
  "Sacramento",
  "Lobster",
  "Alex Brush",
  "Pacifico",
  "Allura",
  "Satisfy",
  "Pinyon Script",
  "Cookie",
  "Rochester",
  "Rosarivo",
  "Fredericka the Great",
  "Cedarville Cursive",
  "Clicker Script",
  "French Script MT",
  "Handlee",
  "Berkshire Swash",
  "Playball",
  "Niconne",
  "Zeyada",
  "Alfa Slab One",
  "Abril Fatface",
  "Parisienne",
  "Merienda",
  "Italiana",
  "Sirin Stencil",
  "Allerta Stencil",
  "Titan One",
  "Gloock",
  "Belgrano",
  "Kalam",
  "Sofia",
  "Marck Script",
  "Vampiro One",
  "Srisakdi",
  "Pattaya",
  "Julius Sans One",
  "Righteous",
];

const CrudTable = ({ searchQuery }) => {
  //snackbar copy text
  const { userData } = useAuthContext();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [anchorEl, setAnchorEl] = useState(null);

  //Text Editor
  // const [popCanelTheEditOrConfirm, setpopCanelTheEditOrConfirm] =
  //   useState(false);

  // const [textEditor, setTextEditor] = useState({
  //   userName: false,
  //   contact: false,
  //   eventName: false,
  //   template: false,
  //   venue: false,
  //   venueDate: false,
  //   venueTime: false,
  // });

  // const [option, setOption] = useState("");

  const navigate = useNavigate();

  // Contexts
  const {
    eventData,
    setEventData,
    getAllEvents,
    allEventsData,
    openDelete,
    setOpenDelete,
    deleteEventData,
    singleEventData,
    showLoader,
    setShowLoader,
  } = useAppDataContext();

  const [content, setContent] = useState({
    userName: "",
    contact: "",
    eventName: "",
    template: "",
    venue: "",
    venue_date: "",
    venue_time: "",
    templateDiscripton: "",
    banner: null,
  });

  // States
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [openEdit, setOpenEdit] = useState(false);
  // const [popCanelToEdit, setPopUpCancelToEdit] = useState(false);

  const [selectedRowId, setSelectedRowId] = useState(null);

  // const [selectedBanner, setSelectedBanner] = useState(null);
  // const [selectedInvitationCard, setSelectedInvitationCard] = useState(null);
  // const [selectedInvitationVideo, setSelectedInvitationVideo] = useState(null);
  // const [selectedOfflineVideo, setSelectedOfflineVideo] = useState(null);
  // const [selectedImages, setSelectedImages] = useState([]);
  // const [selectedOption, setSelectedOption] = useState("RTMPS");

  // dropdownCheck states
  // const [isChecked, setIsChecked] = useState(false);
  // const [selectedPasswordOption, setSelectedPasswordoption] = useState("");
  // const [validPassword, setValidPAssword] = useState("");
  // const [passwordError, setPasswordError] = useState("");
  // const [apiError, setApiError] = useState("");
  // const [apiSuccess, setApiSuccess] = useState("");
  // const [fetchedPassword, setFetchedPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  //hls States
  const [hlsLiveUrl, setHlsLiveUrl] = useState("");
  const [error, setError] = useState("");

  //hls References
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  // const [minimumPassword, setMinimumPassword] = useState("");
  const [loadingView, setLoadingView] = useState(true);
  // const [passwordVisibilty, setPasswordVisibility] = useState(true);

  const loggedInUser = JSON.parse(localStorage.getItem("userData"));
  const [linkIdentifire, setLinkIdentifire] = useState("");

  const [openDataStartselection, setOpenDataStartselection] = useState(false);
  const [openDataToselection, setOpenDataToselection] = useState(false);
  const [openDataselection, setOpenDataselection] = useState(false);
  const [date, setDate] = useState(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterDialog, setFilterDialog] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    search: "",
    category: "",
    available: false,
    priceRange: [0, 1000],
  });
  const [sortOption, setSortOption] = useState("ascending");
  const [startDate, setStartDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [selectedCaterogy, setCategory] = useState("");
  const filterRef = useRef(null);
  const dateRangeStartRef = useRef(null);
  const dateRangeToRef = useRef(null);
  const dateSearchRef = useRef(null);
  const [filteredEventList, setFilteredEventList] = useState([]);
  const [activeDateBtn, setActiveDateBtn] = useState("dateRange");
  const [sortingByName, setSortingByName] = useState("ascending");
  const [confirmMessage, setConfirmMessage] = useState("");

  useEffect(() => {
    const sortedEvents = [...allEventsData].sort((b, a) => {
      const dateA = new Date(a.modified_at);
      const dateB = new Date(b.modified_at);
      return dateA - dateB;
    });
    setFilteredEventList(sortedEvents);
  }, [allEventsData]);

  const handleClickOutside = (event) => {
    // Check if the refs are not null before accessing `contains`
    if (
      filterRef.current &&
      !filterRef.current.contains(event.target) &&
      dateRangeStartRef.current &&
      !dateRangeStartRef.current.contains(event.target) &&
      dateRangeToRef.current &&
      !dateRangeToRef.current.contains(event.target) &&
      dateSearchRef.current &&
      !dateSearchRef.current.contains(event.target)
    ) {
      setOpenFilter(false);
      setOpenDataStartselection(false);
      setOpenDataToselection(false);
      setOpenDataselection(false);
    }
  };

  // Event listener to close dropdowns when clicked outside
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  // Handle changes for input filters and search
  const handleFilterChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFilterOptions((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  // Handle sorting option change
  // const handleSortChange = (e) => {
  //   setSortOption(e.target.value);
  // };

  // const [fontStyle, setFontStyle] = useState(fonts[0]);
  //useEffect to link list of google fonts to head element

  useEffect(() => {
    fonts.forEach((font) => {
      const link = document.createElement("link");
      link.href = `https://fonts.googleapis.com/css2?family=${font.replace(
        /\s+/g,
        "+"
      )}:wght@400;700&display=swap`;
      link.rel = "stylesheet";
      document.head.appendChild(link);
    });
  }, []);

  // hls player options
  const playerOptions = {
    autoplay: true,
    controls: true,
    fluid: true,
    preload: "auto",
    sources: [
      {
        src: hlsLiveUrl || "",
        type: "application/x-mpegURL",
      },
    ],
  };

  useEffect(() => {
    setHlsLiveUrl("");
    if (singleEventData?.live_vidlink) {
      setHlsLiveUrl("");
      const hlsLive = extractHlsUrl(singleEventData.live_vidlink);
      if (hlsLive) {
        setHlsLiveUrl(hlsLive);
      }
    }
  }, [singleEventData?.live_vidlink]);

  const initializePlayer = () => {
    if (playerRef.current) {
      playerRef.current = null;
    }
    if (videoRef.current && !playerRef.current) {
      playerRef.current = videojs(videoRef.current, playerOptions, () => {
        setLoadingView(false);
      });

      let retryCount = 0; // Initialize retry counter

      // Add error handling
      playerRef.current.on("error", (error) => {
        console.error("Video.js Error:", error);

        if (error.code === 3) {
          // MEDIA_ERR_DECODE
          if (retryCount < 3) {
            retryCount++; // Increment retry counter
            setTimeout(() => {
              playerRef.current.src({
                type: "application/x-mpegURL",
                src: hlsLiveUrl,
              });
            }, 5000);
          } else {
            setError("Event stream is not live.");
          }
        } else {
          if (retryCount < 3) {
            setTimeout(() => {
              playerRef.current.src({
                type: "application/x-mpegURL",
                src: hlsLiveUrl,
              });
              retryCount++; // Increment retry counter
            }, 3000);
          } else {
            setError("Cant Access Stream!! Please Reload the Page");
            // window.location.reload()
          }
        }
      });
      setError("");
    }
  };
  useEffect(() => {
    if (openEdit) {
      // Dispose and reinitialize player when hlsLiveUrl or modal changes
      setTimeout(initializePlayer, 1000);
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [hlsLiveUrl]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //fuction for name wise filtering.
  const displayData = filteredEventList
    .filter(
      (event) =>
        event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.contact.includes(searchQuery) ||
        event.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  // const displayData = allEventsData
  //   .sort((a, b) => new Date(a.venue_date) - new Date(b.venue_date))
  //   .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleClickOpenDelete = (rowId) => {
    setSelectedRowId(rowId);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setSelectedRowId(null);
    setConfirmMessage();
  };

  const deleteEvent = async (id) => {
    setShowLoader(true);
    await deleteEventData(id);
    setOpenDelete(false);
    getAllEvents();
    setShowLoader(false);
    setConfirmMessage();
  };

  // Function to close edit modal
  useEffect(() => {
    if (eventData?.is_secure) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  }, [eventData?.is_secure]);

  // const validatePassword = (value) => {
  //   if (value.length > 0 && value.length <= 8) {
  //     setMinimumPassword("It's a Valid Password");
  //   } else if (value.length === 0) {
  //     setMinimumPassword("");
  //   } else {
  //     setPasswordError("Password must be at least 8 characters long.");
  //   }
  // };

  // const resetForm = () => {
  //   setEventData((prev) => ({
  //     ...prev,
  //     password: "",
  //   }));
  //   setPasswordError("");
  //   setApiError("");
  //   setApiSuccess("");
  //   setFetchedPassword("");
  // };

  // const handleGetChannelData = async (id) => {
  //   await getChannelData(id);
  // };

  // const handleImagesChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   setSelectedImages((prevImages) => [...prevImages, ...files]);
  // };

  // const extractHlsUrl = (url) => {
  //   const regExp = /https?:\/\/[^\s]*\.m3u8/;
  //   const match = url?.match(regExp);
  //   return match ? match[0] : null;
  // };

  // const extractHlsUrl = (url) => {
  //   const regExp = /https?:\/\/[^\s]+\.m3u8/;
  //   const match = url?.match(regExp);
  //   return match ? match[0] : null;
  // };

  const isMobile = useMediaQuery("(max-width:800px)"); // Determine if screen width is less than 800px
  const extractHlsUrl = (url) => {
    const regExp = /(https?:\/\/[^\s]+\.m3u8)/;
    const match = url?.match(regExp);
    return match ? match[0] : null;
  };

  useEffect(() => {
    const getAllEventsData = async () => {
      setShowLoader(true);
      await getAllEvents();
      setShowLoader(false);
    };
    getAllEventsData();
  }, []);

  useEffect(() => {
    if (singleEventData) {
      setContent({
        userName: singleEventData?.name,
        contact: singleEventData?.contact,
        eventName: singleEventData?.title,
        template: singleEventData?.template,
        venue: singleEventData?.venue,
        venue_date: singleEventData?.venue_date,
        venue_time: singleEventData?.venue_time,
        templateDiscripton: singleEventData?.templateDescription,
        banner: singleEventData?.banner,
      });
    }
  }, [singleEventData]);

  useEffect(() => {
    // setPopUpCancelToEdit(false);
    setEventData({
      name: singleEventData?.name,
      contact: singleEventData?.contact || "",
      password: singleEventData?.password || "",
      title: singleEventData?.title || "",
      venue: singleEventData?.venue || "",
      venue_date: singleEventData?.venue_date || "",
      venue_time: singleEventData?.venue_time || "",
      live_vidlink: singleEventData?.live_vidlink,
      banner: singleEventData?.banner,
      invitation_card: singleEventData?.invitation_card,
      invitation_video: singleEventData?.invitation_video,
      offline_video: singleEventData?.offline_video,
      images: singleEventData?.images || [],
      is_secure: singleEventData?.is_secure || "",
      template: singleEventData?.template || "",
      templateDescription: "",
    });
  }, [singleEventData]);

  //funtion to filter all events
  const handlerToFilterAllEvents = () => {
    setOpenFilter(false);
    let filteredData = allEventsData;

    if (startDate && toDate) {
      filteredData = allEventsData.filter((event) => {
        const eventDate = dayjs(event.venue_date).format("DD/MMM/YYYY");

        const isWithinDateRange =
          (!startDate || eventDate >= startDate) &&
          (!toDate || eventDate <= toDate);
        return isWithinDateRange;
      });
    }
    if (date) {
      filteredData = allEventsData.filter((event) => {
        const eventData = dayjs(event.venue_date).format("DD/MMM/YYYY");
        return eventData === date;
      });
    }

    if (selectedCaterogy !== "" && selectedCaterogy !== "others") {
      filteredData = filteredData.filter((event) =>
        event?.template.toLowerCase().includes(selectedCaterogy.toLowerCase())
      );
    }
    if (selectedCaterogy === "others") {
      let inBuilt = [
        "Birthday",
        "Marriage",
        "Saree Ceremony",
        "Cultural Event",
        "Family Reunion",
        "Party",
      ];
      filteredData = filteredData.filter(
        (event) => !inBuilt.includes(event?.template)
      );
    }
    setFilteredEventList(filteredData);
  };

  const renderTableSection = () => {
    return (
      <React.Fragment>
        {allEventsData.length === 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "24px",
              padding: "10px",
              justifyContent: "center",
              alignItems: "center",
              height: "auto",
            }}
          >
            <Typography variant="h6">No events created yet!</Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate(routeNames.event)}
            >
              Create Event
            </Button>
          </div>
        ) : (
          <>
            {renderTableTitleSection()}
            {renderTableContainerSection()}
            {renderTablePaginationSection()}
          </>
        )}
      </React.Fragment>
    );
  };

  const renderTableTitleSection = () => {
    return (
      <div className={styles.tablesec}>
        <Typography
          variant="h5"
          component="div"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
          }}
          className={styles.createEventTitleStyle}
        >
          {/* {filter === "today"
            ? "Today "
            : filter === "tomorrow"
            ? "Tomorrow "
            : "Modified"} */}
          <p className={styles.eventsHeading}>Events</p>{" "}
          <span className={styles.eventsCount}>{allEventsData.length}</span>
        </Typography>

        <div
          style={{ position: "relative" }}
          className={styles.rightSection}
          ref={filterRef}
        >
          {((toDate !== null && startDate !== null) ||
            date != null ||
            selectedCaterogy !== "") && (
            <button
              style={{
                backgroundColor: "white",
                top: "-8px",
                left: "21px",
                borderRadius: "50%",
                border: "none",
                color: "red",
                position: "absolute",
                zIndex: "1",
                cursor: "pointer",
              }}
              onClick={() => {
                setToDate(null);
                setStartDate(null);
                setDate(null);
                setOpenFilter(false);
                setCategory("");
                setFilteredEventList(allEventsData);
              }}
            >
              <CircleX />
            </button>
          )}
          <button
            onClick={() => setOpenFilter(!openFilter)}
            style={{
              padding: "1px 3px",
              background: "none",
              borderRadius: "3px",
              position: "relative",
            }}
          >
            <Filter />

            {openFilter && (
              <div
                onMouseLeave={() => {
                  if (
                    (toDate === null && startDate === null) ||
                    date == null ||
                    selectedCaterogy === ""
                  ) {
                    setTimeout(() => {
                      setOpenFilter(false);
                    }, 3000);
                  }
                }}
                className={styles.filterDialogContainer}
                onClick={(e) => e.stopPropagation()}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "2px",
                      alignItems: "center",
                    }}
                  >
                    <h3 style={{ width: "100px" }}>Event Type:</h3>
                    <select
                      value={selectedCaterogy}
                      onChange={(e) => {
                        setCategory(e.target.value);
                      }}
                      style={{
                        padding: "8px 12px",
                        fontSize: "14px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        backgroundColor: "#fff",
                        outline: "none",
                        cursor: "pointer",
                      }}
                    >
                      <option value="">All</option>
                      <option value="Birthday">Birthday</option>
                      <option value="Marriage">Marriage</option>
                      <option value="Saree Ceremony">Saree Ceremony</option>
                      <option value="Cultural Event">Cultural Event</option>
                      <option value="Family Reunion">Family Reunion</option>
                      <option value="Party">Party</option>
                      <option value="others">Others</option>
                    </select>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-around",
                      width: "100%",
                    }}
                  >
                    <button
                      style={{ background: "#499749" }}
                      className={styles.dateRangeBtn}
                      onClick={() => {
                        setActiveDateBtn("dateRange");
                      }}
                    >
                      By Date Range
                    </button>
                    <button
                      style={{ background: "#499749" }}
                      className={styles.dateRangeBtn}
                      onClick={() => {
                        setActiveDateBtn("date");
                      }}
                    >
                      By Date
                    </button>
                  </div>
                  {activeDateBtn === "dateRange" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <h3
                          style={{
                            margin: "0 5px",
                            fontWeight: "bold",
                            width: "100px",
                            textAlign: "left",
                          }}
                        >
                          From:{" "}
                        </h3>
                        <button
                          onClick={() => {
                            if (date) {
                              return toast.warning(
                                "Please Reset Selected Date"
                              );
                            }
                            setOpenDataStartselection(!openDataStartselection);
                          }}
                          ref={dateRangeStartRef}
                        >
                          <CalendarRange />
                        </button>
                        {startDate && (
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "3px",
                              marginLeft: "2px",
                            }}
                          >
                            {startDate}
                            <CircleX
                              onClick={() => {
                                setStartDate(null);
                              }}
                              style={{
                                cursor: "pointer",

                                color: "red",
                                height: "16px",
                                marginBottom: "4px",
                              }}
                            />
                          </p>
                        )}
                        {openDataStartselection && (
                          <div
                            style={{
                              background: "white",
                              position: "absolute",
                              top: "100px",
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DateCalendar", "DateCalendar"]}
                              >
                                <DateCalendar
                                  value={startDate ? dayjs(startDate) : null}
                                  onChange={(newValue) => {
                                    const d = dayjs(newValue?.$d);
                                    const formattedDate =
                                      d.format("DD/MMM/YYYY");
                                    setOpenDataStartselection(
                                      !openDataStartselection
                                    );
                                    setStartDate(formattedDate);
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        )}
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h3
                          style={{
                            margin: "0 5px",
                            fontWeight: "bold",
                            width: "100px",
                            textAlign: "left",
                          }}
                        >
                          To:{" "}
                        </h3>
                        <button
                          onClick={() => {
                            if (date) {
                              return toast.warning(
                                "Please Reset Selected Date"
                              );
                            }
                            setOpenDataToselection(!openDataToselection);
                          }}
                          ref={dateRangeToRef}
                        >
                          <Calendar />
                        </button>
                        {toDate && (
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "3px",
                              marginLeft: "2px",
                            }}
                          >
                            {toDate}
                            <CircleX
                              onClick={() => {
                                setToDate(null);
                              }}
                              style={{
                                cursor: "pointer",

                                color: "red",
                                height: "16px",
                                marginBottom: "4px",
                              }}
                            />
                          </p>
                        )}
                        {openDataToselection && (
                          <div
                            style={{
                              background: "white",
                              position: "absolute",
                              display: "flex",
                              zIndex: "1",
                              top: "150px",
                            }}
                          >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoContainer
                                components={["DateCalendar", "DateCalendar"]}
                              >
                                <DateCalendar
                                  value={toDate ? dayjs(toDate) : null}
                                  onChange={(newValue) => {
                                    const d = dayjs(newValue?.$d);
                                    const formattedDate =
                                      d.format("DD/MMM/YYYY");
                                    setOpenDataToselection(
                                      !openDataToselection
                                    );
                                    setToDate(formattedDate);
                                  }}
                                />
                              </DemoContainer>
                            </LocalizationProvider>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {activeDateBtn === "date" && (
                    <div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <h3
                          style={{
                            margin: "0 5px",
                            fontWeight: "bold",
                            width: "100px",
                          }}
                        >
                          Select Data:
                        </h3>

                        <button
                          style={{
                            cursor: "pointer",
                          }}
                          ref={dateSearchRef}
                        >
                          <CalendarSearch
                            onClick={() => {
                              if (startDate || toDate) {
                                return toast.error(
                                  "Please Reset Start and to Date"
                                );
                              }
                              setOpenDataselection(!openDataselection);
                            }}
                          />
                        </button>
                        {date && (
                          <p
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "3px",
                              marginLeft: "2px",
                            }}
                          >
                            {date}

                            <CircleX
                              onClick={() => {
                                setDate(null);
                              }}
                              style={{
                                cursor: "pointer",
                                color: "red",
                                height: "16px",
                                marginBottom: "4px",
                              }}
                            />
                          </p>
                        )}
                      </div>
                      {openDataselection && (
                        <div
                          style={{
                            display: "flex",
                            zIndex: "999",
                            position: "absolute",
                            top: "220px",
                            background: "white",
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                              components={["DateCalendar", "DateCalendar"]}
                            >
                              <DateCalendar
                                value={date ? dayjs(date) : null}
                                onChange={(newValue) => {
                                  const d = dayjs(newValue?.$d);
                                  const formattedDate = d.format("DD/MMM/YYYY");
                                  setOpenDataselection(false);
                                  setDate(formattedDate);
                                }}
                              />
                            </DemoContainer>
                          </LocalizationProvider>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    padding: "10px 5px",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    onClick={() => {
                      setToDate(null);
                      setStartDate(null);
                      setDate(null);
                      setOpenFilter(false);
                      setCategory();
                      setFilteredEventList(allEventsData);
                    }}
                    style={{
                      padding: "8px 16px",
                      fontSize: "14px",
                      backgroundColor: "#f44336",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                    }}
                  >
                    Clear
                  </button>
                  <button
                    onClick={() => {
                      if (startDate && toDate === null) {
                        return toast.warning("Please Select To Date");
                      } else if (startDate === null && toDate) {
                        return toast.warning("Please Select Start Date");
                      }
                      handlerToFilterAllEvents();
                    }}
                    style={{
                      padding: "8px 16px",
                      fontSize: "14px",
                      backgroundColor: "#4CAF50",
                      color: "white",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                    }}
                  >
                    Filter
                  </button>
                </div>
              </div>
            )}
          </button>

          <button
            onClick={() => {
              handleDateFilterClick("modified");
              eventsSorting("modified");
            }}
            className={styles.filterbtn}
            style={{
              backgroundColor: filter === "modified" ? "green" : "gray",
            }}
          >
            Recent
          </button>
        </div>
      </div>
    );
  };

  const [filter, setFilter] = useState("ascending");

  // Date Filtering Function
  const filterEventsByDate = (data) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    // Filter events for "today"
    // if (filter === "today") {
    //   return data.filter(
    //     (event) =>
    //       new Date(event.venue_date).setHours(0, 0, 0, 0) === today.getTime()
    //   );
    // }

    // // Filter events for "tomorrow"
    // else if (filter === "tomorrow") {
    //   return data.filter(
    //     (event) =>
    //       new Date(event.venue_date).setHours(0, 0, 0, 0) === tomorrow.getTime()
    //   );
    // }

    // Sort events by modified date (descending order)
  };

  // Toggle Filter Function
  const handleDateFilterClick = (selectedFilter) => {
    setFilter(selectedFilter); // Set the filter directly based on the button clicked
  };

  // Function to handle copying the text to clipboard
  const handleCopy = (link) => {
    navigator.clipboard
      .writeText(`${process.env.REACT_APP_MAIN_APP}/${link}`)
      .then(() => {
        setLinkIdentifire(link);
        setSnackbarMessage("Copied!");
        setSnackbarSeverity("success");
        setOpenSnackbar(true);
      })
      .catch(() => {
        setLinkIdentifire(link);
        setSnackbarMessage("Failed!");
        setSnackbarSeverity("error");
        setOpenSnackbar(true);
      });
  };

  // Function to trigger sharing
  const handleShare = (link) => {
    if (navigator.share) {
      // If Web Share API is available (typically works on mobile devices)
      navigator
        .share({
          url: `/${link}`,
        })
        .then(() => {
          setSnackbarMessage("Sharing...!");
          setOpenSnackbar(true);
        })
        .catch(() => {
          setSnackbarMessage("Failed to share content.");
          setOpenSnackbar(true);
        });
    } else {
      setSnackbarMessage(
        "Sharing is not supported on this platform. Copy this link instead!"
      );
      setOpenSnackbar(true);
    }
  };

  // let filteredEventList = filterEventsByDate(displayData);

  const eventsSorting = (sort) => {
    let data = filteredEventList;

    if (sort === "modified") {
      data = filteredEventList.sort(
        (a, b) => new Date(b.modified_at) - new Date(a.modified_at)
      );
    }

    // Sort events by venue date, either ascending or descending
    else if (sort === "ascending") {
      data = filteredEventList.sort((a, b) => {
        const dateA = new Date(a.venue_date);
        const dateB = new Date(b.venue_date);

        return dateA - dateB;
      });
    } else if (sort === "descending") {
      data = filteredEventList.sort((a, b) => {
        const dateA = new Date(a.venue_date);
        const dateB = new Date(b.venue_date);

        return dateB - dateA;
      });
    } else if (sort === "nameAscending") {
      data = [...filteredEventList].sort((a, b) => {
        const titleA = a.name.trim().toLowerCase();
        const titleB = b.name.trim().toLowerCase();
        return titleA.localeCompare(titleB);
      });
    } else if (sort === "nameDescending") {
      data = [...filteredEventList].sort((a, b) => {
        const titleA = a.name.trim().toLowerCase();
        const titleB = b.name.trim().toLowerCase();
        return titleB.localeCompare(titleA);
      });
    }
    setFilteredEventList(data);
  };

  const renderTableContainerSection = () => {
    let finalData = displayData ? displayData : filteredEventList;
    return (
      <div className={styles.scrollableContainer}>
        <TableContainer
          sx={{
            maxHeight: "auto",
            width: "100%",
            flexShrink: "0",
            "@media (max-width: 300px)": {
              padding: "0 4px",
            },
          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: "#30b2ef" }}>
                <TableCell
                  sx={{
                    fontSize: "14px",
                    color: "#fff",
                    minWidth: "180px",
                    "@media (max-width:600px)": {
                      fontSize: "10px !important",
                    },
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                    }}
                  >
                    {loggedInUser && loggedInUser.email === "admin@user.com"
                      ? "User Details"
                      : "Event Details"}
                    {sortingByName === "ascending" ? (
                      <button
                        style={{
                          background: "none",
                          color: "white",
                          border: "none",
                        }}
                        onClick={() => {
                          setSortingByName("descending");
                          eventsSorting("nameDescending");
                        }}
                      >
                        <ArrowDownAZ />
                      </button>
                    ) : (
                      <button
                        style={{
                          background: "none",
                          color: "white",
                          border: "none",
                        }}
                        onClick={() => {
                          setSortingByName("ascending");
                          eventsSorting("nameAscending");
                        }}
                      >
                        <ArrowDownZA />
                      </button>
                    )}
                  </div>
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "14px",
                    color: "#fff",
                    minWidth: "100px",
                    "@media (max-width:600px)": {
                      fontSize: "10px !important",
                    },
                  }}
                >
                  Event
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "14px",
                    color: "#fff",
                    minWidth: "130px",

                    "@media (max-width:600px)": {
                      fontSize: "10px !important",
                    },
                  }}
                >
                  <div style={{ display: "flex", gap: "2px" }}>
                    Event Timings
                    <button
                      style={{
                        background: "transparent",
                        border: "none",
                        color: "white",
                      }}
                    >
                      {filter === "descending" && (
                        <ArrowUpNarrowWide
                          onClick={() => {
                            eventsSorting("ascending");
                            setFilter("ascending");
                          }}
                        />
                      )}
                      {filter === "ascending" && (
                        <ArrowDownWideNarrow
                          onClick={() => {
                            eventsSorting("descending");
                            setFilter("descending");
                          }}
                        />
                      )}
                      {filter !== "ascending" && filter !== "descending" && (
                        <ArrowDownWideNarrow
                          onClick={() => {
                            eventsSorting("ascending");
                            setFilter("ascending");
                          }}
                        />
                      )}
                    </button>
                  </div>
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "14px",
                    color: "#fff",
                    minWidth: "100px",
                    "@media (max-width:600px)": {
                      fontSize: "10px !important",
                    },
                  }}
                >
                  Live Timings
                </TableCell>

                <TableCell
                  sx={{
                    fontSize: "14px",
                    color: "#fff",
                    textAlign: "center",
                    "@media (max-width:600px)": {
                      fontSize: "10px !important",
                    },
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {finalData &&
                finalData.map((row) => {
                  return (
                    <TableRow
                      key={row.id}
                      sx={{
                        backgroundColor: row.id % 2 === 0 ? "#f5f5f5" : "white",
                        "&:hover": { backgroundColor: "#e0f7fa" },
                      }}
                    >
                      <TableCell
                        sx={{
                          maxWidth: "150px",
                          overflow: "auto",
                          "@media (max-width:600px)": {
                            fontSize: "10px !important",
                          },
                        }}
                      >
                        {loggedInUser && (
                          <>
                            {loggedInUser.email === "admin@user.com" ? (
                              <>
                                <p>{row.name}</p>
                                <p>{row.contact}</p>
                                <p>{row.email}</p>
                              </>
                            ) : (
                              <>
                                <p>{row.name}</p>
                                <p>{row.contact}</p>
                              </>
                            )}
                          </>
                        )}

                        <div style={{ display: "flex" }}>
                          <button
                            style={{
                              transition: "background-color 0.3s ease",
                              border: "none",
                              padding: "2px 10px",
                              color: "black",
                              backgroundColor: "#ebd8a0",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleShare(row.prlink);
                            }}
                          >
                            <ShareIcon />
                          </button>
                          <div style={{ position: "relative" }}>
                            <button
                              ref={(button) => setAnchorEl(button)}
                              style={{
                                marginLeft: "4px",
                                transition: "background-color 0.3s ease",
                                border: "none",
                                padding: "2px 10px",
                                color: "white",
                                backgroundColor: "#4caf50",
                                borderRadius: "5px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleCopy(row.prlink)}
                            >
                              <CopyIcon />
                            </button>
                            {linkIdentifire === row.prlink && (
                              <Snackbar
                                open={openSnackbar}
                                autoHideDuration={500}
                                onClose={() => setOpenSnackbar(false)}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "left",
                                }}
                                sx={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "100%",
                                  transform: "translateY(-50%)",
                                }}
                              >
                                <Alert
                                  onClose={() => setOpenSnackbar(false)}
                                  severity={snackbarSeverity}
                                  sx={{
                                    width: "100%",
                                    padding: "1px 4px",
                                    margin: "0px 0px 18px 28px",
                                  }}
                                >
                                  {snackbarMessage}
                                </Alert>
                              </Snackbar>
                            )}
                          </div>
                        </div>
                      </TableCell>

                      <TableCell
                        sx={{
                          maxWidth: "150px",
                          overflow: "auto",
                          "@media (max-width:600px)": {
                            fontSize: "10px !important",
                          },
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <span>{row.title}</span>
                          <span>({row.template})</span>
                        </div>
                      </TableCell>

                      <TableCell
                        sx={{
                          maxWidth: "180px",
                          "@media (max-width:600px)": {
                            fontSize: "10px !important",
                          },
                        }}
                      >
                        <p>
                          {new Date(row.venue_date).toLocaleDateString(
                            "en-US",
                            {
                              month: "short",
                              day: "numeric",
                              year: "numeric",
                            }
                          )}
                        </p>
                        <p>
                          {new Date(
                            `1970-01-01T${row.venue_time}`
                          ).toLocaleTimeString([], {
                            hour: "numeric",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </p>
                      </TableCell>

                      <TableCell
                        sx={{
                          minWidth: "50px",
                          "@media (max-width:600px)": {
                            fontSize: "10px !important",
                          },
                        }}
                      >
                        {new Date(
                          `1970-01-01T${row.live_time}`
                        ).toLocaleTimeString([], {
                          hour: "numeric",
                          minute: "2-digit",
                          hour12: true,
                        })}
                      </TableCell>

                      <TableCell
                        sx={{
                          height: "125px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          gap: "5px",
                          flexWrap: "wrap",
                          "@media (max-width:600px)": {
                            fontSize: "10px !important",
                          },
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          color="info"
                          startIcon={<Edit />}
                          sx={{ fontSize: "12px" }}
                          onClick={() => {
                            navigate(`${routeNames.editEvent}/${row.prlink}`);
                          }}
                        >
                          Edit
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="error"
                          startIcon={<Delete />}
                          onClick={() => {
                            handleClickOpenDelete(row.id);
                          }}
                          sx={{ fontSize: "12px" }}
                        >
                          Delete
                        </Button>
                        <Button
                          variant="contained"
                          size="small"
                          color="warning"
                          startIcon={<FaEye />}
                          onClick={() => {
                            window.open(`/${row.prlink}`, "_blank"); // Open in a new tab
                          }}
                          sx={{ fontSize: "12px" }}
                        >
                          Page
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  };

  const renderTablePaginationSection = () => {
    return (
      <TablePagination
        component="div"
        count={allEventsData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        // rowsPerPageOptions={[10, 20, 30]}
      />
    );
  };
  const [copyTextOpen, setCopyTextOpen] = useState(false);

  const handleDeleteCopy = () => {
    navigator.clipboard
      .writeText("Delete")
      .then(() => {
        toast.success("Delete Text Copied");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const renderDeleteEventSection = () => {
    return (
      <Dialog open={openDelete} onClose={handleCloseDelete}>
        <DialogTitle className={styles.confirmPromptStyle}>
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <div>
            {copyTextOpen && (
              <p
                style={{
                  position: "absolute",
                  top: "30px",
                  left: "137px",
                  backgroundColor: "wheat",
                  padding: "5px 10px",
                  borderRadius: "5px",
                }}
              >
                Copy
              </p>
            )}
            <InputLabel className={styles.inputLabel}>
              Enter the word{" "}
              <strong
                style={{ position: "relative" }}
                // onMouseMove={() => {
                //   setCopyTextOpen(true);
                // }}
                // onMouseLeave={() => {
                //   setCopyTextOpen(false);
                // }}
              >
                "Delete"
              </strong>{" "}
              in the input box below to confirm.
            </InputLabel>

            <TextField
              label="Confirmation Text"
              variant="outlined"
              fullWidth
              className={styles.textField}
              value={confirmMessage}
              onChange={(e) => {
                setConfirmMessage(e.target.value);
              }}
            />
            <div style={{ display: "flex", alignItems: "center", gap: "3px" }}>
              <p>Delete</p>{" "}
              <CopyIcon
                className={styles.deleteText}
                onClick={() => handleDeleteCopy()}
              />
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={handleCloseDelete}
            color="primary"
            className={`${styles.deleteActionBtnStyle} ${styles.cancelBtn}`}
          >
            Cancel
          </Button>
          <Button
            onClick={() => deleteEvent(selectedRowId)}
            color="error"
            className={`${styles.deleteActionBtnStyle} ${styles.deleteBtn}`}
            disabled={confirmMessage !== "Delete"}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderFilteringDialog = () => {
    return (
      <Dialog open={filterDialog} onClose={() => setFilterDialog(false)}>
        <DialogTitle>Select Filtering & Sorting Options</DialogTitle>
        <DialogContent>
          {/* Category Select */}
          <FormControl fullWidth margin="normal">
            <InputLabel sx={{ fontSize: "14px" }}>Event Type</InputLabel>
            <Select
              name="category"
              value={filterOptions.category}
              onChange={handleFilterChange}
              label="Category"
            >
              <MenuItem value="">All</MenuItem>
              <MenuItem value="Birthday">Birthday</MenuItem>
              <MenuItem value="Marriage">Marriage</MenuItem>
              <MenuItem value="Saree Ceremony">Saree Ceremony</MenuItem>
              <MenuItem value="Cultural Event">Cultural Event</MenuItem>
              <MenuItem value="Family Reunion">Family Reunion</MenuItem>
            </Select>
          </FormControl>

          <div>
            <InputLabel>Select Date Range</InputLabel>
            <h3>
              <span>From</span> to <span></span>
            </h3>
          </div>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setFilterDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              // Add your filtering logic here based on filterOptions and sortOption
              setFilterDialog(false);
            }}
            color="primary"
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Paper sx={{ width: "100%" }}>
      {renderTableSection()}
      {renderDeleteEventSection()}
      {renderFilteringDialog()}
    </Paper>
  );
};

export default CrudTable;

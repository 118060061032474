import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import Edit from "@mui/icons-material/Edit";
import PermIdentityTwoToneIcon from "@mui/icons-material/PermIdentityTwoTone";
import PhoneTwoToneIcon from "@mui/icons-material/PhoneTwoTone";
import MailTwoToneIcon from "@mui/icons-material/MailTwoTone";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useEffect, useState } from "react";
import { routeNames } from "constants";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "hooks/useauthcontext";
import styles from "./userInfoTooltip.module.css";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { Eye, EyeOff } from "lucide-react";

const UserInfoTooltip = () => {
  // routing:
  const navigate = useNavigate();

  // contexts:
  const { userData, setUserData, editUserInfo, logOut } = useAuthContext();
  const [isEditProfile, setIsEditProfile] = useState(true);
  const [errors, setErrors] = useState({});

  const [newPassword, setNewPassword] = useState({
    current_password: "",
    new_password: "",
    re_new_password: "",
  });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // states:
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const open = Boolean(anchorEl);

  const [isLoading, setIsLoading] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setErrors({});
    setIsEditProfile(true);
    setNewPassword({
      current_password: "",
      new_password: "",
      re_new_password: "",
    });
  };

  const handleUserEdit = (e) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    if (!userData) {
      getUSerData();
    }
  }, [userData]);
  const getUSerData = async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      const response = await axios.get(
        `${process.env.REACT_APP_MEDIA_URL}/api/users/me/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.data) {
        setUserData(response.data);
      }
    }
  };
  const handleSave = () => {
    setIsLoading(true);
    editUserInfo(userData);
    setIsLoading(false);
    handleCloseModal();
  };

  const handleSavePassword = async () => {
    if (newPassword?.new_password === "") {
      return toast.warning("Can't post Empty password.");
    }
    if (errors?.new_password) {
      return toast.error("Please Ensure Password");
    }
    if (newPassword?.current_password === newPassword?.new_password) {
      return toast.error("New and Old passwords can't be same");
    }
    setIsLoading(true);
    let urlToResetPassword = `${process.env.REACT_APP_MEDIA_URL}/api/users/set_password/`;
    let options = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        current_password: newPassword.current_password,
        new_password: newPassword.new_password,
        re_new_password: newPassword.re_new_password,
      }),
    };

    try {
      let res = await fetch(urlToResetPassword, options);
      if (res.ok) {
        toast.success("Successfully changed");
        handleCloseModal();
      } else {
        let data = await res.json();
        toast.error("Current Password " + data?.current_password[0]);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordEdit = (e) => {
    setNewPassword({ ...newPassword, [e.target.name]: e.target.value });
    validatePassword(e.target.name, e.target.value);
  };

  const validatePassword = (name, value) => {
    let validationErrors = { ...errors };

    if (name === "new_password") {
      if (!value) {
        validationErrors.new_password = "Password is required";
      } else if (value.length < 8) {
        validationErrors.new_password =
          "Password must be at least 8 characters";
      } else {
        delete validationErrors.new_password;
      }
    }

    setErrors(validationErrors);
  };

  return (
    <div>
      <Tooltip title="Account settings">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2, marginLeft: "0px" }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar sx={{ width: 32, height: 32, backgroundColor: "#01579b" }}>
            {userData?.first_name?.charAt(0)?.toUpperCase() || "L"}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          onClick={handleClose}
          className={styles.userActionsOptionStyles}
        >
          <ListItemIcon>
            <PermIdentityTwoToneIcon fontSize="small" />
          </ListItemIcon>
          {`${userData?.first_name} ${userData?.last_name}`}
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          className={styles.userActionsOptionStyles}
        >
          <ListItemIcon>
            <PhoneTwoToneIcon fontSize="small" />
          </ListItemIcon>
          {userData?.phone_number}
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          className={styles.userActionsOptionStyles}
        >
          <ListItemIcon>
            <MailTwoToneIcon fontSize="small" />
          </ListItemIcon>
          {userData?.email}
        </MenuItem>
        {/* {userData?.map((item, index) => (
          <MenuItem
            key={index}
            onClick={handleClose}
           className={styles.userActionsOptionStyles}
          >
            {item}
          </MenuItem>
        ))} */}
        <Divider />
        <MenuItem onClick={handleOpenModal}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          Edit Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            logOut();
            navigate(routeNames?.login);
          }}
        >
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>

      {/* Modal for editing profile */}
      <Dialog
        open={openModal}
        fullWidth={true}
        sx={{
          "& .MuiDialog-paper": {
            display: "flex",
            flexDirection: "column",
            width: "700px",
            maxWidth: "none",
            padding: "0 20px 20px 0",
          },
        }}
        onClose={handleCloseModal}
      >
        <DialogTitle>
          <div className={styles.dialogTitleButtons}>
            <button
              className={isEditProfile ? styles.active : styles.inactive}
              onClick={() => setIsEditProfile(true)}
            >
              Edit Profile
            </button>

            <button
              className={!isEditProfile ? styles.active : styles.inactive}
              onClick={() => setIsEditProfile(false)}
            >
              Update Password
            </button>
          </div>
        </DialogTitle>

        <DialogContent>
          {isEditProfile ? (
            <>
              <TextField
                autoFocus
                margin="dense"
                name="first_name"
                label="First Name"
                type="text"
                fullWidth
                variant="outlined"
                value={userData?.first_name}
                onChange={handleUserEdit}
              />
              <TextField
                margin="dense"
                name="last_name"
                label="Last Name"
                type="text"
                fullWidth
                variant="outlined"
                value={userData?.last_name}
                onChange={handleUserEdit}
              />
              <TextField
                margin="dense"
                name="phone_number"
                label="Phone Number"
                type="text"
                fullWidth
                variant="outlined"
                value={userData?.phone_number}
                onChange={handleUserEdit}
              />
            </>
          ) : (
            <>
              <label>
                Current Password<span style={{ color: "red" }}>*</span>
              </label>
              <div className={styles.passwordInputStyle}>
                <input
                  name="current_password"
                  placeholder="Current Password"
                  type={showCurrentPassword ? "text" : "password"}
                  style={{ width: "100%", outline: "none", border: "none" }}
                  value={newPassword.current_password}
                  onChange={handlePasswordEdit}
                />
                <button
                  type="button"
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                >
                  {showCurrentPassword ? <EyeOff /> : <Eye />}
                </button>
              </div>

              <label>
                New Password<span style={{ color: "red" }}>*</span>
              </label>
              <div className={styles.passwordInputStyle}>
                <input
                  style={{ width: "100%", outline: "none", border: "none" }}
                  name="new_password"
                  placeholder="New Password"
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword.new_password}
                  onChange={handlePasswordEdit}
                />
                <button
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                  type="button"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? <EyeOff /> : <Eye />}
                </button>
              </div>

              {errors.new_password && (
                <p style={{ color: "red", alignSelf: "flex-start" }}>
                  {errors.new_password}
                </p>
              )}

              <label>
                Confirm Password<span style={{ color: "red" }}>*</span>
              </label>
              <div
                style={{
                  border:
                    newPassword.re_new_password &&
                    newPassword?.new_password !== newPassword?.re_new_password
                      ? "1px solid red"
                      : "",
                }}
                className={styles.passwordInputStyle}
              >
                <input
                  margin="dense"
                  name="re_new_password"
                  placeholder="Confirm Password"
                  style={{
                    width: "100%",
                    outline: "none",
                    border: "none",
                  }}
                  type={showConfirmPassword ? "text" : "password"}
                  value={newPassword?.re_new_password}
                  onChange={handlePasswordEdit}
                />
                <button
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                  }}
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? <EyeOff /> : <Eye />}
                </button>
              </div>
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button
            color="secondary"
            variant="outlined"
            onClick={handleCloseModal}
            className="closeBtn"
          >
            Cancel
          </Button>
          <Button
            onClick={isEditProfile ? handleSave : handleSavePassword}
            variant="contained"
            className="saveBtn"
          >
            {isLoading ? (
              <>
                Updating...
                <CircularProgress size={24} className="loader" />
              </>
            ) : (
              "Save"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserInfoTooltip;

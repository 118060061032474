import { useState, useEffect, useRef } from "react";
import { useAppDataContext } from "hooks/useappdatacontext";
import { Helmet } from "react-helmet";
import styles from "./navbar.module.css";
import { useParams } from "react-router-dom";

const NavBar = ({ fnStyle, title }) => {
  // States
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const urlToGetEventData = `${process.env.REACT_APP_BASE_URL}/events/get_event/`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prlink: id,
        }),
      };

      try {
        const response = await fetch(urlToGetEventData, options);
        const result = await response.json();
        setData(result);
        console.log(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  // Contexts
  const { singleEventData, ads, getAds } = useAppDataContext();

  // Update `isMobile` on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getImageMeta = () => {
    if (data?.banner) {
      return data?.banner; // Return URL if banner exists
    } else if (data?.invitation_card) {
      return data?.invitation_card; // Return URL if invitation card exists
    } else {
      return "https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"; // Default image URL
    }
  };

  return (
    <>
      <Helmet>
        <title>{data?.title || "Eventsonlife"}</title>
        <meta property="og:title" content={data?.title || "Eventsonlife"} />
        <meta
          property="og:description"
          content={`${
            data?.template_desc || "Event"
          } Invitation from Eventsonlife.com`}
        />
        <meta property="og:image" content={getImageMeta()} />
        <meta
          property="og:url"
          content={`https://www.eventsonlife.com/${data?.prlink || ""}`}
        />
      </Helmet>
      <nav
        style={{
          display: "flex",
          // flexDirection: isMobile ? "column" : "row", // Flex direction based on screen size
          alignItems: "center",
          justifyContent: isMobile ? "center" : "flex-start",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          width: "100%",
          position: "relative", // Needed for absolute positioning of the text
        }}
      >
        <a href="/">
          <img
            src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
            style={{
              height: "40px",
              width: "150px",
            }}
            alt="nav-logo"
          />
        </a>
      </nav>
    </>
  );
};

export default NavBar;

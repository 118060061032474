import { useAppDataContext } from "hooks/useappdatacontext";
import { useEffect, useState } from "react";
import styles from "./t1.module.css";
import NavBar from "components/navBar/navBar";
import HLSPlayer from "components/hlsPlayer";
import FancyBoxGallery from "../../components/fancyBoxGallery/fancyBoxGallery"; ///components/fancyBoxGallery/fancyBoxGallery

const T1 = () => {
  const { getEvent, singleEventData, showLoader, setShowLoader } =
    useAppDataContext();
  const [newdas, setnewdas] = useState([]);
  // const id = "ivsplayer";
  const id = "123";
  const [showTemplate, setShowTemplate] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState(""); // Store the image source for the modal

  // Function to open the modal and set the clicked image
  const openModal = (imageSrc) => {
    setModalImageSrc(imageSrc);
    setIsModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => setIsModalOpen(false);
  const [videoType, setVideoType] = useState(""); // Type: youtube, youtubeLive, or hls

  const determineVideoType = (videoUrl) => {
    if (
      /^(https?:\/\/)?(www\.)?(youtu\.be\/|youtube\.com\/watch\?v=)/.test(
        videoUrl
      )
    ) {
      return "youtube"; // Normal YouTube link
    } else if (/^(https?:\/\/)?(www\.)?youtube\.com\/live\//.test(videoUrl)) {
      return "youtubeLive"; // YouTube live link
    } else if (/\.m3u8$/.test(videoUrl)) {
      return "hls"; // HLS link
    }
    return null; // Unknown type
  };
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [newOfflineVideo, setNewOfflineVideo] = useState(false);
  const [newlivevideo, setNewlivevideo] = useState(false);
  useEffect(() => {
    const getSingleEvent = async () => {
      setShowLoader(true);
      const newdas = await getEvent(id);
      if (newdas) {
        setnewdas(newdas);
        if (newdas.offline_video != "" && newdas.offline_video != null) {
          setNewOfflineVideo(true);
        }
        if (newdas.live_vidlink != "" && newdas.live_vidlink != null) {
          setNewlivevideo(true);
          setVideoType(determineVideoType(newdas.live_vidlink));
        }
      }
      setShowLoader(false);
      if (newdas && newdas.is_secure === true) {
        setShowTemplate(false);
      }
    };
    if (id) {
      getSingleEvent();
    }
  }, [id]);

  function formatTime(timeString) {
    if (!timeString) return "Invalid time"; // Handle undefined or empty strings

    const [hour, minute] = timeString.split(":");
    const hourInt = parseInt(hour, 10);
    const isPM = hourInt >= 12;

    const formattedHour = hourInt % 12 === 0 ? 12 : hourInt % 12; // Handle 12-hour clock
    const period = isPM ? "PM" : "AM";

    return `${formattedHour.toString().padStart(2, "0")}:${minute} ${period}`;
  }

  return (
    <div>
      <NavBar fnStyle={"Space Grotesk"} />
      <div
        style={{
          backgroundImage:
            'url("https://images.pexels.com/photos/2088170/pexels-photo-2088170.jpeg")',
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "auto",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <div className={styles.contentsize}>
          {isModalOpen && (
            <div className={styles.modal} onClick={closeModal}>
              <img src={modalImageSrc} alt="Full view" />
            </div>
          )}

          {/* Banner Image */}
          {newdas && newdas.banner && (
            <div className={styles.bannerz}>
              <h1>Banner</h1>
              <img
                src={newdas.banner}
                alt={newdas.banner.name}
                onClick={() => openModal(newdas.banner)}
              />
            </div>
          )}

          {/* Invitation Card Image */}
          {newdas && newdas.invitation_card ? (
            <div className={styles.bannerz}>
              <h1>Invitation Card</h1>
              <img
                src={newdas.invitation_card}
                alt={newdas.invitation_card.name || "Invitation Card"}
                onClick={() => openModal(newdas.invitation_card)}
              />
            </div>
          ) : (
            // Timings
            <div className={styles.timings}>
              <h1 className={styles.bannerz}>
                {newdas.template ? newdas.template : ""} Invitation
              </h1>
              <p>
                <span>
                  <b>Date:</b>{" "}
                  {newdas.venue_date
                    ? new Date(newdas.venue_date).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "short",
                        year: "numeric",
                      })
                    : "N/A"}
                </span>
                {" | "}
                <span>
                  <b>Time:</b>{" "}
                  {newdas.venue_time ? formatTime(newdas.venue_time) : "N/A"}
                </span>
                {" | "}
                <span>
                  <b>Live Streaming:</b>{" "}
                  {newdas.live_time ? formatTime(newdas.live_time) : "N/A"}
                </span>
              </p>
              <p>
                <span>
                  <b>Venue:</b> {newdas.venue ? newdas.venue : "N/A"}
                </span>
              </p>
              <p>
                <span>{newdas.template_desc ? newdas.template_desc : ""}</span>
              </p>
            </div>
          )}
          {newdas && newdas.invitation_video && (
            <div>
              {newdas.invitation_video !== "" &&
                newdas.invitation_video !== null && (
                  <div>
                    <h1 className={styles.bannerz}>Invitation Video</h1>
                    <video className={styles.invitation_video} controls>
                      <source src={newdas.invitation_video} type="video/mp4" />
                    </video>
                  </div>
                )}
            </div>
          )}

          {newdas && (newdas.offline_video || newdas.live_vidlink) && (
            <div>
              <h1 className={styles.bannerz}>Live Video</h1>
              {newOfflineVideo && !newlivevideo && (
                <video className={styles.invitation_video} controls>
                  <source src={newdas.invitation_video} type="video/mp4" />
                </video>
              )}
              {newlivevideo && !newOfflineVideo && (
                <>
                  {(videoType === "youtube" || videoType === "youtubeLive") && (
                    <iframe
                      className={styles.youtube_recorded}
                      src={newdas.live_vidlink}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title={videoType}
                    ></iframe>
                  )}
                  {videoType === "hls" && (
                    <div
                      className={`video-container-hlsplayer ${styles.videoContainer}`}
                    >
                      <HLSPlayer />
                    </div>
                  )}
                </>
              )}
            </div>
          )}

          {newdas &&
            Array.isArray(newdas.images) &&
            newdas.images.length > 0 && (
              <>
                <p className={styles.galleryHeading}>Our Wedding Gallery</p>
                <div style={{ width: "100%" }}>
                  <FancyBoxGallery />
                </div>
              </>
            )}

          {/* Display event data */}
          <div>
            {typeof newdas === "object" ? (
              <pre>{JSON.stringify(newdas, null, 2)}</pre>
            ) : (
              newdas
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default T1;

import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../../components/navBar/navBar";
import FancyBoxGallery from "../../components/fancyBoxGallery/fancyBoxGallery";
import HLSPlayer from "components/hlsPlayer";
import CoupleFrame from "../../assets/images/coupleFrame.png";
import { useAppDataContext } from "hooks/useappdatacontext";
import styles from "./weddingTemplate.module.css";
import { FaWhatsapp } from "react-icons/fa6";
import { X } from "lucide-react";
import Slider from "react-slick";
import { ReactComponent as ViewerIcon } from "../../assets/images/viwerCount.svg";

// import LoadingView from "./LoadingView";

const WeddingTemplate = () => {
  // contexts
  const { getEvent, singleEventData, setShowLoader, getAds, ads } =
    useAppDataContext();

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    speed: 1000,
    autoplaySpeed: ads?.delaytime * 1000 || 2000,
    autoplay: true,
  };
  const [streamStatus, setStreamStatus] = useState("");

  // states
  const [showTemplate, setShowTemplate] = useState(true);
  const [newdas, setnewdas] = useState([]);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [LoadingViewFor5, setLoadingViewFor5] = useState(true);
  const [id, setID] = useState(null);
  const videoRef = useRef(null);
  const videoInvitationRef = useRef(null);

  const [idOfflinePortrait, setIdOfflinePortrait] = useState(false);
  const [idInvitationPortrait, setIdInvitationPortrait] = useState(false);
  const [css, setCss] = useState({});
  const [invitationPopOver, setInvitationPopOver] = useState(null);

  const containerRef = useRef(null); // Reference to the container
  const [containerHeight, setContainerHeight] = useState(0);
  const [count, setCount] = useState([]);

  const getVisitorsCount = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}/events/get_event_visitors/`;
    if (id) {
      try {
        let res = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ prlink: id }),
        });
        if (res.ok) {
          const { visitors } = await res.json();
          setCount(visitors);
        }
      } catch (e) {
        console.error(e.message);
      }
    }
  };

  const handlerToIncreaseCount = async () => {
    const eventId = localStorage.getItem(`eventsentID:${id}`);

    if (eventId === null && id) {
      const url = `${process.env.REACT_APP_BASE_URL}/events/increase_visitors/`;
      localStorage.setItem(`eventsentID:${id}`, true);
      try {
        let r = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ prlink: id }),
        });
      } catch (e) {
        console.error(e.message);
      }
    }
  };

  useEffect(() => {
    getVisitorsCount();
    handlerToIncreaseCount();
  }, [id]);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        await getAds(); // Assuming getAds is an API call function
      } catch (error) {
        console.error("Error fetching ads:", error);
      }
    };

    fetchAds();
  }, []);
  // const [isHovered, setIsHovered] = useState(false);
  const [slideSettings, setSettings] = useState({ ...settings });
  const sliderRef = useRef(null);

  const handleMouseEnter = useCallback(() => {
    // Stop autoplay on hover
    setSettings((prevSettings) => ({
      ...prevSettings,
      autoplay: false,
    }));
  }, []);

  const handleMouseLeave = useCallback(() => {
    // Start autoplay on mouse leave
    setSettings((prevSettings) => ({
      ...prevSettings,
      autoplay: true,
    }));
  }, []);

  useEffect(() => {
    if (ads?.delaytime) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        autoplaySpeed: ads.delaytime * 1000, // Update speed in milliseconds
      }));
    }
  }, [ads?.delaytime]);

  const calculateHeight = () => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.scrollHeight);
    }
  };

  const updateHeightSmoothly = () => {
    requestAnimationFrame(() => {
      calculateHeight();
    });
  };

  useEffect(() => {
    calculateHeight();

    const handleResize = () => {
      updateHeightSmoothly();
    };
    window.addEventListener("resize", handleResize);

    const observer = new MutationObserver(updateHeightSmoothly);
    if (containerRef.current) {
      observer.observe(containerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      if (observer) {
        observer.disconnect();
      }
    };
  }, [singleEventData]);

  updateHeightSmoothly();

  const params = useParams();

  useEffect(() => {
    // Set the decoded ID
    setID(params.id);
  }, [params.id]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setLoadingViewFor5(false);
    }, 4500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const path = useParams();

  useEffect(() => {
    const { id } = path;
    const getSingleEvent = async () => {
      setShowLoader(true);
      const newdas = await getEvent(id);
      if (newdas) {
        setnewdas(newdas);
      }
      setShowLoader(false);
      if (newdas && newdas.is_secure === true) {
        setShowTemplate(false);
      }
    };
    if (id) {
      getSingleEvent();
    }
  }, [id]);

  useEffect(() => {
    if (
      singleEventData?.template_css !== "" &&
      singleEventData?.template_css !== undefined
    ) {
      const jsonString = singleEventData?.template_css;
      setCss(JSON.parse(jsonString));
    }
  }, [singleEventData]);

  // Handle video dimensions after metadata is loaded
  const handleVideoMetadata = (ref, setPortraitState) => {
    if (ref.current) {
      const videoWidth = ref.current.videoWidth;
      const videoHeight = ref.current.videoHeight;
      setPortraitState(videoHeight > videoWidth);
    }
  };

  // When videoRef metadata is loaded
  const handleOfflineVideoMetadata = () => {
    handleVideoMetadata(videoRef, setIdOfflinePortrait);
  };

  // When videoInvitationRef metadata is loaded
  const handleInvitationVideoMetadata = () => {
    handleVideoMetadata(videoInvitationRef, setIdInvitationPortrait);
  };

  const handleSecureValidation = () => {
    if (password === newdas.password) {
      setShowTemplate(true);
    } else {
      setError("Password Invalid");
    }
  };

  const extractYouTubeVideoId = () => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = singleEventData?.live_vidlink?.match(regExp);
    return match && match[7].length === 11 ? match[7] : null;
  };

  const extractYouTubeLiveId = () => {
    const regExp = /youtube\.com\/live\/([^?&]+)/;
    const match = singleEventData?.live_vidlink?.match(regExp);
    return match ? match[1] : null;
  };

  const extractHlsUrl = () => {
    const regExp = /https?:\/\/[^\s]*\.m3u8/;
    const match = singleEventData?.live_vidlink?.match(regExp);
    return match ? match[0] : null;
  };

  const renderSpecificBannerView = () => {
    switch (singleEventData.template) {
      case "Birthday":
      case "Saree Ceremony":
        return "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728558003/5395531_fkgvby.jpg";
      case "Marriage":
        return "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728635524/japanese-paper-textures_vhxore.jpg";
      case "Cultural Event":
        return "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728636985/frame-with-flowers-it-that-says-golden_nkloxz.jpg";
      case "Family Reunion":
      case "Party":
        return "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728636985/frame-with-flowers-it-that-says-golden_nkloxz.jpg";
      default:
        return CoupleFrame;
    }
  };
  const WhatsAppButton = () => {
    const handleWhatsAppClick = () => {
      const phoneNumber = "919030908181"; // Replace with your WhatsApp number (include country code, e.g., 911234567890)
      const message = "Greetings From eventsonlife.com"; // Replace with your default message
      const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
        message
      )}`;
      window.open(url, "_blank"); // Opens WhatsApp in a new tab
    };

    return (
      <div
        style={{
          position: "fixed",
          bottom: "20px",
          right: "20px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        {count > 0 && (
          <div
            style={{
              color: css?.textColor ? css?.textColor : "black",
              fontFamily: "Arial, sans-serif",
              fontSize: "18px",
              backgroundColor: "#f3f4f6",
              border: "1px solid #ccc",
              borderRadius: "8px",
              padding: "6px 5px",
              display: "flex",
              alignItems: "center",
              gap: "5px",
              boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.1)",
            }}
          >
            <ViewerIcon
              style={{ borderRadius: "50%", width: "30px", height: "32px" }}
            />
            : <strong style={{ color: "#ff5722" }}>{count}</strong>
          </div>
        )}

        <button
          onClick={handleWhatsAppClick}
          style={{
            backgroundColor: "#25D366",
            color: "#fff",
            border: "none",
            borderRadius: "50%",
            width: "60px",
            height: "60px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            fontSize: "24px",
          }}
        >
          <FaWhatsapp style={{ fontSize: "24px" }} />
        </button>
      </div>
    );
  };

  const renderBannerImage = () => {
    const fontSize =
      singleEventData?.title &&
      singleEventData?.title.length > 20 &&
      singleEventData?.title.length < 40
        ? "20px"
        : singleEventData?.title && singleEventData?.title.length >= 40
        ? "16px"
        : "24px";
    return (
      singleEventData &&
      singleEventData?.banner && (
        <div>
          <p
            style={{
              color: css?.textColor ? css?.textColor : "black",
              fontFamily: css?.textFontFamily
                ? css?.textFontFamily
                : "Courier New",
              fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
            }}
            className={styles.invitationHeading}
          >
            Banner
          </p>

          <div id="home" className={styles.videoContainer}>
            <img
              style={{ width: "inherit", height: "inherit", cursor: "pointer" }}
              src={singleEventData?.banner}
              alt="Banner_image"
              onClick={() => setInvitationPopOver(singleEventData?.banner)}
            />
          </div>
        </div>
      )
    );
  };

  const renderNavigation = () => {
    return (
      <>
        <NavBar />

        {/* {!singleEventData?.live_vidlink && !singleEventData?.offline_video && (
          <div
            style={{ width: "100vw" }}
            className={styles.advertisementContainer}
          >
            <div className={styles.advertisement}>
              <div>
                {singleEventData?.scrolling_mess &&
                  singleEventData?.scrolling_mess}
              </div>
            </div>
          </div>
        )} */}
      </>
    );
  };

  const renderScrollingView = () => {
    return (
      <>
        {singleEventData?.scrolling_mess?.length > 0 && (
          <div
            className={`${styles.advertisementContainer} ${
              idOfflinePortrait ? styles.advertismentL : styles.advertismentP
            }`}
          >
            <div className={styles.advertisement}>
              <div>{singleEventData?.scrolling_mess}</div>
            </div>
          </div>
        )}
      </>
    );
  };

  // extracts day, month, and year
  function formatTime(timeString) {
    if (!timeString) return "Invalid time"; // Handle undefined or empty strings

    const [hour, minute] = timeString.split(":");
    const hourInt = parseInt(hour, 10);
    const isPM = hourInt >= 12;

    const formattedHour = hourInt % 12 === 0 ? 12 : hourInt % 12; // Handle 12-hour clock
    const period = isPM ? "PM" : "AM";

    return `${formattedHour.toString().padStart(2, "0")}:${minute} ${period}`;
  }

  const renderInvitationSection = () => {
    return (
      <>
        {singleEventData && singleEventData.invitation_card ? (
          <div id="invitation">
            <p
              style={{
                color: css?.textColor ? css?.textColor : "black",
                fontFamily: css?.textFontFamily
                  ? css?.textFontFamily
                  : "Courier New",
                fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
              }}
              className={styles.invitationHeading}
            >
              Invitation Card
            </p>

            <div>
              {singleEventData?.invitation_card && (
                <img
                  style={{
                    borderRadius: "10px",
                  }}
                  className={styles.invitaitonImage}
                  src={singleEventData?.invitation_card}
                  alt="Invitation card"
                  onClick={() =>
                    setInvitationPopOver(singleEventData?.invitation_card)
                  }
                />
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              color: css?.textColor ? css?.textColor : "black",
              fontFamily: css?.textFontFamily
                ? css?.textFontFamily
                : "Courier New",
            }}
            className={styles.timings}
          >
            <h1
              className={styles.bannerz}
              style={{
                color: css?.textColor ? css?.textColor : "black",
                fontFamily: css?.textFontFamily
                  ? css?.textFontFamily
                  : "Courier New",
                fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
              }}
            >
              {newdas.template ? newdas.template : ""} Invitation
            </h1>
            <p>
              <span>
                <b>Date:</b>{" "}
                {newdas.venue_date
                  ? new Date(newdas.venue_date).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })
                  : "N/A"}
              </span>
              {" | "}
              <span>
                <b>Time:</b>{" "}
                {newdas.venue_time ? formatTime(newdas.venue_time) : "N/A"}
              </span>
              {" | "}
              <span>
                <b>Live Streaming:</b>{" "}
                {newdas.live_time ? formatTime(newdas.live_time) : "N/A"}
              </span>
            </p>
            <p>
              <span>
                <b>Venue:</b> {newdas.venue ? newdas.venue : "N/A"}
              </span>
            </p>
            <p>
              <span>{newdas.template_desc ? newdas.template_desc : ""}</span>
            </p>
          </div>
        )}
        {singleEventData && singleEventData?.invitation_video && (
          <>
            <p
              style={{
                color: css?.textColor ? css?.textColor : "black",
                fontFamily: css?.textFontFamily
                  ? css?.textFontFamily
                  : "Courier New",
                fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
              }}
              className={styles.invitationHeading}
            >
              Invitation Video
            </p>
            <div
              className={`${
                idInvitationPortrait
                  ? styles.videoContainerPortrait
                  : styles.videoContainer
              }`}
            >
              <video
                ref={videoInvitationRef}
                style={{ width: "inherit" }}
                controls
                onLoadedMetadata={handleInvitationVideoMetadata}
              >
                <source
                  src={singleEventData?.invitation_video}
                  type="video/mp4"
                />
              </video>
            </div>
          </>
        )}
      </>
    );
  };

  const renderLiveVideoSection = () => {
    const videoId = extractYouTubeVideoId();
    const liveId = extractYouTubeLiveId();
    const hlsUrl = extractHlsUrl();

    if (videoId) {
      return (
        <>
          <p
            style={{
              color: css?.textColor ? css?.textColor : "black",
              fontFamily: css?.textFontFamily
                ? css?.textFontFamily
                : "Courier New",
              fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
            }}
            className={styles.invitationHeading}
          >
            Live Video
          </p>
          {renderScrollingView()}
          <div className={styles.videoContainer}>
            <iframe
              style={{ width: "inherit", height: "inherit" }}
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube Video"
            ></iframe>
          </div>
        </>
      );
    } else if (liveId) {
      return (
        <>
          <p
            style={{
              color: css?.textColor ? css?.textColor : "black",
              fontFamily: css?.textFontFamily
                ? css?.textFontFamily
                : "Courier New",
              fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
            }}
            className={styles.invitationHeading}
          >
            Live Video
          </p>
          {renderScrollingView()}
          <div className={styles.videoContainer}>
            <iframe
              style={{ width: "inherit", height: "inherit" }}
              title="Live"
              src={`https://www.youtube.com/embed/${liveId}?autoplay=1&mute=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </>
      );
    } else if (hlsUrl) {
      return (
        <>
          <p
            style={{
              color: css?.textColor ? css?.textColor : "black",
              fontFamily: css?.textFontFamily
                ? css?.textFontFamily
                : "Courier New",
              fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
            }}
            className={styles.invitationHeading}
          >
            Live Video
          </p>
          {renderScrollingView()}
          <div className={`video-container-hlsplayer ${styles.videoContainer}`}>
            <HLSPlayer
              weddingPage={true}
              streamStatus={streamStatus}
              setStreamStatus={setStreamStatus}
            />
          </div>
        </>
      );
    } else if (
      singleEventData?.offline_video !== null &&
      singleEventData?.offline_video !== ""
    ) {
      return (
        <>
          <p
            style={{
              color: css?.textColor ? css?.textColor : "black",
              fontFamily: css?.textFontFamily
                ? css?.textFontFamily
                : "Courier New",
              fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
            }}
            className={styles.invitationHeading}
          >
            Live Video
          </p>
          {renderScrollingView()}
          <div
            className={`${
              idOfflinePortrait
                ? styles.videoContainerPortrait
                : styles.videoContainer
            }`}
          >
            <video
              ref={videoRef}
              style={{ width: "inherit" }}
              src={singleEventData?.offline_video}
              type="video/mp4"
              controls
              onLoadedMetadata={handleOfflineVideoMetadata}
            />
          </div>
        </>
      );
    }
  };

  const renderGallerySection = () => {
    return (
      <div id="gallery" className={styles.galleryBlock}>
        {singleEventData &&
          Array.isArray(singleEventData.images) &&
          singleEventData.images.length > 0 && (
            <>
              <p
                style={{
                  color: css?.textColor ? css?.textColor : "black",
                  fontFamily: css?.textFontFamily
                    ? css?.textFontFamily
                    : "Courier New",
                  fontSize: css?.headingFontSize
                    ? css?.headingFontSize
                    : "30px",
                }}
                className={styles.invitationHeading}
              >
                Our Wedding Gallery
              </p>
              <div style={{ width: "100%" }}>
                <FancyBoxGallery />
              </div>
            </>
          )}
      </div>
    );
  };

  const filteredAds =
    ads?.scrolldata?.length > 0
      ? ads.scrolldata.filter((banner) => {
          const expiresOn = new Date(banner?.expires_on); // Convert expires_on to a Date object
          const now = new Date(); // Get the current date and time

          // Reset the time to 00:00:00 for both dates
          expiresOn.setHours(0, 0, 0, 0);
          now.setHours(0, 0, 0, 0);

          // Compare the dates: Include ads that haven't expired or those with no expiration date
          return !banner?.expires_on || expiresOn >= now;
        })
      : [];

  const handleWhatsAppClick = (number) => {
    const phoneNumber = "919030908181"; // Replace with your WhatsApp number (include country code, e.g., 911234567890)
    const message = "Greetings From eventsonlife.com"; // Replace with your default message
    const url = `https://wa.me/${number}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank"); // Opens WhatsApp in a new tab
  };

  const renderFooterSection = () => {
    return (
      <>
        <div style={{ margin: idOfflinePortrait ? "100px 0px 0px 0" : "50px" }}>
          {/* <p className={styles.footerHeading}>Forever our love, Thank you</p> */}
          <p
            style={{
              color: css?.textColor ? css?.textColor : "black",
              fontFamily: css?.textFontFamily
                ? css?.textFontFamily
                : "Courier New",
              fontSize: "0px",
            }}
          >
            Copyright 2024, Made with love by Infinitum Tech
          </p>
        </div>
      </>
    );
  };

  const renderImage = (type, imgLink, altName, link, number) => {
    if (type === "NONE") {
      return <img src={imgLink} alt={altName} className={styles.bannerImage} />;
    } else if (type === "LINK") {
      return (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img src={imgLink} alt={altName} className={styles.bannerImage} />
        </a>
      );
    } else if (type === "WHATSAPP") {
      return (
        <img
          src={imgLink}
          alt={altName}
          style={{ cursor: "pointer" }}
          className={styles.bannerImage}
          onClick={() => {
            handleWhatsAppClick(number);
          }}
        />
      );
    }
  };

  const renderAdv = () => {
    return (
      <div className={styles.carouselContainer}>
        {filteredAds?.length === 1 ? (
          renderImage(
            filteredAds[0].link_type,
            filteredAds[0].banner,
            filteredAds[0].title,
            filteredAds[0].link,
            filteredAds[0].whatsapp
          )
        ) : (
          <Slider
            key={slideSettings.autoplay ? "autoplay" : "paused"}
            ref={sliderRef}
            {...slideSettings}
          >
            {ads &&
              ads?.scrolldata?.length > 0 &&
              filteredAds?.map((banner, index) => {
                return (
                  <div key={index}>
                    <div
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {renderImage(
                        banner.link_type,
                        banner.banner,
                        banner.title,
                        banner.link,
                        banner.whatsapp
                      )}
                    </div>
                  </div>
                );
              })}
          </Slider>
        )}
      </div>
    );
  };

  return (
    <>
      {showTemplate ? (
        <div style={{ minWidth: "350px" }}>
          <div
            style={{
              display: `${LoadingViewFor5 === false ? "inline" : "none"}`,
            }}
            className={styles.mainContainerForPage}
          >
            <div>
              {renderNavigation()}

              <div style={{ position: "relative" }}>
                <div
                  style={{
                    backgroundImage:
                      css?.bgColor === "" && css?.bgImage
                        ? `url('${css?.bgImage}')`
                        : undefined,
                    backgroundRepeat: css?.bgImage && "no-repeat",
                    backgroundSize: css?.bgImage && "cover",
                    opacity: css?.bgImage && parseFloat(css?.bgOpacity),
                    position: css?.bgImage && "absolute",
                    height: css?.bgImage && `${containerHeight}px`,
                    width: css?.bgImage && "100%",
                  }}
                ></div>
                <div
                  ref={containerRef}
                  style={{
                    minHeight: "100vh",
                    width: "100%",
                    backgroundColor: css?.bgColor ? css?.bgColor : undefined,
                    position: "absolute",
                    background: css?.bgGradient ? css?.bgGradient : undefined,
                  }}
                >
                  {renderAdv()}

                  <div className={styles.templateGlobal}>
                    <p
                      style={{
                        color: css?.textColor ? css?.textColor : "black",
                        fontFamily: css?.textFontFamily
                          ? css?.textFontFamily
                          : "Courier New",
                        margin: "0px",
                        fontsize: "16px",
                      }}
                      className={styles.invitationHeading}
                    >
                      {singleEventData?.title}
                    </p>
                    {renderBannerImage()}
                    {/* {renderVenueSection()} */}
                    {renderInvitationSection()}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0,
                      }}
                    >
                      {renderLiveVideoSection()}
                    </div>
                    {renderGallerySection()}
                    {renderFooterSection()}
                  </div>
                </div>
              </div>
            </div>
            {WhatsAppButton()}
          </div>
          <div
            style={{
              display: `${LoadingViewFor5 ? "flex" : "none"}`,
              opacity: "1",
              transition: "opacity 1s ease-in-out",
            }}
            className={styles.loaderContainer}
          >
            <div className={styles.loader}></div>
          </div>
          {invitationPopOver !== null && (
            <div className={styles.invitationPopOver}>
              <div>
                <button
                  style={{
                    position: "absolute",
                    backgroundColor: "red",
                    padding: "5px",
                    borderRadius: "5px",
                    border: "none",
                    color: "white",
                    top: "10px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  className={styles.closeIcon}
                  onClick={(e) => {
                    e.stopPropagation();
                    setInvitationPopOver(null);
                  }}
                >
                  <X />
                </button>
              </div>
              <img
                src={invitationPopOver}
                alt="overlayImage"
                style={{
                  maxWidth: "100vw",
                  maxHeight: "100vh",
                  objectFit: "contain",
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <>
          <div
            style={{
              backgroundColor: css?.bgColor,
              opacity: css?.opacity,
            }}
            className={styles.modalOverlay}
          >
            <div className={styles.modalContent}>
              <h1 className={styles.modalTitle}>
                This event is password protected
              </h1>
              <h2 className={styles.modalSubTitle}>Please Enter Password</h2>
              <input
                type="text"
                placeholder="Enter password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={styles.inputField}
              />
              {error && <p className={styles.errorMessage}>{error}</p>}
              <button
                onClick={handleSecureValidation}
                className={styles.submitBtn}
              >
                Submit
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default WeddingTemplate;

import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import useDragDropHook from "hooks/usedragdrophook";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FileUploadInput from "../fileUploadInput/fileUploadInput";
import DropDownCheck from "../dropDownCheck/dropDownCheck";
import UrlPreview from "../urlPreview/page";
import { useAppDataContext } from "hooks/useappdatacontext";
import "./global.css";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { routeNames } from "constants";
import styles from "./global.module.css";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogTitle,
  FormControlLabel,
  LinearProgress,
  MenuItem,
  Typography,
} from "@mui/material";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { Riple } from "react-loading-indicators";
import { IoSearchCircleOutline } from "react-icons/io5";
import { ContactSupportOutlined } from "@mui/icons-material";
import { SiTicktick } from "react-icons/si";
import { RxCrossCircled } from "react-icons/rx";
import { toast } from "react-toastify";
import { CopyIcon } from "lucide-react";
import { useAuthContext } from "../../hooks/useauthcontext";

const EventsForm = () => {
  const { userData } = useAuthContext();
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [option, setOption] = useState("");
  const navigate = useNavigate();

  // contexts:
  const {
    // eventData,
    // setEventData,
    postEventData,
    hlsVideoUrl,
    channelData,
    showLoader,
    setShowLoader,
    showStreamType,
    userCredits,
    evedisabled,
    uploadingOfflineVideo,
    uploadingInvitation,
    setuploadingInvitation,
    setuploadingOfflineVideo,
    singleChannelData,
  } = useAppDataContext();

  const [eventData, setEventData] = useState({
    name: "",
    contact: "",
    password: "",
    title: "",
    venue: "",
    venue_date: null,
    venue_time: null,
    live_vidlink: "",
    banner: "",
    invitation_card: "",
    invitation_video: "",
    offline_video: "",
    images: [],
    is_secure: "",
    // password: "",
    template: "",
    template_desc: "",
    live_time: null,
    prlink: "",
    scrolling_mess: "",
    ads_disabled: true,
  });

  // states:
  const [errorMessage, setErrorMessage] = useState({});
  const [optionData, setOptionData] = useState({});
  const [customInput, setCustomInput] = useState(""); // Add this to your component
  const [previews, setPreviews] = useState({
    banner: "",
    invitation_card: "",
    invitation_video: "",
    images: [],
    offline_video: "",
  });

  //loading for url searching
  const [isLoading, setIsLoading] = useState(false);
  const [tick, setTick] = useState();
  const [newPrlinks, setNewPrlinks] = useState(null);

  const [selectedOption, setSelectedOption] = useState("RTMPS");

  const [LoadingViewFor5, setLoadingViewFor5] = useState(true);

  useEffect(() => {
    let timer = setTimeout(() => {
      setLoadingViewFor5(false);
    }, 4500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    let timer;

    const getNavigateFunction = () => {
      navigate("/dashboard");
      setuploadingOfflineVideo(0);
      setuploadingInvitation(0);
    };

    if (uploadingInvitation === 100 && uploadingOfflineVideo === 0) {
      timer = setTimeout(() => {
        getNavigateFunction();
      }, 2000);
    }

    if (uploadingOfflineVideo === 100 && uploadingInvitation === 0) {
      timer = setTimeout(() => {
        getNavigateFunction();
      }, 2000);
    }

    if (uploadingInvitation === 100 && uploadingOfflineVideo === 100) {
      timer = setTimeout(() => {
        getNavigateFunction();
      }, 2000);
    }

    // Cleanup the timer when component is unmounted or dependencies change
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [uploadingInvitation, uploadingOfflineVideo]);

  const streamData = {
    playback_url: channelData?.data?.playback_url || "",
    rtmp_id: channelData?.data?.rtmp_id || "",
    rtmps_id: channelData?.data?.rtmps_id || "",
    srt_id: channelData?.data?.srt_id || "",
    Stream_access_key: channelData?.data?.Stream_access_key || "",
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    setEventData((prevState) => ({ ...prevState, eventType: e.target.value }));
  };

  const handleFileChange = (file, field) => {
    // Clear error message for the field
    setErrorMessage((prev) => ({
      ...prev,
      [field]: "",
    }));

    if (field === "images") {
      const filesArray = Array.from(file);

      // Filter out duplicate files based on file name
      const newFiles = filesArray.filter(
        (newFile) =>
          !eventData?.images?.some(
            (existingFile) => existingFile.name === newFile.name
          )
      );

      // Update eventData with the new files
      setEventData((prev) => ({
        ...prev,
        images: [...prev?.images, ...newFiles],
      }));
    } else {
      // Handle other file types
      setEventData((prev) => ({
        ...prev,
        [field]: file,
      }));
    }
  };

  const { isDragging, dragOverProps } = useDragDropHook((file) =>
    handleFileChange(file, "banner")
  );

  const handleValidation = () => {
    const newErrorMessage = {};

    if (!eventData.name.trim()) {
      newErrorMessage.name = "User name is required";
    }
    if (!eventData.contact.trim()) {
      newErrorMessage.contact = "Contact is required";
    }
    if (eventData.contact.trim().length < 12) {
      newErrorMessage.contact = "Mininum 10 characters";
    }
    if (!eventData.prlink.trim()) {
      newErrorMessage.prlink = "Event URL is required";
    }
    if (!eventData.venue_date) {
      newErrorMessage.venue_date = "Venue date is required";
    }
    if (!eventData.venue_time) {
      newErrorMessage.venue_time = "Venue time is required";
    }
    setErrorMessage(newErrorMessage);
    if (Object.keys(newErrorMessage)?.length === 0) {
      handleCreateEvent();
    }
  };

  // Updated handleCreateEvent function

  const handleCreateEvent = async () => {
    // Format the venue_date to YYYY-MM-DD
    const formattedVenueDate = eventData.venue_date.format("YYYY-MM-DD");

    // Format the venue_time to HH:MM
    const formattedVenueTime = eventData.venue_time.format("HH:mm");
    const formattedLiveTime = eventData.live_time
      ? eventData.live_time.format("HH:mm")
      : formattedVenueTime;
    const formattedEventData = {
      ...eventData,
      title: eventData.title.trim(),
      name: eventData.name.trim(),
      venue: eventData.name.trim(),
      template_desc: eventData.template_desc.trim(),
      prlink: eventData.prlink.trim(),
      live_time: formattedLiveTime,
      venue_date: formattedVenueDate,
      venue_time: formattedVenueTime,
      offline_video: optionData.file,
      scrolling_mess: eventData.scrolling_mess.trim(),
      channattahed: channelData?.data?.playback_url ? true : false,
      live_vidlink: optionData.youtubeStreamedUrl
        ? optionData.youtubeStreamedUrl
        : optionData.youtubeLiveUrl
        ? optionData.youtubeLiveUrl
        : hlsVideoUrl
        ? hlsVideoUrl
        : streamData.playback_url,
    };

    const videoData = new FormData();
    if (optionData.file) {
      videoData.append("offline_video", optionData.file);
    }
    if (eventData.invitation_video) {
      videoData.append("invitation_video", eventData.invitation_video);
    }
    // Deleting video fields from formattedEventData as they are handled separately
    delete formattedEventData.invitation_video;
    delete formattedEventData.offline_video;

    const updatedEventData = new FormData();
    Object.keys(formattedEventData).forEach((key) => {
      if (Array.isArray(formattedEventData[key])) {
        formattedEventData[key].forEach((file) =>
          updatedEventData.append(key, file)
        );
      } else {
        updatedEventData.append(key, formattedEventData[key]);
      }
    });
    console.log(optionData);
    await postEventData(updatedEventData, videoData); // Make sure videoData includes both videos
    setTermsAndConditions(false);
  };

  const handleDelete = (imgIndex) => {
    const updatedimagesPreviews = previews.images.filter(
      (_, index) => index !== imgIndex
    );
    const updatedimagesFiles = eventData.images.filter(
      (_, index) => index !== imgIndex
    );
    setPreviews((prev) => ({
      ...prev,
      images: updatedimagesPreviews,
    }));

    setEventData((prev) => ({
      ...prev,
      images: updatedimagesFiles,
    }));
  };

  const renderTopSection = () => (
    <section className={styles.topBlockStyle}>
      <div className={styles.titleAndArrowViewStyle}>
        <h5 className={styles.titleStyle}>Create Event</h5>
      </div>
      <p className={styles.subTitleStyle}>Proceed to create your event </p>
    </section>
  );

  const onChangeUrlInput = (e) => {
    let timer;
    const newTitleUrl = e.target.value;
    const restrictedInputs = [
      /http/i,
      /https/i,
      /:/,
      /\//,
      /"/,
      /'/,
      /\?/,
      /\{/,
      /\}/,
      /\[/,
      /\]/,
      /\!/,
      /\#/,
      /\%/,
      /\^/,
      /\(/,
      /\)/,
      /%/,
      /\s/,
      /;/,
      /_/,
      /\=/,
      /\+/,
      /\@/,
      /\$/,
      /\%/,
      /\&/,
      /\*/,
      /\</,
      /\>/,
      /\`/,
      /\~/,
      /\,/,
      /\./,
    ];

    const isValid = !restrictedInputs.some((regex) => regex.test(newTitleUrl));

    if (!isValid) {
      toast.error("Invalid input. Please avoid special characters");
      setTick(false);
      return;
    }

    setEventData({
      ...eventData,
      prlink: newTitleUrl,
    });
    if (newTitleUrl === "") {
      setNewPrlinks(null);
      setTick();
    }

    const searchUrl = async () => {
      try {
        setIsLoading(true);
        setNewPrlinks(null);
        setTick();
        const token = localStorage.getItem("accessToken");

        if (!token) {
          toast.error("No Token Found");
          setIsLoading(false);
          return;
        }

        const url = `${process.env.REACT_APP_BASE_URL}/events/check_link/`;
        const options = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ prlink: newTitleUrl }),
        };

        const res = await fetch(url, options);
        const data = await res.json();

        if (res.ok || res.status === 200) {
          setTick(true);
        } else {
          setNewPrlinks(data.new_prlinks);
          setTick(false);
        }
      } catch (e) {
        console.error("Error during fetch:", e);
      } finally {
        setIsLoading(false);
      }
    };

    if (timer) {
      clearTimeout(timer); // Clear any previous timer
    }

    if (newTitleUrl.length >= 3) {
      timer = setTimeout(() => {
        searchUrl(); // Call the searchUrl function after 3 seconds
      }, 2000);
    }
  };

  const renderEventSelection = () => {
    return (
      <>
        <Typography variant="h6" className={styles.uploadVideoTextStyle}>
          Event Type
        </Typography>
        <TextField
          select
          label="Select Event Type"
          value={option}
          onChange={(e) => {
            const value = e.target.value;
            setOption(value);
            setEventData((prevState) => ({
              ...prevState,
              template: value === "others" ? customInput : value,
            }));
          }}
          onFocus={() =>
            setErrorMessage({
              ...errorMessage,
              template: "",
            })
          }
          SelectProps={{
            IconComponent: ArrowDropDownIcon,
          }}
          helperText={errorMessage.template}
          error={!!errorMessage.template}
          variant="outlined"
          margin="normal"
          fullWidth
        >
          <MenuItem value="Birthday">Birthday</MenuItem>
          <MenuItem value="Marriage">Marriage</MenuItem>
          <MenuItem value="Saree Ceremony">Saree Ceremony</MenuItem>
          <MenuItem value="Cultural Event">Cultural Event</MenuItem>
          <MenuItem value="Family Reunion">Family Reunion</MenuItem>
          <MenuItem value="others">Others</MenuItem>
        </TextField>
        {option === "others" && (
          <TextField
            label="Enter Custom Event"
            value={customInput}
            onChange={(e) => {
              const value = e.target.value;
              setCustomInput(value);
              setEventData((prevState) => ({
                ...prevState,
                template: value, // Update template with custom input
              }));
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            style={{ marginTop: "10px" }}
          />
        )}

        {eventData.template && (
          <TextField
            label="Invitation Message"
            placeholder="Ex - Special invitation from family..."
            value={eventData?.template_desc}
            onChange={(e) => {
              setEventData((prevState) => ({
                ...prevState,
                template_desc: e.target.value,
              }));
            }}
            variant="outlined"
            margin="normal"
            fullWidth
          />
        )}
        <div>
          <div style={{ position: "relative", width: "100%" }}>
            <p style={{ color: "red", fontSize: "12px", marginBottom: "5px" }}>
              Note: Format like "ram123"
            </p>
            <TextField
              label="Set Your Own URL"
              type="text"
              value={eventData.prlink}
              onChange={(e) => onChangeUrlInput(e)}
              onFocus={() =>
                setErrorMessage({
                  ...errorMessage,
                  prlink: "",
                })
              }
              helperText={errorMessage.prlink}
              error={!!errorMessage.prlink}
              className={styles.inputStyle}
              sx={{ textDecoration: `${newPrlinks ? "line-through" : "none"}` }}
              fullWidth
            />

            {isLoading && (
              <div
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                }}
              >
                <CircularProgress size={24} />
              </div>
            )}

            <div
              style={{
                position: "absolute",
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              {tick === true ? (
                <SiTicktick style={{ color: "#3498db" }} size={24} />
              ) : (
                tick === false && (
                  <RxCrossCircled style={{ color: "red" }} size={24} />
                )
              )}
            </div>
          </div>
          {newPrlinks && (
            <p style={{ color: "red", marginBottom: "3px" }}>
              Link Already Used
            </p>
          )}
          <div style={{ display: "flex", gap: "8px" }}>
            {newPrlinks && <p>Samples: </p>}

            {newPrlinks &&
              newPrlinks.map((each, index) => (
                <button
                  style={{
                    border: "none",
                    backgroundColor: "#d4f5d4",
                    padding: "2px 5px",
                    borderRadius: "4px",
                    color: " #054c05",
                  }}
                  key={index}
                  onClick={() => {
                    setEventData({ ...eventData, prlink: each });
                    setNewPrlinks(null);
                    setTick(true);
                  }}
                >
                  {each}
                </button>
              ))}
          </div>
          {tick && (
            <p
              style={{
                color: "#32cd32",
                fontSize: "16px",
                fontWeight: "bold",
                textDecoration: "underline",
                letterSpacing: "0.5px",
              }}
            >
              https:eventsonlife.com/{eventData?.prlink}
            </p>
          )}
        </div>
      </>
    );
  };

  const renderMiddleSection = () => (
    <section className={styles.middleBlockStyle}>
      {renderUserDetailsSection()}
      {renderEventSelection()}
      {renderVenueDetailsSection()}
      {renderBannerAndInvitationSection()}
      {renderimagesAndVideoSection()}
      {renderUrlPreviewSection()}
      {showStreamType && renderStreamDataSection()}
      <div>
        {renderDropDownCheckSection()}
        {renderBottomSection()}
      </div>
    </section>
  );

  const renderUserDetailsSection = () => (
    <section className={styles.userDetailsBlockStyle}>
      <TextField
        label="User name"
        type="text"
        value={eventData.name}
        onChange={(e) =>
          setEventData({
            ...eventData,
            name: e.target.value,
          })
        }
        onFocus={() =>
          setErrorMessage({
            ...errorMessage,
            name: "",
          })
        }
        helperText={errorMessage.name}
        error={!!errorMessage.name}
        className={styles.inputStyle}
      />

      <PhoneInput
        country={"in"}
        value={eventData.contact}
        onChange={(value) =>
          setEventData({
            ...eventData,
            contact: value,
          })
        }
        onFocus={() =>
          setErrorMessage({
            ...errorMessage,
            contact: "",
          })
        }
      />
      {errorMessage.contact && (
        <p
          style={{
            color: "red",
            marginTop: "-25px",
            fontSize: "13px",
            font: "normal 400 12px / 24px Regular, sans-serif",
            marginBottom: "-22px",
          }}
        >
          {errorMessage.contact}
        </p>
      )}

      <TextField
        label="Event Title"
        type="text"
        value={eventData.title}
        onChange={(e) =>
          setEventData({
            ...eventData,
            title: e.target.value,
          })
        }
        onFocus={() =>
          setErrorMessage({
            ...errorMessage,
            title: "",
          })
        }
        helperText={errorMessage.title}
        error={!!errorMessage.title}
        className={styles.inputStyle}
      />
    </section>
  );

  const renderVenueDetailsSection = () => (
    <section className={styles.venueDetailsBlockStyle}>
      <Typography variant="h6" className={styles.uploadVideoTextStyle}>
        Venue Details
      </Typography>
      <TextField
        label="Venue"
        type="text"
        value={eventData.venue}
        onChange={(e) =>
          setEventData({
            ...eventData,
            venue: e.target.value,
          })
        }
        onFocus={() =>
          setErrorMessage({
            ...errorMessage,
            venue: "",
          })
        }
        helperText={errorMessage.venue}
        error={!!errorMessage.venue}
        className={styles.inputStyle}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={eventData.venue_date ? dayjs(eventData.venue_date) : null}
          onChange={(newValue) => {
            if (newValue && newValue.isValid && newValue.isValid()) {
              setEventData({ ...eventData, venue_date: newValue });
              setErrorMessage({ ...errorMessage, venue_date: "" });
            } else {
              setEventData({ ...eventData, venue_date: null });
              setErrorMessage({ ...errorMessage, venue_date: "Invalid date" });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Venue Date"
              helperText={errorMessage.venue_date}
              error={!!errorMessage.venue_date}
              className={styles.inputStyle}
            />
          )}
        />
      </LocalizationProvider>

      {errorMessage.venue_date && (
        <p
          style={{
            color: "red",
            marginTop: "-25px",
            fontSize: "13px",
            font: "normal 400 12px / 24px Regular, sans-serif",
            marginBottom: "-22px",
          }}
        >
          {errorMessage.venue_date}
        </p>
      )}

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileTimePicker
          value={eventData.venue_time ? dayjs(eventData.venue_time) : null}
          onChange={(newValue) => {
            if (newValue && newValue.isValid && newValue.isValid()) {
              setEventData({ ...eventData, venue_time: newValue });
              setErrorMessage({ ...errorMessage, venue_time: "" });
            } else {
              setEventData({ ...eventData, venue_time: null });
              setErrorMessage({ ...errorMessage, venue_time: "Invalid time" });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={errorMessage.venue_time}
              error={!!errorMessage.venue_time}
              className={styles.inputStyle}
            />
          )}
        />
      </LocalizationProvider>
      <p
        style={{
          color: "red",
          marginTop: "-25px",
          fontSize: "13px",
          font: "normal 400 12px / 24px Regular, sans-serif",
          marginBottom: "-22px",
        }}
      >
        {errorMessage.venue_time}
      </p>
      <Typography variant="h6" className={styles.uploadVideoTextStyle}>
        Live Timing
      </Typography>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileTimePicker
          value={
            eventData.live_time
              ? dayjs(eventData.live_time)
              : eventData?.venue_time
          }
          onChange={(newValue) => {
            if (newValue && newValue.isValid && newValue.isValid()) {
              setEventData({ ...eventData, live_time: newValue });
              setErrorMessage({ ...errorMessage, live_time: "" });
            } else {
              setEventData({ ...eventData, live_time: null });
              setErrorMessage({
                ...errorMessage,
                live_time: "Invalid Live time",
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              helperText={errorMessage.live_time}
              error={!!errorMessage.live_time}
              className={styles.inputStyle}
            />
          )}
        />
      </LocalizationProvider>
      <p className={styles.erroMessageStyle}>{errorMessage.live_time}</p>
      <TextField
        label="Scrolling Message"
        type="text"
        value={eventData.scrolling_mess}
        onChange={(e) =>
          setEventData({
            ...eventData,
            scrolling_mess: e.target.value,
          })
        }
        onFocus={() =>
          setErrorMessage({
            ...errorMessage,
            scrolling_mess: "",
          })
        }
        helperText={errorMessage.scrolling_mess}
        error={!!errorMessage.scrolling_mess}
        className={styles.inputStyle}
      />
    </section>
  );

  const renderBannerAndInvitationSection = () => (
    <section className={styles.bannerAndInvitationStyle}>
      {["banner", "invitation_card", "invitation_video"].map((field) => (
        <div key={field} className={styles.uploadMainBlockStytle}>
          <label className={styles.uploadLabelStyle}>
            {field === "banner"
              ? "Banner Image"
              : field === "invitation_card"
              ? "Invitation Card"
              : "Invitation Video"}
          </label>
          <div
            className={
              isDragging ? styles.dragZoneBlockStyle : styles.uplaodBlockStyle
            }
            {...dragOverProps}
          >
            <FileUploadInput
              label="upload"
              setSelectedFile={(file) => handleFileChange(file, field)}
              customLabelStyle={styles.uploadFileLableStyle}
            />
            <p className={styles.fileUploadInfoStyle}>
              {field === "invitation_video"
                ? "Supported format .mp4, .mov, .avi"
                : "Supported format .jpg, .jpeg, .png, .tiff"}
            </p>
          </div>
          {errorMessage[field] && (
            <p className={styles.erroMessageStyle}>{errorMessage[field]}</p>
          )}
          {eventData[field] && (
            <div className={styles.previewImgMainBlockStyle}>
              {field === "invitation_video" ? (
                <video className={styles.previewVideoBlockStyle} controls>
                  <source
                    src={
                      eventData[field] instanceof File
                        ? URL.createObjectURL(eventData[field])
                        : ""
                    }
                    type="video/mp4"
                  />
                </video>
              ) : (
                <div className={styles.previewImgBlockStyle}>
                  <img
                    src={
                      eventData[field] instanceof File
                        ? URL.createObjectURL(eventData[field])
                        : ""
                    }
                    alt={`${field} preview`}
                    className={styles.previewImageStyle}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      ))}
    </section>
  );

  const renderimagesAndVideoSection = () => (
    <section className={styles.imagesAndVideoStyle}>
      <div className={styles.uploadMainBlockStytle}>
        <label className={styles.uploadLabelStyle}>images</label>
        <div
          className={
            isDragging ? styles.dragZoneBlockStyle : styles.uplaodBlockStyle
          }
          {...dragOverProps}
        >
          <FileUploadInput
            label="upload"
            setSelectedFile={(file) => handleFileChange(file, "images")}
            customLabelStyle={styles.uploadFileLableStyle}
            multiple={true}
          />
          <p className={styles.fileUploadInfoStyle}>
            Supported format .jpg, .jpeg, .png, .tiff
          </p>
        </div>
        {errorMessage.images && (
          <p className={styles.erroMessageStyle}>{errorMessage.images}</p>
        )}
        {eventData?.images?.length > 0 && (
          <div className={styles.previewContainerStyle}>
            {eventData?.images?.map((file, index) => (
              <div key={index} className={styles.previewImgBlockStyle}>
                <img
                  src={URL.createObjectURL(file)}
                  alt={`Image_preview ${index}`}
                  className={styles.previewImageStyle}
                />
                <button
                  className={styles.deleteBtnStyle}
                  onClick={() => handleDelete(index)}
                >
                  x
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );

  const [keysCopyBtn, setKeysCopyBtn] = useState("");

  const handleCopy = (name, value) => {
    navigator.clipboard.writeText(value);
    toast.success(`${name} Copied to clipboard!`);
    setKeysCopyBtn(name);
    setTimeout(() => setKeysCopyBtn(""), 1500);
  };

  const renderUrlPreviewSection = () => {
    return (
      channelData.length === 0 && (
        <UrlPreview
          setEventData={setEventData}
          eventData={eventData}
          edit={false}
          setOptionData={setOptionData}
          title={eventData.title}
        />
      )
    );
  };

  const renderStreamDataSection = () => {
    return (
      streamData && (
        <div className={styles.container}>
          <h2 className={styles.heading}>Select Stream Type</h2>

          <div className={styles.radioGroup}>
            {/* Radio Buttons */}
            <label className={styles.radioLabel}>
              <input
                type="radio"
                value="RTMP"
                checked={selectedOption === "RTMP"}
                onChange={handleOptionChange}
                className={styles.radioInput}
              />
              RTMP
            </label>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                value="RTMPS"
                checked={selectedOption === "RTMPS"}
                onChange={handleOptionChange}
                className={styles.radioInput}
              />
              RTMPS
            </label>
            <label className={styles.radioLabel}>
              <input
                type="radio"
                value="SRT"
                checked={selectedOption === "SRT"}
                onChange={handleOptionChange}
                className={styles.radioInput}
              />
              SRT
            </label>
            {/* <label className={styles.radioLabel}>
              <input
                type="radio"
                value="Playback"
                checked={selectedOption === "Playback"}
                onChange={handleOptionChange}
                className={styles.radioInput}
              />
              Playback URL
            </label> */}
          </div>

          {/* Dynamic Input Fields */}
          <div
            className={`${
              new Date(eventData?.venue_date).setHours(0, 0, 0, 0) !==
              new Date().setHours(0, 0, 0, 0)
                ? styles.inputContainerBlur
                : ""
            } ${styles.inputContainer}`}
          >
            {selectedOption === "RTMP" && (
              <div className={styles.inputGroup}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <input
                    type="text"
                    value={streamData.rtmp_id}
                    readOnly
                    className={styles.inputField}
                  />
                  {keysCopyBtn === "RTMP ID" ? (
                    <p className={styles.copedSuccess}>Copied</p>
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleCopy("RTMP ID", streamData.rtmp_id);
                      }}
                    />
                  )}
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <input
                    type="text"
                    value={streamData.Stream_access_key}
                    readOnly
                    className={styles.inputField}
                  />
                  {keysCopyBtn === "Stream Key" ? (
                    <p className={styles.copedSuccess}>Copied</p>
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleCopy("Stream Key", streamData.Stream_access_key);
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            {selectedOption === "RTMPS" && (
              <div className={styles.inputGroup}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <input
                    type="text"
                    value={streamData.rtmps_id}
                    readOnly
                    className={styles.inputField}
                  />
                  {keysCopyBtn === "RTMPS ID" ? (
                    <p className={styles.copedSuccess}>Copied</p>
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleCopy("RTMPS ID", streamData.rtmps_id);
                      }}
                    />
                  )}
                </div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <input
                    type="text"
                    value={streamData.Stream_access_key}
                    readOnly
                    className={styles.inputField}
                  />
                  {keysCopyBtn === "RTMPS Key" ? (
                    <p className={styles.copedSuccess}>Copied</p>
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleCopy("RTMPS Key", streamData.Stream_access_key);
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            {selectedOption === "SRT" && (
              <div className={styles.inputGroup}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <input
                    type="text"
                    value={streamData.srt_id}
                    readOnly
                    className={styles.inputField}
                  />
                  {keysCopyBtn === "SRT ID" ? (
                    <p className={styles.copedSuccess}>Copied</p>
                  ) : (
                    <CopyIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        handleCopy("SRT ID", streamData.srt_id);
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            {/* {selectedOption === "Playback" && (
              <div className={styles.inputGroup}>
                <input
                  type="text"
                  value={streamData.playback_url}
                  readOnly
                  className={styles.inputField}
                />
              </div>
            )} */}
          </div>
          {new Date(eventData?.venue_date).setHours(0, 0, 0, 0) !==
            new Date().setHours(0, 0, 0, 0) && (
            <p style={{ color: "red" }}>
              Note: Keys Will be revelead on Event Scheduled Date
            </p>
          )}
        </div>
      )
    );
  };

  const renderDropDownCheckSection = () => {
    return (
      <DropDownCheck newEventData={eventData} setNewEventData={setEventData} />
    );
  };

  const renderBottomSection = () => (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={termsAndConditions}
            onChange={() => {
              setTermsAndConditions(!termsAndConditions);
            }}
          />
        }
        label={
          <p>
            Accept Terms and Conditions{" "}
            <a
              href="http://www.eventsonlife.com/terms-of-service"
              target="_blank"
            >
              For more Click Here
            </a>
          </p>
        }
        className="label-text-style"
      />

      {!termsAndConditions ? (
        <Button
          variant="contained"
          className={styles.createEventBtnStyle}
          disabled={true}
        >
          Create Event
        </Button>
      ) : (
        <Button
          variant="contained"
          className={styles.createEventBtnStyle}
          onClick={() => handleValidation()}
          disabled={evedisabled}
        >
          Create Event
        </Button>
      )}
    </div>
  );

  const truncateName = (name) => {
    if (name.length > 10) {
      return name.substring(0, 10) + "...";
    } else if (name.length > 15) {
      return name.substring(0, 10) + "...";
    }
    return name;
  };

  return (
    <>
      <div
        style={{
          display: `${LoadingViewFor5 === false ? "inline" : "none"}`,
        }}
        className={styles.mainContainerForPage}
      >
        <main className={styles.mainContainerStyle}>
          <div className={styles.subContainerStyle}>
            {renderTopSection()}
            {renderMiddleSection()}
            <Dialog
              open={
                showLoader &&
                (uploadingInvitation !== 0 || uploadingOfflineVideo !== 0)
              }
              fullWidth
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <DialogTitle
                sx={{
                  textAlign: "center",
                  marginBottom: "10px",
                  fontWeight: "700",
                  color: "#FF5722",
                  fontSize: "14px",
                }}
              >
                Files Are Being Uploaded. Please Do Not Close This Window.
              </DialogTitle>
              <div
                style={{
                  padding: "0px 30px 10px 30px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {eventData.invitation_video && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      marginBottom: "10px",
                    }}
                  >
                    {uploadingInvitation === 100 ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#333",
                          }}
                        >
                          {truncateName(eventData.invitation_video.name)}
                        </p>
                        <SiTicktick style={{ color: "#4CAF50" }} />
                        {parseInt(uploadingInvitation)}%
                      </div>
                    ) : (
                      <div>
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#333",
                          }}
                        >
                          {truncateName(eventData.invitation_video.name)}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <LinearProgress
                            style={{
                              height: "10px",
                              borderRadius: "5px",
                              backgroundColor: "#e0e0e0",
                              borderColor: "#9e9e9e",
                            }}
                            sx={{
                              width: "200px",
                              "& .MuiLinearProgress-bar": {
                                backgroundColor:
                                  uploadingInvitation === 100
                                    ? "#4CAF50"
                                    : "#2196F3",
                              },
                            }}
                            variant="determinate"
                            value={uploadingInvitation}
                          />
                          {parseInt(uploadingInvitation)}%
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {optionData.option === "file" && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {uploadingOfflineVideo === 100 ? (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#333",
                          }}
                        >
                          {truncateName(
                            optionData.option === "file" && optionData.file.name
                          )}
                        </p>
                        <SiTicktick style={{ color: "#FF9800" }} />
                        {parseInt(uploadingOfflineVideo)}%
                      </div>
                    ) : (
                      <div>
                        <p
                          style={{
                            textAlign: "center",
                            fontSize: "14px",
                            color: "#333",
                          }}
                        >
                          {truncateName(
                            optionData.option === "file" && optionData.file.name
                          )}
                        </p>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "5px",
                          }}
                        >
                          <LinearProgress
                            style={{
                              height: "10px",
                              borderRadius: "5px",
                              backgroundColor: "#e0e0e0",
                              borderColor: "#9e9e9e",
                            }}
                            sx={{
                              width: "200px",
                              "& .MuiLinearProgress-bar": {
                                backgroundColor:
                                  uploadingOfflineVideo === 100
                                    ? "#FF9800"
                                    : "#2196F3",
                              },
                            }}
                            variant="determinate"
                            value={uploadingOfflineVideo}
                          />
                          {parseInt(uploadingOfflineVideo)}%
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Dialog>
          </div>
        </main>

        <Dialog
          open={
            showLoader &&
            (uploadingInvitation !== 0 || uploadingOfflineVideo !== 0)
          }
          fullWidth
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DialogTitle
            sx={{
              textAlign: "center",
              marginBottom: "10px",
              fontWeight: "700",
              color: "#FF5722",
              fontSize: "14px",
            }}
          >
            Files Are Being Uploaded. Please Do Not Close This Window.
          </DialogTitle>
          <div
            style={{
              padding: "0px 30px 10px 30px",
              isplay: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {eventData.invitation_video && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  marginBottom: "10px",
                }}
              >
                {uploadingInvitation === 100 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#333",
                      }}
                    >
                      {truncateName(eventData.invitation_video.name)}
                    </p>
                    <SiTicktick style={{ color: "#4CAF50" }} />
                    {parseInt(uploadingInvitation)}%
                  </div>
                ) : (
                  <div>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#333",
                      }}
                    >
                      {truncateName(eventData.invitation_video.name)}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <LinearProgress
                        style={{
                          height: "10px",
                          borderRadius: "5px",
                          backgroundColor: "#e0e0e0",
                          borderColor: "#9e9e9e",
                        }}
                        sx={{
                          width: "200px",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor:
                              uploadingInvitation === 100
                                ? "#4CAF50"
                                : "#2196F3",
                          },
                        }}
                        variant="determinate"
                        value={uploadingInvitation}
                      />
                      {parseInt(uploadingInvitation)}%
                    </div>
                  </div>
                )}
              </div>
            )}
            {optionData.option === "file" && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {uploadingOfflineVideo === 100 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#333",
                      }}
                    >
                      {truncateName(
                        optionData.option === "file" && optionData.file.name
                      )}
                    </p>
                    <SiTicktick style={{ color: "#FF9800" }} />
                    {parseInt(uploadingOfflineVideo)}%
                  </div>
                ) : (
                  <div>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        color: "#333",
                      }}
                    >
                      {truncateName(
                        optionData.option === "file" && optionData.file.name
                      )}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <LinearProgress
                        style={{
                          height: "10px",
                          borderRadius: "5px",
                          backgroundColor: "#e0e0e0",
                          borderColor: "#9e9e9e",
                        }}
                        sx={{
                          width: "200px",
                          "& .MuiLinearProgress-bar": {
                            backgroundColor:
                              uploadingOfflineVideo === 100
                                ? "#FF9800"
                                : "#2196F3",
                          },
                        }}
                        variant="determinate"
                        value={uploadingOfflineVideo}
                      />
                      {parseInt(uploadingOfflineVideo)}%
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Dialog>
      </div>
      <div
        style={{
          display: `${LoadingViewFor5 ? "flex" : "none"}`,
          opacity: "1",
          transition: "opacity 1s ease-in-out",
        }}
        className={styles.loaderContainer}
      >
        <div className={styles.loader}></div>
      </div>
    </>
  );
};
export default EventsForm;

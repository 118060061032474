import { createContext, useCallback, useMemo, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { routeNames } from "constants";
import {
  allChannelsApi,
  allRegionsApi,
  createChannelApi,
  creditsApi,
  deleteChannelApi,
  deleteEventDataApi,
  eventDataApi,
  getAllEventsApi,
  pricingDataApi,
  updateCustomHls,
  updateEventDataApi,
} from "networking/apis/users";
import { useToastContext } from "hooks/usetoastcontext";
import axios from "axios";
import { toast } from "react-toastify";

const AppDataContext = createContext();
const fonts = [
  "Dancing Script",
  "Great Vibes",
  "Sacramento",
  "Lobster",
  "Alex Brush",
  "Pacifico",
  "Allura",
  "Satisfy",
  "Pinyon Script",
  "Cookie",
  "Rochester",
  "Rosarivo",
  "Fredericka the Great",
  "Cedarville Cursive",
  "Clicker Script",
  "French Script MT",
  "Handlee",
  "Berkshire Swash",
  "Playball",
  "Niconne",
  "Zeyada",
  "Alfa Slab One",
  "Abril Fatface",
  "Baloo 2",
  "Baskervville",
  "Parisienne",
  "Merienda",
  "Italiana",
  "Sirin Stencil",
  "Allerta Stencil",
  "Titan One",
  "Gloock",
  "Belgrano",
  "Kalam",
  "Sofia",
  "Marck Script",
  "Vampiro One",
  "Srisakdi",
  "Pattaya",
  "Carme",
  "Roboto Slab",
  "Julius Sans One",
  "Noto Serif",
  "Merriweather",
  "Droid Serif",
  "Righteous",
  "Rufina",
];

const AppDataProvider = (props) => {
  const navigate = useNavigate();

  // contexts
  const { showToast } = useToastContext();

  // states:
  const [eventData, setEventData] = useState({
    name: "",
    contact: "",
    password: "",
    title: "",
    venue: "",
    fontStyle: fonts[0],
    venue_date: null,
    venue_time: null,
    live_vidlink: "",
    banner: null,
    invitation_card: null,
    invitation_video: null,
    offline_video: null,
    images: [],
    is_secure: "",
    // password: "",
    template: "",
    template_desc: "",
    template_css: {
      bgColor: "",
      bgImage: "",
      bgOpacity: null,
      textColor: "",
      textFontFamily: "",
      titleFontSize: "",
      titleFontWeight: "",
      headingFontSize: "",
      bgGradient: "",
    },
    ads_disabled: false,
  });

  const [sendCredits, setSendCredits] = useState(false);
  const [openliveComplitionDialog, setOpenliveComplitionDialog] =
    useState(false);

  //state to track video uploading progress
  const [uploadingInvitation, setuploadingInvitation] = useState(0);
  const [uploadingOfflineVideo, setuploadingOfflineVideo] = useState(0);
  const [checkRecorded, setCheckRecorded] = useState(0);

  const resetEventData = () => {
    setEventData({
      ...eventData,
      name: "",
      nameFontFamily: "",
      nameFontSize: "",
      nameColor: "",
      nameFontWeight: "normal",
      contact: "",
      password: "",
      title: "",
      venue: "",
      fontStyle: fonts[0],
      venue_date: null,
      venue_time: null,
      live_vidlink: "",
      banner: "",
      invitation_card: "",
      invitation_video: "",
      offline_video: "",
      images: [] || null,
      is_secure: "",
      // password: "",
      template: "",
      template_desc: "",
      template_css: {
        bgColor: "",
        bgImage: null,
        bgOpacity: null,
        textColor: "",
        textFontFamily: "",
      },
      ads_disabled: false,
    });
  };

  const [registrationFormData, setRegistrationFormData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: "",
    password: "",
    re_password: "",
    role: 1,
  });

  const resetRegistrationFormData = () => {
    setRegistrationFormData({
      ...registrationFormData,
      first_name: "",
      last_name: "",
      phone_number: "",
      email: "",
      password: "",
      re_password: "",
    });
  };

  const [allEventsData, setAllEventsData] = useState([]);
  const [singleEventData, setSingleEventData] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [evedisabled, setEveDisabled] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [step, setStep] = useState(1);

  const [hlsVideoUrl, setHlsVideoUrl] = useState("");

  const [regions, setRegions] = useState([]);

  const [channelData, setChannelData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [userCredits, setUserCredits] = useState([]);

  const [allChannelsData, setAllChannelsData] = useState([]);

  const [singleChannelData, setSingleChannelData] = useState(null);

  const [pricingData, setPricingData] = useState([]);

  const [showStreamType, setShowStreamType] = useState(false);

  const [upiDetails, setUPIDetails] = useState(null);

  const [feedbackData, setFeedbackData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [ads, setAds] = useState(null);

  // funtion to post event data
  const postEventData = useCallback(async (data, videoData) => {
    try {
      setShowLoader(true);
      setEveDisabled(true);
      const response = await eventDataApi(data);
      if (response.status === 200 || response.statusText === "OK") {
        if (videoData.has("invitation_video")) {
          const inviVideoFile = videoData.get("invitation_video");
          const inviVideoUploaded = await fileInvitationVideoFiles3({
            file: inviVideoFile, // Pass the file
            apiUrl: `${process.env.REACT_APP_MEDIA_URL}/api/events/inviVideo_chunk/`,
            type: "invitation_video",
            mylink: response.data.prlink,
          });
        }

        // Check if offline_video is present
        if (videoData.has("offline_video")) {
          const offlineVideoFile = videoData.get("offline_video");
          await fileOfflineVideoFiles3({
            file: offlineVideoFile, // Pass the file
            apiUrl: `${process.env.REACT_APP_MEDIA_URL}/api/events/offline_chunk/`,
            type: "offline_video",
            mylink: response.data.prlink,
          });
        }

        showToast.success("Event created successfully");
        resetEventData();
        setChannelData([]);
        getUserCredits();
        setEveDisabled(false);

        if (
          videoData.has("offline_video") ||
          videoData.has("invitation_video")
        ) {
          console.log(
            videoData.has("offline_video") || videoData.has("invitation_video")
          );
          // navigate(routeNames.dashBoard);
        } else {
          navigate(routeNames.dashBoard);
        }
      } else {
        setShowLoader(false);
        resetEventData();
        setEveDisabled(false);
      }
    } catch (error) {
      if (
        error.code === "ERR_NETWORK" ||
        error.code === "ERR_CONNECTION_REFUSED"
      ) {
        return navigate("/maintenance");
      }
      setShowLoader(false);
      setEveDisabled(false);
      toast.error(error.message);
      console.error("Error in postEventData:", error); // Improved error logging
    } finally {
      setHlsVideoUrl("");
      setChannelData([]);
    }
  }, []);
  const modifyFileName = (fileName) => {
    // Replace spaces with hyphens and remove special characters except - . _
    return fileName
      .toLowerCase() // Optional: convert to lowercase
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^a-z0-9-_.]/g, "") // Remove any special characters except - . _
      .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
  };

  const fileInvitationVideoFiles3 = async (data) => {
    const { file, type, apiUrl, mylink } = data;
    if (!file) return;
    try {
      // Request a presigned URL from the backend
      const modifiedFileName = modifyFileName(file.name);
      const encodedFileName = encodeURIComponent(modifiedFileName);

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/events/file_upload_link/`,
        {
          params: {
            file_name: encodedFileName, // URL-encode the file name
            file_type: file.type,
            pr_link: mylink,
            type,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      const { link, key } = response.data;

      // Create a new XMLHttpRequest to upload the file with progress tracking
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", link, true);

      // Update progress
      xhr.upload.onprogress = function (event) {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setuploadingInvitation(progress);
        }
      };

      // On success
      xhr.onload = async function () {
        if (xhr.status === 200) {
          const options = {
            prlink: mylink,
            finalLink: key,
          };

          // Send the final link to the API
          const postResponse = await axios.post(apiUrl, options, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          });

          if (postResponse.status === 200) {
            toast.success("Invitation Video successfully Uploaded!");
            return true;
          } else {
            console.error("Failed to update server with final file link.");
            toast.error("Failed to update. Try Again");
            return false;
          }
        } else {
          toast.error("Error during file upload.");
          console.error("Error during file upload.");
          return false;
        }
      };

      // On error
      xhr.onerror = function () {
        console.error("Network error during file upload.");
        toast.error("Network error during file upload.");

        return false;
      };

      // Send the file
      xhr.send(file);
    } catch (err) {
      console.error("Upload error:", err);
      if (err.code === "ERR_NETWORK" || err.code === "ERR_CONNECTION_REFUSED") {
        return navigate("/maintenance");
      }
      return false;
    }
  };

  const fileOfflineVideoFiles3 = async (data) => {
    const { file, type, apiUrl, mylink } = data;
    if (!file) return;
    try {
      // Request a presigned URL from the backend
      const modifiedFileName = modifyFileName(file.name);
      const encodedFileName = encodeURIComponent(modifiedFileName);

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/events/file_upload_link/`,
        {
          params: {
            file_name: encodedFileName, // URL-encode the file name
            file_type: file.type,
            pr_link: mylink,
            type,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      const { link, key } = response.data;

      // Create a new XMLHttpRequest to upload the file with progress tracking
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", link, true);

      // Update progress
      xhr.upload.onprogress = function (event) {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;

          setuploadingOfflineVideo(progress);
        }
      };

      // On success
      xhr.onload = async function () {
        if (xhr.status === 200) {
          const options = {
            prlink: mylink,
            finalLink: key,
          };

          // Send the final link to the API
          const postResponse = await axios.post(apiUrl, options, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          });

          if (postResponse.status === 200) {
            toast.success("Invitation Video successfully Uploaded!");
            return true;
          } else {
            console.error("Failed to update server with final file link.");
            toast.error("Failed to update server with final file link.");
            return false;
          }
        } else {
          console.error("Error during file upload.");
          toast.error("Error during file upload.");
          return false;
        }
      };

      // On error
      xhr.onerror = function () {
        console.error("Network error during file upload.");
        toast.error("Network error during file upload.");
        return false;
      };

      // Send the file
      xhr.send(file);
    } catch (err) {
      console.error("Upload error:", err);
      if (err.code === "ERR_NETWORK" || err.code === "ERR_CONNECTION_REFUSED") {
        return navigate("/maintenance");
      }
      return false;
    }
  };

  const updateEventData = useCallback(
    async (data, optionData, selectedInvitationVideo, selectedOfflineVideo) => {
      let response;
      try {
        setShowLoader(true);
        response = await updateEventDataApi(data);
        if (response.status === 200) {
          if (
            (optionData && !optionData.file) ||
            selectedInvitationVideo !== null ||
            selectedOfflineVideo !== null
          ) {
            navigate("/dashboard");
            showToast.success("Event updated successfully");
          }

          resetEventData();
        } else {
          showToast.error("Got Error Please Try Again");

          navigate("/dashboard");
          setShowLoader(false);
        }
      } catch (error) {
        if (
          error.code === "ERR_NETWORK" ||
          error.code === "ERR_CONNECTION_REFUSED"
        ) {
          return navigate("/maintenance");
        }
        setShowLoader(false);

        showToast.error(error.message);
      } finally {
        setHlsVideoUrl("");
      }
    },
    []
  );

  // funtion to delete event data

  const deleteEventData = useCallback(async (id) => {
    try {
      setShowLoader(true);
      const response = await deleteEventDataApi(id);
      if (response.status === 200) {
        setShowLoader(false);
        showToast.success("Event deleted successfully");
        setAllEventsData((prevEvents) =>
          prevEvents.filter((event) => event.id !== id)
        );
        setOpenDelete(false);
      } else {
        setShowLoader(false);
        setOpenDelete(false);
      }
    } catch (error) {
      if (
        error.code === "ERR_NETWORK" ||
        error.code === "ERR_CONNECTION_REFUSED"
      ) {
        return navigate("/maintenance");
      }
      setShowLoader(false);
      setOpenDelete(false);
    }
  }, []);

  // funtion to get all events
  const getAllEvents = useCallback(async () => {
    try {
      setShowLoader(true);
      const response = await getAllEventsApi();
      if (response.status === 200) {
        setAllEventsData(response.data);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      if (
        error.code === "ERR_NETWORK" ||
        error.code === "ERR_CONNECTION_REFUSED"
      ) {
        return navigate("/maintenance");
      }
      setShowLoader(false);
    }
  }, []);

  // funtion to get single event
  const getEvent = useCallback(async (id) => {
    try {
      setShowLoader(true);

      const urlToGetEventData = `${process.env.REACT_APP_BASE_URL}/events/get_event/`;
      const options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prlink: id,
        }),
      };

      const response = await fetch(urlToGetEventData, options);
      const responseData = await response.json();
      if (
        response.status === 500 ||
        responseData.error === "No Event matches the given query."
      ) {
        return navigate("/page-not-found");
      } else if (response.status === 200) {
        setSingleEventData(responseData);
        setShowLoader(false);
        return responseData;
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      if (
        error.code === "ERR_NETWORK" ||
        error.code === "ERR_CONNECTION_REFUSED"
      ) {
        return navigate("/maintenance");
      }
      setShowLoader(false);
    }
  }, []);

  // function to get all regions
  const fetchRegions = useCallback(async () => {
    try {
      const res = await allRegionsApi();
      setRegions(res.data);
    } catch (error) {
      if (
        error.code === "ERR_NETWORK" ||
        error.code === "ERR_CONNECTION_REFUSED"
      ) {
        return navigate("/maintenance");
      }
      console.error("Error fetching regions:", error);
    }
  }, []);

  // function to create channel
  const handleProceed = useCallback(async (selectedRowId, edit, payload) => {
    try {
      setLoading(true);
      const response = await createChannelApi(payload);
      if (response.status === 200) {
        if (edit === true) {
          setSingleChannelData(response.data);
          const formDataTOUpdatePlabackURL = new FormData();
          formDataTOUpdatePlabackURL.append("id", selectedRowId);
          formDataTOUpdatePlabackURL.append(
            "live_vidlink",
            response.data.playback_url
          );
          const r = await updateEventDataApi(formDataTOUpdatePlabackURL);

          if (r.status === 200) {
            toast.success("Channel Created");
            window.location.reload();
          } else {
            toast.error("Got Error While Channel Creating");
          }
        } else {
          setChannelData(response);
        }

        setLoading(false);
        setShowStreamType(true);
        return true;
      } else {
        setLoading(false);
        setShowStreamType(false);
        return false;
      }
    } catch (error) {
      if (
        error.code === "ERR_NETWORK" ||
        error.code === "ERR_CONNECTION_REFUSED" ||
        error.code === "ERR_CONNECTION_REFUSED"
      ) {
        return navigate("/maintenance");
      }
      setLoading(false);
      setShowStreamType(false);
      return false;
    }
  }, []);

  // function to get credits
  const getUserCredits = useCallback(async () => {
    try {
      const response = await creditsApi();
      if (response.status === 200) {
        setUserCredits(response.data);
        // setShowLoader(false);
      }
    } catch (error) {
      if (
        error.code === "ERR_NETWORK" ||
        error.code === "ERR_CONNECTION_REFUSED"
      ) {
        return navigate("/maintenance");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, []);

  // function to get all channels data
  const getAllChannelsApi = useCallback(async () => {
    try {
      setShowLoader(true);
      const response = await allChannelsApi();
      if (response.status === 200) {
        setShowLoader(false);
        setAllChannelsData(response.data);
      } else {
        setShowLoader(false);
      }
    } catch (error) {
      if (
        error.code === "ERR_NETWORK" ||
        error.code === "ERR_CONNECTION_REFUSED"
      ) {
        return navigate("/maintenance");
      }
      setShowLoader(false);
    } finally {
      setLoading(false);
    }
  }, []);

  // function to delete channel
  const deleteChannel = useCallback(async (id, edit) => {
    try {
      const response = await deleteChannelApi(id);
      if (response.status === 200) {
        showToast.success("Channel deleted");
        if (edit) {
          window.location.reload();
        }
      }
    } catch (error) {
      if (
        error.code === "ERR_NETWORK" ||
        error.code === "ERR_CONNECTION_REFUSED"
      ) {
        return navigate("/maintenance");
      }
    }
  }, []);

  // function to get single channel data

  const path = useParams();

  const getChannelData = async (id) => {
    const accessToken = localStorage.getItem("accessToken");
    try {
      const url = `${process.env.REACT_APP_BASE_URL}/stream/get_event_channel/`;
      const response = await axios.post(
        url,
        { prlink: id },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      // Check if response status is OK
      if (response && response.status === 200 && response.data) {
        setSingleChannelData(response.data);
      } else {
        setSingleChannelData(null);
      }
    } catch (error) {
      if (
        error.code === "ERR_NETWORK" ||
        error.code === "ERR_CONNECTION_REFUSED"
      ) {
        return navigate("/maintenance");
      }
      setSingleChannelData(null);
      console.error("Error fetching channel data:", error.message || error);
    }
  };

  // function to get pricing data
  const getPricingData = useCallback(async () => {
    try {
      setShowLoader(true);
      const response = await pricingDataApi();

      if (response.status === 200) {
        setPricingData(response.data);
      } else {
        // Handle cases when the response is not successful but don't affect the page
        console.warn(
          "Pricing data fetch unsuccessful, status code:",
          response.status
        );
        setPricingData(null); // Optional: Clear or set a fallback value
      }
    } catch (error) {
      if (
        error.code === "ERR_NETWORK" ||
        error.code === "ERR_CONNECTION_REFUSED"
      ) {
        // Handle network errors gracefully
        console.error("Network error, redirecting to maintenance page:", error);
        return navigate("/maintenance");
      }
      console.error("Error fetching pricing data:", error);
      setPricingData(null); // Optional: Set fallback data
    } finally {
      setShowLoader(false); // Ensure loader is hidden after the operation
    }
  }, []);

  // function to post user feedback data

  // function to update custom hls
  const updateCustomHlsApi = useCallback(async (id) => {
    try {
      setLoading(true);
      const response = await updateCustomHls(id);
      if (response.status === 200) {
        setLoading(false);

        return true;
      } else {
        setLoading(false);
        return false;
      }
    } catch (error) {
      if (
        error.code === "ERR_NETWORK" ||
        error.code === "ERR_CONNECTION_REFUSED"
      ) {
        return navigate("/maintenance");
      }
      setLoading(false);
      return false;
    }
  }, []);

  const getUPIPaymentDetails = async () => {
    const accessToken = localStorage.getItem("accessToken");
    const url = `${process.env.REACT_APP_BASE_URL}/pay/upipays/`;

    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (res.statusText === "OK" && res.status === 200) {
        let { data } = res;
        setUPIDetails(data);
      }
    } catch (e) {
      console.error(e.message);
    }
  };

  const getAds = async () => {
    const url = `${process.env.REACT_APP_BASE_URL}/pay/scroller/`;
    try {
      const res = await axios(url);
      if (res && res.data) {
        setAds(res.data);
      }
      return res.data;
    } catch (e) {
      console.error(e.message);
    }
  };

  const getStreamStatus = useCallback(async (id) => {
    if (!id) {
      return;
    }
    const url = `${process.env.REACT_APP_BASE_URL}/stream/stream_status/${id}/`;
    const accessToken = localStorage.getItem("accessToken");
    try {
      const res = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (e) {
      console.error(e);
    }
  }, []);

  const recordingCheck = useCallback(async (id) => {
    if (id) {
      const url = `${process.env.REACT_APP_BASE_URL}/stream/check_recorded/${id}/`;
      try {
        let res = await axios.get(url);
        if (res.status === 200) {
          setCheckRecorded(true);
          return true;
        } else {
          setCheckRecorded(false);
          return false;
        }
      } catch (e) {
        setCheckRecorded(false);
        console.error(e.message);
        return false;
      }
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      // values:
      sendCredits,
      eventData,
      registrationFormData,
      allEventsData,
      singleEventData,
      regions,
      channelData,
      loading,
      userCredits,
      allChannelsData,
      singleChannelData,
      pricingData,
      feedbackData,
      showLoader,
      step,
      openDelete,
      hlsVideoUrl,
      showStreamType,
      evedisabled,
      uploadingInvitation,
      uploadingOfflineVideo,
      upiDetails,
      ads,
      openliveComplitionDialog,
      checkRecorded,

      // functions:
      setSendCredits,
      setEventData,
      setRegistrationFormData,
      resetRegistrationFormData,
      setAllEventsData,
      setSingleEventData,
      setRegions,
      setLoading,
      setUserCredits,
      setAllChannelsData,
      setSingleChannelData,
      setPricingData,
      setFeedbackData,
      setShowLoader,
      setStep,
      postEventData,
      updateEventData,
      deleteEventData,
      getAllEvents,
      getEvent,
      fetchRegions,
      setChannelData,
      getAllChannelsApi,
      getChannelData,
      getPricingData,
      handleProceed,
      getUserCredits,
      deleteChannel,
      setOpenDelete,
      setHlsVideoUrl,
      setShowStreamType,
      updateCustomHlsApi,
      setEveDisabled,
      setuploadingInvitation,
      setuploadingOfflineVideo,
      getUPIPaymentDetails,
      setUPIDetails,
      getAds,
      getStreamStatus,
      setOpenliveComplitionDialog,
      recordingCheck,
      setCheckRecorded,
    }),
    [
      //values:
      sendCredits,
      eventData,
      registrationFormData,
      allEventsData,
      singleEventData,
      regions,
      channelData,
      loading,
      userCredits,
      allChannelsData,
      singleChannelData,
      pricingData,
      feedbackData,
      showLoader,
      step,
      openDelete,
      hlsVideoUrl,
      showStreamType,
      evedisabled,
      uploadingInvitation,
      upiDetails,
      ads,
      openliveComplitionDialog,
      checkRecorded,

      //functions:
      setSendCredits,
      setEventData,
      setRegistrationFormData,
      resetRegistrationFormData,
      setAllEventsData,
      setSingleEventData,
      setRegions,
      setLoading,
      setUserCredits,
      setAllChannelsData,
      setSingleChannelData,
      setPricingData,
      setFeedbackData,
      setShowLoader,
      setStep,
      postEventData,
      updateEventData,
      deleteEventData,
      getAllEvents,
      getEvent,
      fetchRegions,
      setChannelData,
      getAllChannelsApi,
      getChannelData,
      getPricingData,
      handleProceed,
      getUserCredits,
      deleteChannel,
      setOpenDelete,
      setHlsVideoUrl,
      setShowStreamType,
      updateCustomHlsApi,
      setEveDisabled,
      setuploadingInvitation,
      setuploadingOfflineVideo,
      getUPIPaymentDetails,
      setUPIDetails,
      getAds,
      getStreamStatus,
      setOpenliveComplitionDialog,
      recordingCheck,
      setCheckRecorded,
    ]
  );

  return (
    <AppDataContext.Provider value={memoizedValue}>
      {props.children}
    </AppDataContext.Provider>
  );
};

export { AppDataContext, AppDataProvider };

import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  getUserDataApi,
  userLoginApi,
  userSignUpApi,
  editUserInfoApi,
} from "networking/apis/users";
import { routeNames } from "constants";
import { setAxiosSession } from "utils/auth";
import { getDataFromToken, isTokenExpired } from "utils/jwt";
import { useAppDataContext } from "hooks/useappdatacontext";
import { useToastContext } from "hooks/usetoastcontext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

// Authentication context
const AuthContext = createContext();

const AuthProvider = (props) => {
  const navigate = useNavigate();
  const { setShowLoader, resetRegistrationFormData } = useAppDataContext();
  const { showToast } = useToastContext();

  const [isInitialized, setIsInitialized] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [loginAuthError, setLoginAuthError] = useState("");
  const [userData, setUserData] = useState(null);
  const [adminData, setAdminData] = useState(null);
  const [userType, setUserType] = useState(null);
  // Initialize authentication
  const initialize = useCallback(async () => {
    try {
      const loggedIn = localStorage.getItem("loggedIn");
      setIsLoggedIn(loggedIn === "true");
      setIsInitialized(true);
      const token = localStorage.getItem("accessToken");
      if (token) {
        if (isTokenExpired(token)) {
          await refreshToken(); // Attempt to refresh the token if expired
        } else {
          setAxiosSession(token);
          const tokenData = await getDataFromToken(token);
          setUserType(tokenData.user_type);
          if (loggedIn === "true") {
            if (tokenData.user_type === "User") {
              setUserData(tokenData);
            } else if (tokenData.user_type === "Admin") {
              setAdminData(tokenData);
            }
          }
        }
      } else {
        logOut();
      }
    } catch (error) {
      setIsInitialized(true);
      setIsLoggedIn(false);
      setAuthError(error.message);
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);

  const refreshToken = async () => {
    try {
      const refreshToken = localStorage.getItem("refreshToken");
      const response = await axios.post(
        `${process.env.REACT_APP_MEDIA_URL}/api/jwt/refresh/`,
        {
          refresh: refreshToken,
        }
      );
      const { access } = response.data;
      localStorage.setItem("accessToken", access);
      setAxiosSession(access);
      return access;
    } catch (error) {
      console.error("Failed to refresh token:", error);
      logOut();
    }
  };

  const userRegister = async (registerData) => {
    setShowLoader(true);
    try {
      const response = await userSignUpApi(registerData);
      if (response.status === 201) {
        showToast.success("Please check your mail to activate your account");
        resetRegistrationFormData();
      }
    } catch (error) {
      if (
        error.code === "ERR_NETWORK" ||
        error.code === "ERR_CONNECTION_REFUSED"
      ) {
        return navigate("/maintenance");
      }
      showToast.error(error.email);
      setAuthError(error.message);
    } finally {
      setShowLoader(false);
    }
  };

  const validateEmail = (email) => {
    // Regular expression to validate the email format
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const reVerify = async (mail) => {
    if (mail === "") {
      return toast.warning("Mail Can't be empty");
    }
    if (!validateEmail(mail)) {
      return toast.error("Invalid email format!");
    }
    let reVerifyURL = `${process.env.REACT_APP_MEDIA_URL}/api/users/resend_activation/`;
    let options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: mail }),
    };

    let res = await fetch(reVerifyURL, options);
    if (res.ok) {
      toast.success("Please Check your mail");
    }
    setLoginAuthError("");
  };

  const userLogin = async (loginData) => {
    setShowLoader(true);
    try {
      const response = await userLoginApi(loginData);
      if (response.status === 200) {
        const { access, refresh } = response.data;
        localStorage.setItem("accessToken", access);
        localStorage.setItem("refreshToken", refresh);
        localStorage.setItem("loggedIn", "true");
        setAxiosSession(access);
        setIsLoggedIn(true);
        navigate(routeNames.dashBoard);
        await getUserData();
      }
    } catch (error) {
      if (
        error.code === "ERR_NETWORK" ||
        error.code === "ERR_CONNECTION_REFUSED"
      ) {
        return navigate("/maintenance", { replace: true });
      } else if (error.status === 401) {
        let reCheclWhy401Error = `${process.env.REACT_APP_MEDIA_URL}/status/`;
        let options = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: loginData.email }),
        };
        try {
          let res = await fetch(reCheclWhy401Error, options);
          let { message } = await res.json();
          if (res.ok) {
            console.log("message");
          } else {
            toast.error(message);
            if (message === "User not verified") {
              return setLoginAuthError(
                <span>
                  {message}.{" "}
                  <a
                    href="#"
                    onClick={() => {
                      reVerify(loginData.email);
                    }}
                  >
                    Click Here
                  </a>{" "}
                  to Reverify
                </span>
              );
            }
            if (message === "User not found") {
              return setLoginAuthError(
                <span>
                  {message}. <a href="/signup">Click Here</a> to Sign Up
                </span>
              );
            }
          }
        } catch (e) {
          return toast.error(e.message);
        }
      }
      const errorMessage =
        error?.response?.data?.detail || "Login failed! try again later";
      toast.error(errorMessage);
      setIsLoggedIn(false);
    }
  };

  const getUserData = async () => {
    try {
      const response = await getUserDataApi();
      if (response.status === 200) {
        setUserData(response.data);
        localStorage.setItem("userData", JSON.stringify(response.data));
      }
    } catch (error) {
      console.error("Failed to get user data:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const editUserInfo = async (updatedData) => {
    try {
      const response = await editUserInfoApi(updatedData);
      if (response.status === 200) {
        setUserData(response.data);
        localStorage.setItem("userData", JSON.stringify(response.data));
        showToast.success("Profile updated successfully");
      }
    } catch (error) {
      if (
        error.code === "ERR_NETWORK" ||
        error.code === "ERR_CONNECTION_REFUSED"
      ) {
        return navigate("/maintenance");
      }
      console.error("Failed to edit user info:", error);
    } finally {
      setShowLoader(false);
    }
  };

  const logOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("userData");
    localStorage.removeItem("userType");
    setUserData(null);
    setAxiosSession(null);
    setIsLoggedIn(false);
    setUserType(null);
  };

  const memoizedValue = useMemo(
    () => ({
      isInitialized,
      isLoggedIn,
      authError,
      loginAuthError,
      userData,
      adminData,
      userType,

      userRegister,
      userLogin,
      logOut,
      setAuthError,
      setLoginAuthError,
      setUserData,
      setAdminData,
      setUserType,
      editUserInfo,
    }),
    [
      isInitialized,
      isLoggedIn,
      authError,
      loginAuthError,
      userData,
      adminData,
      userType,
      setAuthError,
      setLoginAuthError,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };

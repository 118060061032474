import { useEffect, useState } from "react";
import SearchBar from "components/searchBar/searchBar";
import MuiCard from "components/muiCard/muiCard";
import CrudTable from "components/crudTable/page";
import { useAppDataContext } from "hooks/useappdatacontext";
import styles from "./styles.module.css";
import { useAuthContext } from "../../hooks/useauthcontext";

const Dashboard = () => {
  const { allEventsData, userCredits } = useAppDataContext();
  const { userData } = useAuthContext();

  const [searchQuery, setSearchQuery] = useState("");

  // state and effect to show loading view
  const [LoadingViewFor5, setLoadingViewFor5] = useState(true);

  useEffect(() => {
    let timer = setTimeout(() => {
      setLoadingViewFor5(false);
    }, 4500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const renderTopSection = () => {
    return (
      <SearchBar
        searchQuery={searchQuery}
        handleSearchChange={handleSearchChange}
      />
    );
  };

  const renderMiddleSection = () => {
    return (
      <div className={styles.cardmaincomp}>
        <MuiCard
          value={userCredits.credits}
          title="Remaining Event Credits"
          width="100%"
          height="100%"
          backgroundColor="#FF7519"
          color="#ffffff"
          style={{
            flexBasis: "30%",
            minWidth: "250px",
            height: "100%", // Ensure same height for all cards
            fontSize: "clamp(14px, 2.5vw, 18px)",
          }}
        />
        {/* <MuiCard
          value={userCredits.vid_credits}
          title="Remaining Recording Credits"
          width="100%"
          height="100%"
          backgroundColor="#ab8ece"
          color="#ffffffd9"
          style={{
            flexBasis: "30%",
            minWidth: "250px",
            height: "100%", // Ensure same height for all cards
            fontSize: "clamp(14px, 2.5vw, 18px)",
          }}
        /> */}
        <MuiCard
          value={userCredits.yt_credits}
          title="Remaining Youtube Credits"
          width="100%"
          height="100%"
          backgroundColor="#ab8ece"
          color="#ffffff"
          style={{
            flexBasis: "30%",
            minWidth: "250px",
            height: "100%", // Ensure same height for all cards
            fontSize: "clamp(14px, 2.5vw, 18px)",
          }}
        />
      </div>
    );
  };

  const renderBottomSection = () => {
    return <CrudTable searchQuery={searchQuery} />;
  };

  return (
    <>
      <div
        style={{
          display: `${LoadingViewFor5 === false ? "inline" : "none"}`,
        }}
        className={styles.mainContainerForPage}
      >
        <div className={styles.maincomp}>
          {renderTopSection()}

          {userData?.is_admin === false && renderMiddleSection()}
          {renderBottomSection()}
        </div>
      </div>
      <div
        style={{
          display: `${LoadingViewFor5 ? "flex" : "none"}`,
          opacity: "1",
          transition: "opacity 1s ease-in-out",
        }}
        className={styles.loaderContainer}
      >
        <div className={styles.loader}></div>
      </div>
    </>
  );
};

export default Dashboard;

import { useState, useEffect } from "react";
import { TextField, Typography, Alert, Button } from "@mui/material";
import CustomVideoPlayer from "../customVideoPlayer/customVideoPlayer";
import StreamModal from "../streamModal/streamModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./global.css";
import styles from "./global.module.css";
import { useAppDataContext } from "../../hooks/useappdatacontext";
import { useAuthContext } from "../../hooks/useauthcontext";

const UrlPreview = ({ setOptionData, title, edit, selectedRowId }) => {
  const { userData } = useAuthContext();
  const { setHlsVideoUrl, userCredits, setEventData, eventData } =
    useAppDataContext();
  // states
  const [option, setOption] = useState("");
  const [youtubeStreamedUrl, setYoutubeStreamedUrl] = useState("");
  const [youtubeChannelId, setYoutubeChannelId] = useState("");
  const [youtubeLiveUrl, setYoutubeLiveUrl] = useState("");
  const [file, setFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [modalOpen, setModalOpen] = useState(true);
  const [errors, setErrors] = useState({
    youtubeStreamedUrl: "",
    youtubeChannelId: "",
    file: "",
  });

  let btn;
  if (userCredits?.credits > 0 || userCredits?.yt_credits > 0) {
    btn = "cloud";
  } else {
    btn = "free";
  }

  const [activeBtn, setActiveBtn] = useState(btn);

  const handleOptionChange = (event) => {
    const selectedOption = event;
    setOption(selectedOption);
    if (selectedOption === "customStreamUrl") {
      setModalOpen(true);
    } else {
      resetForm();
    }
  };

  const resetForm = () => {
    setYoutubeChannelId("");
    setYoutubeStreamedUrl("");
    setFile(null);
    setFilePreview(null);
    setModalOpen(false);
    setErrors({
      youtubeStreamedUrl: "",
      youtubeChannelId: "",
      file: "",
    });
  };

  const handleYoutubeStreamedUrlChange = (event) => {
    const streamedurl = event.target.value;
    const videoId = extractYouTubeVideoId(streamedurl);
    if (!videoId) {
      setErrors((prev) => ({
        ...prev,
        youtubeStreamedUrl: "Invalid YouTube URL.",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        youtubeStreamedUrl: "",
      }));
    }
    setYoutubeStreamedUrl(streamedurl);
  };

  const handleYoutubeLiveIdChange = (event) => {
    const liveUrl = event.target.value;
    const liveId = extractYouTubeLiveId(liveUrl);
    setYoutubeLiveUrl(liveUrl);
    if (!liveId) {
      setErrors((prev) => ({
        ...prev,
        youtubeChannelId: "Invalid YouTube Live URL.",
      }));
    } else {
      setErrors((prev) => ({
        ...prev,
        youtubeChannelId: "",
      }));
    }
    setYoutubeChannelId(liveId);
  };

  const extractYouTubeLiveId = (url) => {
    const regExp = /youtube\.com\/live\/([^?&]+)/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (!selectedFile.type.startsWith("video/")) {
      setErrors((prev) => ({
        ...prev,
        file: "Please select a valid video file.",
      }));
      setFile(null);
      setFilePreview(null);
    } else {
      setErrors((prev) => ({
        ...prev,
        file: "",
      }));
      setOption("file");
      setFile(selectedFile);
      setFilePreview(URL.createObjectURL(selectedFile));
    }
  };

  const extractYouTubeVideoId = (url) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : null;
  };

  const videoId = extractYouTubeVideoId(youtubeStreamedUrl);

  const handleModalClose = () => setModalOpen(false);

  const handleSubmit = () => {
    // Post the selected file or URL to an API
    const postData = {
      option,
      youtubeStreamedUrl,
      // youtubeChannelId,
      youtubeLiveUrl,
      file,
    };
    setOptionData(postData);
    // Implement your API call here
  };

  useEffect(() => {
    setOption("");
  }, []);

  useEffect(() => {
    if (
      (option === "file" && file && !errors.file) ||
      (option === "youtubeStreamedUrl" &&
        youtubeStreamedUrl &&
        !errors.youtubeStreamedUrl) ||
      (option === "youtubeChannelId" &&
        youtubeChannelId &&
        !errors.youtubeChannelId)
    ) {
      handleSubmit();
    }
  }, [option, file, youtubeStreamedUrl, youtubeChannelId, errors]);
  useEffect(() => {
    if (option === "customStreamUrl" && (title === null || title === "")) {
      toast.error("Please Enter Event Title");
      setOption(""); // Reset option if no title
    }
  }, [option, title]); // Run this effect whenever option or title changes

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Typography variant="h6" className={styles.uploadLabelStyle}>
        Live Video Streaming Settings
      </Typography>
      <div
        style={{
          border: !edit && "1px dashed black",
          borderRadius: !edit && "6px",
          padding: "20px 0px",
        }}
      >
        <div style={{ margin: "10px 20px" }}>
          {userData && userData?.is_admin === false && (
            <div style={{ margin: "0px 0px 10px 0px" }}>
              <p>Event Credits: {userCredits?.credits}</p>
              <p>Youtube Credits: {userCredits?.yt_credits}</p>
            </div>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              gap: "10px",
            }}
          >
            <button
              disabled={
                userData && userData?.is_admin
                  ? false
                  : userCredits?.credits === 0
              }
              onClick={() => {
                setActiveBtn("cloud");
                setOption({});
                setYoutubeChannelId("");
                setYoutubeLiveUrl("");
                setFilePreview(null);
                setOptionData({});
                setHlsVideoUrl("");
                setEventData({ ...eventData, ads_disabled: true });
              }}
              className={styles.uploadLiveVideoBtn}
              style={{
                backgroundColor: activeBtn === "cloud" ? "#4CAF50" : "#f0f0f0",
                color: activeBtn === "cloud" ? "#fff" : "#000",
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#45a049")}
              onMouseOut={(e) =>
                (e.target.style.backgroundColor =
                  activeBtn === "cloud" ? "#4CAF50" : "#f0f0f0")
              }
            >
              Cloud Server
            </button>

            <button
              disabled={
                userData && userData?.is_admin
                  ? false
                  : userCredits?.yt_credits === 0
              }
              onClick={() => {
                setActiveBtn("youTube");
                setOption({});
                setHlsVideoUrl("");
                setFile(null);
                setFilePreview(null);
                setOptionData({});
                setEventData({ ...eventData, ads_disabled: true });
              }}
              className={styles.uploadLiveVideoBtn}
              style={{
                backgroundColor:
                  activeBtn === "youTube" ? "#4CAF50" : "#f0f0f0",
                color: activeBtn === "youTube" ? "#fff" : "#000",
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#45a049")}
              onMouseOut={(e) =>
                (e.target.style.backgroundColor =
                  activeBtn === "youTube" ? "#4CAF50" : "#f0f0f0")
              }
            >
              YouTube
            </button>

            <button
              onClick={() => {
                setActiveBtn("free");
                setOption({});
                setHlsVideoUrl("");
                setFile(null);
                setFilePreview(null);
                setOptionData({});
                setEventData({ ...eventData, ads_disabled: false });
              }}
              className={styles.uploadLiveVideoBtn}
              style={{
                backgroundColor: activeBtn === "free" ? "#4CAF50" : "#f0f0f0",
                color: activeBtn === "free" ? "#fff" : "#000",
              }}
              onMouseOver={(e) => (e.target.style.backgroundColor = "#45a049")}
              onMouseOut={(e) =>
                (e.target.style.backgroundColor =
                  activeBtn === "free" ? "#4CAF50" : "#f0f0f0")
              }
            >
              Youtube With Ads
            </button>

            {/* <button
          disabled={userCredits?.yt_credits === 0}
          onClick={() => {
            setActiveBtn("youTube");
            setOption("");
          }}
          className={styles.uploadLiveVideoBtn}
          style={{
            backgroundColor: activeBtn === "youTube" ? "#4CAF50" : "#f0f0f0",
            color: activeBtn === "youTube" ? "#fff" : "#000",
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = "#45a049")}
          onMouseOut={(e) =>
            (e.target.style.backgroundColor =
              activeBtn === "youTube" ? "#4CAF50" : "#f0f0f0")
          }
        >
          YOUTUBE
        </button> */}
          </div>
        </div>
        {activeBtn === "cloud" ? (
          <div
            style={{
              display: "flex",
              gap: "10px",
              alignItem: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Button
              variant="contained"
              component="label"
              color="primary"
              margin="normal"

              // startIcon={<LuFileVideo />}
            >
              Upload
              <input
                type="file"
                accept="video/*"
                hidden
                onChange={(e) => {
                  handleFileChange(e);
                  setYoutubeChannelId("");
                  setYoutubeStreamedUrl("");
                  setHlsVideoUrl("");
                }}
              />
            </Button>
            <Button
              variant="contained"
              component="label"
              color="primary"
              margin="normal"
              onClick={() => {
                handleOptionChange("HLS_Url");
                setYoutubeChannelId("");
                setYoutubeStreamedUrl("");
                setFile(null);
                setOptionData({});
                setFilePreview();
              }}
              // startIcon={<LuFileVideo />}
            >
              HLS Video
            </Button>

            <Button
              variant="contained"
              component="label"
              color="primary"
              margin="normal"
              onClick={() => {
                handleOptionChange("customStreamUrl");
                setFile(null);
                setHlsVideoUrl("");
                setOptionData({});
                setFilePreview(null);
              }}
              // startIcon={<LuFileVideo />}
            >
              RTMP/SRT
            </Button>
          </div>
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItem: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                variant="contained"
                component="label"
                color="primary"
                margin="normal"
                onClick={() => {
                  handleOptionChange("youtubeChannelId");
                  setYoutubeStreamedUrl("");
                  setFile(null);
                  setOptionData({});
                  setHlsVideoUrl("");
                }}
                // startIcon={<LuFileVideo />}
              >
                Live
              </Button>
              <Button
                variant="contained"
                component="label"
                color="primary"
                margin="normal"
                onClick={() => {
                  setYoutubeChannelId("");
                  setFile(null);
                  handleOptionChange("youtubeStreamedUrl");
                  setOptionData({});
                  setHlsVideoUrl("");
                }}

                // startIcon={<LuFileVideo />}
              >
                Recorded
              </Button>
            </div>
            {activeBtn === "free" && (
              <p
                style={{
                  fontSize: "16px",
                  color: "#444",
                  fontFamily: '"Arial", sans-serif',
                  margin: "15px 0",
                  lineHeight: "1.5",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "red" }}>Note: </span>By using this free
                YouTube option, the event page will display Google
                advertisements.
              </p>
            )}
          </div>
        )}
      </div>
      <div>
        {errors.file && <Alert severity="error">{errors.file}</Alert>}
        {file && (
          <div>
            <Typography variant="subtitle1">File Preview:</Typography>
            <video
              src={filePreview}
              controls
              width="100%"
              style={{ maxWidth: "600px" }}
            ></video>
          </div>
        )}
      </div>

      {/* {option === "file" && (
        <div>
          <Button
            variant="contained"
            component="label"
            color="primary"
            margin="normal"
            startIcon={<LuFileVideo />}
          >
            Select File
            <input
              type="file"
              accept="video/*"
              hidden
              onChange={handleFileChange}
            />
          </Button>
          {errors.file && <Alert severity="error">{errors.file}</Alert>}
          {file && (
            <div>
              <Typography variant="subtitle1">File Preview:</Typography>
              <video
                src={filePreview}
                controls
                width="100%"
                style={{ maxWidth: "600px" }}
              ></video>
            </div>
          )}
        </div>
      )} */}

      {option === "youtubeStreamedUrl" && (
        <div>
          <TextField
            label="Enter youtube video URL"
            value={youtubeStreamedUrl}
            onChange={handleYoutubeStreamedUrlChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.youtubeStreamedUrl}
            helperText={errors.youtubeStreamedUrl}
          />
          {youtubeStreamedUrl && !errors.youtubeStreamedUrl && (
            <div>
              <Typography variant="subtitle1">Preview:</Typography>
              <iframe
                title={`${videoId}`}
                width="100%"
                height="315"
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      )}

      {option === "youtubeChannelId" && (
        <div>
          <TextField
            label="Enter Youtube live video URL"
            value={youtubeChannelId}
            onChange={handleYoutubeLiveIdChange}
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!errors.youtubeChannelId}
            helperText={errors.youtubeChannelId}
          />
          {youtubeChannelId && !errors.youtubeChannelId && (
            <div>
              <Typography variant="subtitle1">Preview:</Typography>
              <iframe
                title={`${youtubeChannelId}`}
                width="100%"
                height="315"
                src={`https://www.youtube.com/embed/${youtubeChannelId}?autoplay=1&mute=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          )}
        </div>
      )}

      {option === "HLS_Url" && <CustomVideoPlayer edit={edit} />}
      {option === "customStreamUrl" && title && title !== "" && (
        <StreamModal
          edit={edit}
          selectedRowId={selectedRowId}
          open={modalOpen}
          handleClose={handleModalClose}
          title={title}
        />
      )}
    </div>
  );
};

export default UrlPreview;

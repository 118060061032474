import { useRazorpay } from "react-razorpay";
import { motion } from "framer-motion";
import { MdCancel } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { useEffect, useState, useRef } from "react";
import axiosInstance from "utils/axios";
import { useAppDataContext } from "hooks/useappdatacontext";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.css";
import { Dialog, DialogTitle } from "@mui/material";
import {
  ArrowLeft,
  ArrowRight,
  CheckCheck,
  CheckCheckIcon,
  CopyIcon,
  X,
} from "lucide-react";
import Slider from "react-slick";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const Payments = () => {
  const [isCopied, setIsCopied] = useState(false);
  const {
    pricingData,
    getPricingData,
    getUserCredits,
    getUPIPaymentDetails,
    upiDetails,
  } = useAppDataContext();
  // states
  const [paymentFailed, setPaymentFailed] = useState(false);
  const paymentFailedRef = useRef(paymentFailed);
  const { Razorpay } = useRazorpay();
  const [loading, setLoading] = useState(false);
  const [selectedPlanDetials, setSelectedPlanDetails] = useState(null);
  const [paymentConfirmationWindow, setpaymentConfirmationWindow] =
    useState(false);

  const [openQRCodeWithInstructions, setOpenQRCodeWithInstructions] =
    useState(false);
  const [isUpiCopied, setIsUpiCopied] = useState(false);

  const handleCopy = (text, type) => {
    navigator.clipboard.writeText(text).then(() => {
      if (type === "number") {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
      }
      if (type === "upi") {
        setIsUpiCopied(true);
        setTimeout(() => setIsUpiCopied(false), 2000);
      }
    });
  };
  // const token = process.env.NEXT_PUBLIC_API_TOKEN;
  const { REACT_APP_BASE_URL } = process.env;
  const api = `${REACT_APP_BASE_URL}/pay/make_payment/`;
  const verifyapi = `${REACT_APP_BASE_URL}/pay/verify_payment/`;

  useEffect(() => {
    if (upiDetails === null) {
      getUPIPaymentDetails();
    }
  }, []);

  // Keep the ref updated with the current state
  useEffect(() => {
    paymentFailedRef.current = paymentFailed;
  }, [paymentFailed]);

  // functions

  const makePaymentPop = (plan) => {
    setpaymentConfirmationWindow(true);
    setLoading(true);

    // Calculate the final price based on Sale Price or Actual Price
    let final_price =
      plan.Sale_price === 0 ? plan.actual_price : plan.Sale_price;

    // Calculate GST based on the final price and GST percentage
    const gst_rate = (
      (final_price * Number(plan.Gst_percentage)) /
      100
    ).toFixed(2);

    // Update selected plan details with necessary info
    setSelectedPlanDetails({
      ...selectedPlanDetials,
      id: plan.id,
      name: plan.name,
      Sale_price: final_price,
      Gst_percentage: plan.Gst_percentage,
      gst_margin: gst_rate,
      service_charge: plan.service_charge,
      final_amount_pay: plan.finalpricewithcharges,
    });
  };

  const makePayment = async (plan) => {
    // setpaymentConfirmationWindow(false);
    try {
      const response = await axiosInstance.post(api, {
        price_id: selectedPlanDetials?.id,
        product_name: selectedPlanDetials?.name,
        currency: "INR",
      });

      if (response?.data) {
        const payId = selectedPlanDetials?.id;
        const { order_id, amount, currency, entity } = response.data;
        handlePayment({ order_id, amount, currency, entity, payId });
      } else {
        setLoading(false);
        toast.error("Payment failed");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error making payment");
    } finally {
      setLoading(false);
    }
  };

  const handlePayment = async ({
    order_id,
    amount,
    currency,
    entity,
    payId,
  }) => {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert(
        "Failure loading the Razorpay SDK. Please make sure you are connected to the internet"
      );
      return;
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY,
      amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: currency,
      name: "Infinitum Tech",
      description: entity,
      order_id: order_id,
      handler: async (response) => {
        try {
          await axiosInstance.post(verifyapi, {
            pricing_id: payId,
            razorpay_paymentId: response.razorpay_payment_id,
            razorpay_orderId: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          });
          getUserCredits();
          toast.success("Payment Successful");
          setpaymentConfirmationWindow(!paymentConfirmationWindow);
          setLoading(false);
        } catch (err) {
          toast.error("Error verifying payment");
          console.error("Verification error:", err);
        }
      },
      theme: {
        color: "#3399cc",
      },
      modal: {
        ondismiss: async () => {
          // Handle the case when the payment is closed without completing it
          if (paymentFailedRef.current) return; // Use ref for accurate check
          try {
            if (!paymentFailedRef.current) {
              await axiosInstance.post(verifyapi, {
                pricing_id: payId,
                razorpay_orderId: order_id,
                status: "cancelled",
              });
              toast.warning("Payment cancelled");
            }
          } catch (err) {
            console.error("Cancellation error:", err);
          }
        },
      },
    };

    const paymentObject = new window.Razorpay(options);

    paymentObject.on("payment.failed", async (response) => {
      setPaymentFailed(true); // Update state immediately
      try {
        await axiosInstance.post(verifyapi, {
          pricing_id: payId,
          razorpay_orderId: order_id,
          status: "failed",
          error: response.error,
        });
        toast.error("Payment failed");
      } catch (err) {
        console.error("Failed payment error:", err);
      }
    });

    paymentObject.open();
  };

  useEffect(() => {
    const fetchPricingData = async () => {
      await getPricingData();
    };
    fetchPricingData();
  }, []);

  //function to remove duplicate words from youtube pack name
  const capitalizeWords = (str1, str2) => {
    if (
      str1.toLowerCase().includes("pack") &&
      str2.toLowerCase().includes("pack")
    ) {
    }
    if (str1.toLowerCase() === str2.toLowerCase()) {
      return str1.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    } else {
      let word = str1 + " " + str2;
      let splittedWords = word.toLowerCase().split(" ");
      let uniqueWords = [];

      splittedWords.forEach((word) => {
        if (!uniqueWords.includes(word)) {
          uniqueWords.push(word);
        }
      });
      let r = uniqueWords
        .join(" ")
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase());
      console.log(r);

      return r;
    }
  };
  const filteredState = {};

  // Ensure pricingData and its properties exist
  const filters = pricingData?.filters || [];
  const pricing = pricingData?.pricing || [];

  // Iterate through filters and populate the state
  filters.forEach(([key, displayName]) => {
    // Filter the pricing data based on the pack
    filteredState[key] = pricing.filter((plan) => plan.pack === key);
  });

  // Log the state to verify the data
  const [openIndex, setOpenIndex] = useState(-1);

  const handleToggle = (index) => {
    setOpenIndex(index === openIndex ? 0 : index);
  };

  const settings = {
    infinite: true, // Enables infinite scrolling
    slidesToShow: 1, // Show one slide at a time
    slidesToScroll: 1, // Scroll one slide at a time
    arrows: true, // Display arrows
    dots: true, // Show navigation dots
    autoplay: false, // Set to true if you want autoplay
    autoplaySpeed: 3000, // Adjust the speed of autoplay
  };

  const sliderRef = useRef(null);
  const handlePrev = () => {
    setIsCopied(false);
    setIsUpiCopied(false);
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    setIsCopied(false);
    setIsUpiCopied(false);
    sliderRef.current.slickNext();
  };

  return (
    <div style={{ minWidth: "400px" }}>
      <>
        <div
          style={{
            opacity: `${loading ? "0.25" : "1"}`,
            marginTop: "0px",
            paddingTop: "100px",
          }}
        >
          <div className={styles.buttonContainer}>
            {Object.keys(filteredState).length > 0 && (
              <>
                {/* All Packs Button */}
                <button
                  className={`${styles.navButton} ${
                    openIndex === -1 ? styles.active : ""
                  }`}
                  onClick={() => handleToggle(-1)}
                >
                  All Packs
                </button>
                {Object.keys(filteredState).map(
                  (key, index) =>
                    filteredState[key].length > 0 && ( // Ensure the filter has at least one pack
                      <button
                        key={index}
                        className={`${styles.navButton} ${
                          openIndex === index ? styles.active : ""
                        }`}
                        onClick={() => handleToggle(index)}
                      >
                        {filteredState[key].displayName || key}
                      </button>
                    )
                )}
              </>
            )}
          </div>

          {/* All Packs Display */}
          <motion.div
            style={{
              width: "95vw",
              display: openIndex === -1 ? "flex" : "none",
              flexWrap: "wrap",
              justifyContent: "space-around",
              margin: "auto",
              padding: "10px",
              overflowX: "auto",
              gap: "15px",
            }}
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 100 }}
            transition={{ duration: 0.5 }}
            className="NavigationMenuContent"
          >
            {pricing.map((plan, index) => (
              <>
                {plan.actual_price === 0 && plan.Sale_price === 0 ? null : (
                  <div key={index} className={styles.pricingCard}>
                    <p className={styles.pricingCardTitle}>{plan.name}</p>
                    <p
                      style={{
                        textDecoration:
                          plan.actual_price < plan.Sale_price
                            ? "none"
                            : plan.Sale_price === 0
                            ? "none"
                            : "line-through",
                      }}
                      className={styles.actualPrice}
                    >
                      {plan.actual_price === 0 ||
                      plan.actual_price === plan.Sale_price ? null : (
                        <>₹{plan.actual_price}</>
                      )}
                    </p>
                    <div className={styles.salePrice}>
                      {plan.Sale_price === 0 ? null : <>₹{plan.Sale_price}</>}
                    </div>
                    <div className={styles.featuresBlock}>
                      {/* Map the features dynamically */}
                      {["num_Events", "num_yt_coins"].map(
                        (feature, idx) =>
                          feature ? ( // Check that feature is not null
                            <div key={idx} className={styles.feature}>
                              {plan[feature] > 0 && (
                                <>
                                  <FaCheckCircle color="green" />
                                  <p className={styles.ProductSpecStyle}>
                                    {`${plan[feature]} ${feature
                                      .replace("num_", "")
                                      .replace("_", " ")
                                      .toUpperCase()} credits`}
                                  </p>
                                </>
                              )}
                            </div>
                          ) : null // If feature is null, render nothing
                      )}
                    </div>
                    <button
                      className={styles.payBtn}
                      onClick={() => makePaymentPop(plan)}
                    >
                      Get Started
                    </button>
                  </div>
                )}
              </>
            ))}
          </motion.div>

          {/* Dynamic Display for Each Filtered State */}
          {Object.keys(filteredState).map((key, index) => (
            <motion.div
              key={index}
              style={{
                width: "95vw",
                display: openIndex === index ? "flex" : "none",
                flexWrap: "wrap",
                justifyContent: "space-around",
                margin: "auto",
                padding: "10px",
                overflowX: "auto",
                gap: "15px",
              }}
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{ duration: 0.5 }}
              className="NavigationMenuContent"
            >
              {filteredState[key].map((plan, idx) => (
                <>
                  {plan.actual_price === 0 && plan.Sale_price === 0 ? null : (
                    <div key={idx} className={styles.pricingCard}>
                      <p className={styles.pricingCardTitle}>{plan.name}</p>
                      <p
                        style={{
                          textDecoration:
                            plan.actual_price < plan.Sale_price
                              ? "none"
                              : plan.Sale_price === 0
                              ? "none"
                              : "line-through",
                        }}
                        className={styles.actualPrice}
                      >
                        {plan.actual_price === 0 ||
                        plan.actual_price === plan.Sale_price ? null : (
                          <>₹{plan.actual_price}</>
                        )}
                      </p>
                      <div className={styles.salePrice}>
                        {plan.Sale_price === 0 ? null : <>₹{plan.Sale_price}</>}
                      </div>
                      <div className={styles.featuresBlock}>
                        {/* Map the features dynamically */}
                        {["num_Events", "num_yt_coins"].map((feature, idx) => (
                          <div key={idx} className={styles.feature}>
                            {plan[feature] > 0 && (
                              <>
                                <FaCheckCircle color="green" />
                                <p className={styles.ProductSpecStyle}>
                                  {`${plan[feature]} ${feature
                                    .replace("num_", "")
                                    .replace("_", " ")
                                    .toUpperCase()} credits`}
                                </p>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                      <button
                        className={styles.payBtn}
                        onClick={() => makePaymentPop(plan)}
                      >
                        Get Started
                      </button>
                    </div>
                  )}
                </>
              ))}
            </motion.div>
          ))}
        </div>
      </>
      {openQRCodeWithInstructions && (
        <div style={{ minWidth: "350px" }} className={styles.overlay}>
          <div className={styles.modal}>
            <button
              className={styles.closeButton}
              onClick={() => {
                setOpenQRCodeWithInstructions(false);
              }}
            >
              <X />
            </button>
            <div className={styles.carouselContainer}>
              <button className={styles.prevButton} onClick={handlePrev}>
                <ArrowLeft />
              </button>
              <button className={styles.nextButton} onClick={handleNext}>
                <ArrowRight />
              </button>

              <Slider {...settings} ref={sliderRef}>
                {upiDetails &&
                Array.isArray(upiDetails) &&
                upiDetails.length > 0 ? (
                  upiDetails.map((each, index) => (
                    <div key={index}>
                      <div>
                        <img
                          src={each.qr_code}
                          alt={`qr${index}`}
                          className={styles.qrImage}
                        />
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <strong className={styles.phoneNumber}>
                            {each.phone_number}
                          </strong>
                          {isCopied ? (
                            <CheckCheckIcon />
                          ) : (
                            <CopyIcon
                              onClick={() => {
                                handleCopy(each.phone_number, "number");
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <strong className={styles.phoneNumber}>
                            {each.upi_pay_id}
                          </strong>

                          {isUpiCopied ? (
                            <CheckCheck />
                          ) : (
                            <CopyIcon
                              onClick={() => {
                                handleCopy(each.upi_pay_id, "upi");
                              }}
                              style={{ cursor: "pointer" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>No QR codes available</div>
                )}
              </Slider>
            </div>
            <p className={styles.instructionText}>
              <span style={{ color: "red", fontWeight: "700" }}>Note:</span>{" "}
              After successful payment, send a screenshot to the above WhatsApp
              number and verify the beneficiary's UPI ID.
            </p>
          </div>
        </div>
      )}

      <Dialog
        sx={{
          minWidth: "350px",
          maxWidth: "500px",
          margin: "auto",
          padding: "20px",
        }}
        fullWidth
        open={paymentConfirmationWindow}
        onClose={() => {
          setSelectedPlanDetails(null);
        }}
      >
        <div
          style={{
            boxShadow: "0 10px 15px rgba(0, 0, 0, 0.3)",
          }}
        >
          <button
            style={{
              color: "black",
              fontSize: "16px",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              setpaymentConfirmationWindow(false);
              setLoading(false);
            }}
          >
            X
          </button>

          <DialogTitle className={styles.dialogTitle}>Payment Info</DialogTitle>

          <div className={styles.dialogContent}>
            <p style={{ marginBottom: "18px" }} className={styles.priceItem}>
              Price : <span>{selectedPlanDetials?.Sale_price}</span>
            </p>
            <p className={styles.priceItem} style={{ marginBottom: "18px" }}>
              GST charges @ {selectedPlanDetials?.Gst_percentage}%:
              <span>+{selectedPlanDetials?.gst_margin}</span>
            </p>
            <p className={styles.priceItem}>
              Service Charge
              <span>+{selectedPlanDetials?.service_charge}</span>
            </p>
            <hr />
            <p
              style={{ display: "flex", justifyContent: "space-around" }}
              className={(styles.priceItem, styles.totalPrice)}
            >
              Total Price: <span>{selectedPlanDetials?.final_amount_pay}</span>
            </p>
          </div>
          <div className={styles.dialogFooter}>
            <button
              className={styles.paymentBtn}
              onClick={() => {
                makePayment(selectedPlanDetials);
                setpaymentConfirmationWindow(false);
              }}
            >
              Make Payment
            </button>
            <div style={{ position: "relative" }}>
              <p className={styles.footer} style={{ marginBlock: "20px" }}>
                Make Payment via UPI?{" "}
                <span
                  onClick={() => {
                    setOpenQRCodeWithInstructions(!openQRCodeWithInstructions);
                  }}
                  style={{
                    color: "blue",
                    cursor: "pointer",
                  }}
                >
                  Click Here
                </span>
              </p>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Payments;

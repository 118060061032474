import React, { useEffect } from 'react';

const GoogleAdsense = () => {

    const adClient = process.env.REACT_APP_AD_CLIENT;
    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            const script = document.createElement('script');
            script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${adClient}`;
            script.async = true;
            script.crossOrigin = 'anonymous';
            document.body.appendChild(script);

            // Push the ad after the script is loaded
            script.onload = () => {
                if (window.adsbygoogle) {
                    // Avoid pushing ads multiple times
                    const ads = document.querySelectorAll('.adsbygoogle');
                    ads.forEach((ad) => {
                        if (!ad.hasAttribute('data-ad-client')) {
                            ad.setAttribute('data-ad-client', adClient);
                            window.adsbygoogle.push({});
                        }
                    });
                }
            };

            return () => {
                document.body.removeChild(script); // Cleanup script when component unmounts
            };
        }
    }, [adClient]);

    const isMobile = window.innerWidth <= 768;

    return (
        <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0',backgroundColor: 'green' }}>
            <h1>Google AdSense </h1>
            {/* For left-right ads (desktop) */}
            {!isMobile && process.env.NODE_ENV === 'production' && (
                <>
                <h1>vertical</h1>
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client={adClient}
                    data-ad-slot="2920029613"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                ></ins>
                </>
            )}
            <h1> NODE ENV {process.env.NODE_ENV}</h1>
            {/* For top/bottom ads (mobile) */}
            {isMobile && process.env.NODE_ENV === 'production' && (
               <>
                <h1>horizontal</h1>
                <ins
                    className="adsbygoogle"
                    style={{ display: 'block' }}
                    data-ad-client={adClient}
                    data-ad-slot="2783168946"
                    data-ad-format="auto"
                    data-full-width-responsive="true"
                ></ins>
               </>
            )}
        </div>
    );
};

export default GoogleAdsense;
import HomeHeader from "../../../components/homeHeader/homeHeader";
import { AnimatePresence } from "framer-motion";
import Terms from "./terms";
import styles from "./styles.module.css";
import PrivacyPolicy from "./refund";

const TermsAndConditions = ({ val }) => {  // Destructure the prop
  return (
    <AnimatePresence>
      <div
        style={{
          minWidth: "370px",
          position: "relative",
        }}
      >
        <HomeHeader />
        <div className={styles.pageContainer}>
          {val === "Policy" ? (
            <div
              style={{
                paddingTop: "24px",
                paddingLeft: "5px",
                paddingRight: "5px",
              }}
              id="privacy policies"
            >
              <PrivacyPolicy />
            </div>
          ) : (
            <div
              style={{
                paddingTop: "24px",
                paddingRight: "5px",
              }}
              id="terms of service"
            >
              <h1
                style={{
                  fontWeight: "900",
                  fontSize: "1.5rem",
                  paddingRight: "1.25rem",
                  display: "inline",
                }}
              >
                Terms of Service
              </h1>
              <Terms />
            </div>
          )}
        </div>
      </div>
    </AnimatePresence>
  );
};

export default TermsAndConditions;

import styles from "./styles.module.css";

const PrivacyPolicy = () => {
  return (
    <div
      style={{
        paddingRight: "1.25rem",
      }}
    >
      <h1
        style={{
          color: "black",
          fontWeight: "800",
          fontSize: "1.5rem",
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      >
        Privacy Policy
      </h1>
      <p className={styles.contentDiscription} style={{ marginBottom: "20px" }}>
        Welcome to the website{" "}
        <a
          style={{ color: "#3b82f6" }}
          href="https://www.eventsonlife.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.eventsonlife.com
        </a>
        , and any associated applications or services operated by Events On Life
        Private Limited, based in Hyderabad, Telangana, India ("Events On
        Life").
      </p>

      <p className={styles.contentDiscription} style={{ marginBottom: "20px" }}>
        Events On Life values your privacy and adheres to applicable data
        protection regulations, including the Information Technology Act, 2000,
        and other Indian privacy regulations.
      </p>

      <h2 style={{ marginBottom: "16px" }}>
        Information We Collect
      </h2>
      <p className={styles.contentDiscription} style={{ marginBottom: "20px" }}>
        <strong>User-Provided Information:</strong> You may provide us with
        personal information such as your name, email, phone number, address,
        payment details, and more when using our services or participating in
        activities like account registration or purchases.
      </p>
      <p className={styles.contentDiscription} style={{ marginBottom: "20px" }}>
        <strong>Automatically Collected Information:</strong> We collect
        information such as your IP address, device type, browser version,
        session data, and cookies to enhance functionality and user experience.
      </p>
      <p className={styles.contentDiscription} style={{ marginBottom: "20px" }}>
        <strong>Information from Third Parties:</strong> If you use social media
        platforms or third-party integrations, we may collect additional
        information in accordance with this Privacy Policy.
      </p>

      <h2 style={{ marginBottom: "16px" }}>
        How We Use Your Information
      </h2>
      <p className={styles.contentDiscription} style={{ marginBottom: "20px" }}>
        We use your data to provide personalized services, improve user
        experiences, comply with legal obligations, and communicate relevant
        updates. Data may also be used for marketing with your explicit consent.
      </p>

      <h2 style={{ marginBottom: "16px" }}>
        Data Sharing and Disclosure
      </h2>
      <p className={styles.contentDiscription} style={{ marginBottom: "20px" }}>
        Events On Life does not sell your data. We may share information with
        trusted partners, service providers, or as required by law.
      </p>

      <h2 style={{ marginBottom: "16px" }}>Your Rights</h2>
      <p className={styles.contentDiscription} style={{ marginBottom: "20px" }}>
        As per Indian regulations, you have the right to access, modify, or
        delete your personal data. You can also withdraw your consent to data
        processing at any time.
      </p>

      <h2 style={{ marginBottom: "16px" }}>Data Security</h2>
      <p className={styles.contentDiscription} style={{ marginBottom: "20px" }}>
        We implement reasonable security measures to protect your data.
        However, no method of transmission or storage is completely secure, and
        users are advised to take appropriate precautions.
      </p>

      <h2 style={{ marginBottom: "16px" }}>Grievance Redressal</h2>
      <p className={styles.contentDiscription} style={{ marginBottom: "20px" }}>
        If you have concerns or complaints about our privacy practices, please
        contact our Grievance Officer at{" "}
        <a href="mailto:support@eventsonlife.com">support@eventsonlife.com</a>.
      </p>

      <h2 style={{ marginBottom: "16px" }}>Cookies and Tracking</h2>
      <p className={styles.contentDiscription} style={{ marginBottom: "20px" }}>
        Cookies are used to enhance user experiences and for analytics. You can
        manage your cookie preferences through your browser settings.
      </p>

      <h2 style={{ marginBottom: "16px" }}>Delete Account Data</h2>
      <p className={styles.contentDiscription} style={{ marginBottom: "20px" }}>
        To request account deletion and data removal, contact us at{" "}
        <a href="mailto:support@eventsonlife.com">support@eventsonlife.com</a>.
        This action is irreversible.
      </p>
    </div>
  );
};

export default PrivacyPolicy;


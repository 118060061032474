import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./routes";
import { AuthProvider } from "providers/authprovider";
import { AppDataProvider } from "providers/appdataprovider";
import { ToastProvider } from "providers/toastprovider";
import { useEffect } from "react";
import LinkPreview from "./components/LinkPreview";

function App() {
  useEffect(() => {
    document.documentElement.style.scrollBehavior = "smooth";
  }, []);

  return (
    <BrowserRouter>
      <ToastProvider>
        <AppDataProvider>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </AppDataProvider>
      </ToastProvider>
    </BrowserRouter>
  );
}

export default App;

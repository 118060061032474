import React from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Video, Users, Share2 } from "lucide-react";
import "./homePage.css";

const Feature = ({ icon, title, description }) => (
  <div className="feature-item">
    <div className="feature-icon">{icon}</div>
    <h3 className="feature-title">{title}</h3>
    <p className="feature-description">{description}</p>
  </div>
);

const Hero = () => {
  const navigate = useNavigate();
  return (
    <div id="home" className="page-container">
      <div className="page-content">
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="page-heading"
        >
          <h5>Connect, Stream, and Share</h5>
          <h5>Your Events on</h5>
          <span className="typing-effect">Events on Life</span>
        </motion.h1>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="page-paragraph"
        >
          Create unforgettable moments with our all-in-one platform for live
          streaming, event sharing, and collaborative experiences.
        </motion.p>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="page-buttons"
        >
          <motion.button
            whileHover={{ scale: 1.05 }}
            className="white-label-solutions"
          >
            <a
              style={{ textDecoration: "none" }}
              className="white-label-solutions"
              href="#white-label-solution"
            >
              White Label Solution
            </a>
          </motion.button>
          <motion.button
            whileHover={{ scale: 1.05 }}
            className="get-started"
            onClick={() => navigate("/login")}
          >
            Get Started
          </motion.button>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="feature-grid"
        >
          <Feature
            icon={<Video className="w-8 h-8" style={{ margin: "auto" }} />}
            title="Live Streaming"
            description="Broadcast your events in real-time with HD quality"
          />
          <Feature
            icon={<Users className="w-8 h-8" style={{ margin: "auto" }} />}
            title="Easy Invitations"
            description="Invite and manage guests with just a few clicks"
          />
          <Feature
            icon={<Share2 className="w-8 h-8" style={{ margin: "auto" }} />}
            title="Media Sharing"
            description="Share photos and videos securely with attendees"
          />
        </motion.div>
      </div>

      {/* Background decorative elements */}
      <div className="background-decor">
        <div className="blob-one"></div>
        <div className="blob-two"></div>
      </div>
    </div>
  );
};

export default Hero;

import { motion, useInView } from "framer-motion";
import styles from "./styles.module.css";

const contents = [
  { condition: 1, term: "Accounts" },
  { condition: 2, term: "The Services" },
  { condition: 3, term: "Modifications to These Terms of Service" },
  { condition: 4, term: "Term, Termination, and Suspension" },
  // { condition: 5, term: "Events On Life Properties; Events On Life Content" },
  { condition: 5, term: "License Grant; Retained Rights" },
  { condition: 6, term: "Acceptable Use Policy" },
  { condition: 7, term: "Downtime and Service Suspensions; Security" },
  { condition: 8, term: "Fees" },
  { condition: 9, term: "Confidentiality" },
  { condition: 10, term: "Intellectual Property" },
  {
    condition: 11,
    term: "Representations and Warranties; Disclaimers; Limitations of Liability",
  },
  { condition: 12, term: "Indemnification" },
  { condition: 13, term: "Notices" },
  { condition: 14, term: "Miscellaneous Provisions" },
];

const terms = [
  {
    section: "1. ACCOUNTS",
    subsections: [
      {
        title: "1.1 Registration",
        description:
          'To access the Site or certain resources, you may be required to create an account ("Account") by providing registration details or other information. Unless otherwise specified in the applicable Service Description (as defined in Section 2), you may only create one Account per email address. Your access and use of the Site are conditional upon providing accurate, current, and complete information. You agree that all information provided by you is governed by our Privacy Policy and consent to all actions we take concerning your information consistent with our Privacy Policy.',
      },
      {
        title: "1.2 Eligibility",
        description:
          "You must be at least 18 years old or the age of majority in your jurisdiction, whichever is greater, to create an Account or use our Services. Minors under the applicable age may use our Services only under the supervision of a parent or legal guardian who is at least 18 years old. In such cases, the parent or legal guardian is deemed the user and is responsible for all activities under the Account.",
      },
      {
        title: "1.3 Individual Accounts",
        description:
          "If you register as an individual, you warrant that (a) your use of the Services is limited to streaming content that you own or have created and not on behalf of an organization or entity, and (b) the registration information provided is accurate and complete throughout the Term.",
      },
      {
        title: "1.4 Entity Accounts",
        description:
          'If you register as an entity, you may stream content on behalf of others or as a service. For instance, if you are a company live-streaming its content or an agency streaming content for clients, you must register for a "for companies" or "agency" plan. By registering as an entity, you warrant that: (a) all registration information is accurate and complete; (b) you are authorized to operate in the relevant jurisdictions; (c) the individual completing the registration meets the requirements of Section 1.2 and is authorized to bind the entity to this Agreement; and (d) your representatives accessing the Services are authorized to bind the entity legally.',
      },
      {
        title: "1.5 Activities Under Your Account",
        description:
          "You are fully responsible for all activities under your Account, whether undertaken by you or third parties. Notify us immediately if you suspect unauthorized use of your Account or if your credentials are lost or stolen. We are not liable for unauthorized access to, or alteration, deletion, or loss of, any content or data associated with your Account.",
      },
    ],
  },
  {
    section: "2. THE SERVICES",
    description:
      'The services provided by Events On Life and its affiliates (collectively "we" or "us") include both Free Services and Paid Services. Free Services are available at no charge, while Paid Services require a fee. We reserve the right to transition a Free Service to a Paid Service or vice versa without prior notice. Detailed descriptions of these services ("Service Descriptions") are available at https://eventsonlife.com/pricing.',
  },
  {
    section: "3. MODIFICATIONS TO THESE TERMS OF SERVICE",
    description:
      "We may update these Terms of Service or related policies at any time by posting the revised version on the Site. For Paid Services, the changes take effect upon posting unless stated otherwise. For Free Services, changes take effect 13 days after posting or upon your acceptance via any provided mechanism. Continued use of the Site or Services after the effective date signifies your acceptance of the revised terms.",
  },
  {
    section: "4. TERM, TERMINATION, AND SUSPENSION",
    subsections: [
      {
        title: "4.1 Term",
        description:
          "This Agreement commences when you accept these Terms of Service by registering for an Account or using the Services. It remains in effect until terminated by either party as outlined in this Section.",
      },
      {
        title: "4.2 Termination by You for Convenience",
        description:
          "You may terminate this Agreement for any reason by (a) providing written notice in accordance with Section 16.2, (b) closing your Account via available mechanisms, or (c) letting a Trial Account expire without further access.",
      },
      {
        title: "4.2.1 Account Closure",
        description:
          "For Paid Services with a minimum term or prepaid period, your Account will close immediately unless specified otherwise. Services requiring advance notice of termination will close at the end of the notice period as described in the applicable Service Description.",
      },
    ],
  },

  // {
  //   section: "5. Events On Life PROPERTIES; Events On Life CONTENT",
  //   description:
  //     'From time to time we may make available to you, via the developer portal at https://developers.eventsonlife.com/, a variety of software, data and other content and printed and electronic documentation for your access, installation and/or use in connection with the Services (all such materials except those specifically made available by us under separate license terms, the "Events On Life Properties"). The Events On Life Properties may include without limitation: APIs; developer tools for use in connection with the APIs, such as sample source code and libraries; articles and documentation for use in connection with the use and implementation of the APIs (collectively, "Documentation"); specifications describing the operational and functional capabilities, use limitations, technical and engineering requirements, and testing and performance criteria relevant to the proper use of a Service and its related APIs and other technology, textual materials made available as part of the Service ("Text Materials"); and other forms of digital content, data, text, images, logos, user interface designs and other creative designs, audio and video (with the Text Materials, collectively, "Events On Life Content"). These Terms of Use apply to your access, installation and use of the Events On Life Properties and Events On Life Content.',
  // },
  {
    section: "5. LICENSE GRANT; RETAINED RIGHTS",
    subsections: [
      {
        subsection: "5.1 License Grant",
        description: `
          Subject to your acceptance of and compliance with this Agreement and with the payment requirements for the Services that are set forth in the applicable Service Description (as such payment terms may be updated from time to time), we hereby grant you a limited, non-exclusive, non-transferable, non-sublicenseable right and license, in and under our intellectual property rights, to (i) access and use the Site and Services during the Term, and (ii) access, install, copy, and use the Events On Life Properties solely in connection with and as necessary for your use of the subject Services.
        `,
      },
      {
        subsection: "5.2 Nonexclusive Rights",
        description: `
          The rights and licenses granted by us in this Agreement are nonexclusive, and we reserve the right to: (a) act as developers of products or services related to products you may develop in connection with the Events On Life Properties or via your use of the Services, and (b) appoint third parties as developers or systems integrators who may offer products or services competing with your Applications.
        `,
      },
    ],
  },
  {
    section: "6. ACCEPTABLE USE POLICY",
    subsections: [
      {
        title: "6.1 Permitted Uses Generally",
        description: `
          You may write or develop software, websites, or other online services or technology that interface with the Services (collectively "Applications"). Applications include machine images containing software applications, libraries, data, and associated configuration settings. You acknowledge that we may change, deprecate, or republish APIs and other developer tools for any Service or feature of a Service from time to time, and it is your responsibility to ensure that calls you make to any Service are compatible with the then-current APIs and/or other developer tools for the Service. 

          You further acknowledge that we may change or remove features or functionality of the Services at any time. You may enable access and use of Your Content by your end users subject to the terms of this Section 6. "Your Content" means any Application, data, or other content that you may: (a) provide to us pursuant to this Agreement, (b) make available to any end users in conjunction with the Services, or (c) develop or use in connection with the Services. You are responsible for all terms and conditions applicable to Your Content.
        `,
      },
      {
        title: "6.2 Restricted Uses Generally",
        description: `
          You may not interfere or attempt to interfere in any manner with the functionality or proper working of the Services. 

          You may not compile or use the Events On Life Properties or any other information obtained through the Services for direct marketing, spamming, unsolicited contacting of sellers or customers, or other impermissible advertising, marketing, or other activities, including, without limitation, any activities that violate anti-spamming laws and regulations applicable in Hyderabad, Telangana, India.

          You may not remove, obscure, or alter any notice of trademarks, service marks, trade names, logos, or other designations of Events On Life and its affiliates or licensors (the "Marks") or other intellectual property or proprietary right designations appearing on or contained within the Services or Events On Life Properties.

          You may generally publicize your use of the Services; however, you may not issue any press release with respect to the Services or this Agreement without our prior written consent.

          You may not publish content that we, at our sole discretion, deem offensive. This includes depictions of explicit sexual activities and extreme violence depicted for entertainment purposes.

          Except as expressly authorized under this Agreement, you shall not: (a) license, sublicense, sell, resell, transfer, assign, distribute, or otherwise commercially exploit or make available to any third party the Services or Events On Life Properties in any way, (b) modify or make derivative works based upon the Services or Events On Life Properties, (c) create Internet "links" to the Services, (d) reverse engineer the Services or Events On Life Properties, or (e) access the Services or Events On Life Properties for the purposes of competitive analysis, development, provision, or use of a competing service or product, or any other purpose that is to our detriment or commercial disadvantage.
        `,
      },
    ],
  },
  {
    section: "7. DOWNTIME AND SERVICE SUSPENSIONS; SECURITY",
    subsections: [
      {
        title: "7.1 Downtime and Service Suspensions",
        description:
          "In addition to our rights to terminate or suspend Services to you as described in Section 4 above, you acknowledge that: (a) your access to and use of the Services may be suspended for the duration of any unanticipated or unscheduled downtime or unavailability of any portion or all of the Services for any reason, including as a result of power outages, system failures, or other interruptions, and (b) we shall also be entitled, without any liability to you, to suspend access to any portion or all of the Services at any time, on a Service-wide basis: for scheduled downtime to permit us to conduct maintenance or make modifications to any Service, in the event of a denial-of-service attack or other event that we determine, in our sole discretion, may create a risk to the applicable Service, to you, or to any of our other customers if the Service were not suspended, or in the event that we determine, in our sole discretion, that any Service is prohibited by law or we otherwise determine, in our sole discretion, that it is necessary or prudent to do so for legal or regulatory reasons applicable in India.",
      },
      {
        title: "7.2 Security",
        description:
          "We strive to keep Your Content secure but cannot guarantee that we shall be successful at doing so, given the nature of the Internet. Accordingly, without limitation to Section 1.5 above and Section 12.4 below, you acknowledge that you bear sole responsibility for adequate security, protection, and backup of Your Content and Applications. We strongly encourage you, where available and appropriate, to: (a) use encryption technology to protect Your Content from unauthorized access, (b) routinely archive Your Content, and (c) keep your Applications or any software that you use or run with our Services current with the latest security patches or updates. We shall have no liability to you for any unauthorized access, corruption, deletion, destruction, or loss of any of Your Content or Applications.",
      },
    ],
  },
  {
    section: "8. FEES",
    subsections: [
      {
        title: "8.1 Service Fees",
        description:
          "In consideration of your use of any of the Paid Services, you agree to pay the fees set forth on the applicable Service Description. Fees for any new Service or new Service feature shall be effective upon posting by us on the applicable Service page. We may increase or add new fees for any existing Service or Service feature, or implement a fee for any previously free Service or Service feature, by giving you thirty (30) days’ advance notice. Such notice shall be posted on the Service Description page for the affected Service. You agree that you are responsible for checking the applicable Service page each month to confirm whether there are any new fees and their effective dates. All fees payable by you are exclusive of applicable taxes and duties, including, without limitation, GST and other applicable Indian taxes. You shall provide such information to us as reasonably required to determine whether we are obligated to collect GST from you, including your GST identification number.",
      },
      {
        title: "8.2 Payment",
        description:
          "We may specify the way you shall pay any fees, and any such payment shall be subject to our general accounts receivable policies from time to time in effect. All amounts payable by you under this Agreement shall be made without setoff or counterclaim and without deduction or withholding. If any deduction or withholding is required by applicable Indian law, you shall notify us and shall pay such additional amounts to us as necessary to ensure that the net amount we receive, after such deduction and withholding, equals the amount we would have received if no such deduction or withholding had been required. Additionally, you shall provide us with documentation evidencing that the withholding and deducted amounts have been paid to the relevant taxing authority.",
      },
      {
        title: "8.3 Special Pricing Programs",
        description:
          "From time to time, we may offer free or discounted pricing for compute capacity, data transfer, data storage, and other usage of certain Services (each a 'Special Pricing Program'). After a Special Pricing Program ends, normal charges shall apply. You must comply with any additional terms, restrictions, or limitations (e.g., limitations on the total amount of usage) we impose in connection with the Special Pricing Program as described on the Service-specific detail pages. You may not sign up for multiple Accounts to receive additional benefits under a Special Pricing Program. We may immediately terminate any Account that we determine, in our sole discretion, is established or used to avoid the terms, restrictions, or limitations applicable to a Special Pricing Program.",
      },
    ],
  },
  {
    section: "9. CONFIDENTIALITY",
    subsections: [
      {
        title: "9.1 Use and Disclosure",
        description:
          "You shall not disclose Events On Life Confidential Information during the Term or at any time during the three (3) year period following the end of the Term. As used in this Agreement, 'Events On Life Confidential Information' means all non-public information disclosed by us, our business partners, or our respective agents or contractors that is designated as confidential or that, given the nature of the information or circumstances surrounding its disclosure, reasonably should be understood to be confidential. Events On Life Confidential Information includes, without limitation: (a) non-public information relating to our or our business partners’ technology, customers, business plans, promotional and marketing activities, finances, and other business affairs, (b) third-party information that we are obligated to keep confidential, and (c) the nature, content, and existence of any discussions or negotiations between you and us. Confidential Information does not include any information described in Section 9.2 or any information that you are required to disclose by law.",
      },
      {
        title: "9.2 Excluded Information",
        description:
          "Notwithstanding any other provision in this Agreement, you shall not have any confidentiality obligation to us under Section 9.1 above with respect to any information provided or made available by us hereunder, and we shall not have any confidentiality or non-use obligation to you hereunder with respect to any information, software application, data, or content provided or made available by you hereunder that: (a) is or becomes publicly available without breach of this Agreement, (b) can be shown by documentation to have been known to you at the time of its receipt from us, (c) is received from a third party who did not acquire or disclose the same by a wrongful act, or (d) can be shown by documentation to have been independently developed by you without reference to the Events On Life Confidential Information.",
      },
      {
        title: "9.3 Conflict with Separate Non-Disclosure Agreement",
        description:
          "If you and we are parties to a separate non-disclosure agreement ('Stand-Alone NDA') and there is a conflict between the terms of the Stand-Alone NDA and the terms of this Section 9, the terms of the Stand-Alone NDA shall control.",
      },
    ],
  },
  {
    section:
      "10. REPRESENTATIONS AND WARRANTIES; DISCLAIMERS; LIMITATIONS OF LIABILITY",
    subsections: [
      {
        title: "10.1 Use of the Services",
        description: [
          "You represent and warrant that you shall not use the Services, Events On Life Properties, your Application, or Your Content:",
          "(a) in a manner that infringes, violates, or misappropriates any of our rights or those of any third party.",
          "(b) to engage in spamming or other impermissible advertising, marketing, or other activities, including, without limitation, any activities that violate anti-spamming laws and regulations, including, without limitation, the Information Technology Act of 2000 (India) and any rules or amendments made under it.",
          "(c) in any manner that constitutes or facilitates the illegal export of any controlled or restricted items, including, without limitation, software, algorithms, or other data that is subject to Indian export laws and regulations.",
          "(d) in a way that is otherwise illegal or promotes illegal activities, including without limitation, in a manner that might be libelous, defamatory, malicious, or harmful to any person or entity, or discriminatory based on race, sex, religion, nationality, disability, sexual orientation, or age.",
        ],
      },
      {
        title: "10.2 Your Applications and Content",
        description: [
          "You represent and warrant:",
          "(a) that you are solely responsible for the development, operation, and maintenance of Your Content, including, without limitation, the accuracy, security, appropriateness, and completeness of Your Content and all product-related materials and descriptions.",
          "(b) that you have the necessary rights and licenses, consents, permissions, waivers, and releases to use and display Your Content.",
          "(c) that Your Content:",
          "(i) does not violate, misappropriate, or infringe any of our rights or those of any third party;",
          "(ii) does not constitute defamation, invasion of privacy, or publicity, or otherwise violate any rights of any third party;",
          "(iii) is not designed for use in any illegal activity or to promote illegal activities, including, without limitation, use in a manner that might be libelous, defamatory, malicious, or harmful to any person or entity, or discriminatory based on race, sex, religion, nationality, disability, sexual orientation, or age.",
          "(d) that Your Content does not contain any unauthorized data, malware, viruses, Trojan horses, spyware, worms, or other malicious or harmful code.",
        ],
      },
      {
        title: "10.3 Public Software and Feedback",
        description: [
          "You represent and warrant that you shall not use, and shall not authorize any third party to use, any Public Software in connection with the Events On Life Properties or Services in any manner that requires, pursuant to the license applicable to such Public Software, that any Events On Life Properties or Services be:",
          "(a) disclosed or distributed in source code form;",
          "(b) made available free of charge to recipients; or",
          "(c) modifiable without restriction by recipients.",
          "You represent and warrant that any Feedback contributed by or through you, in whole or in part:",
          "(a) does not contain third-party software or any software that may be considered Public Software; and",
          "(b) does not violate, misappropriate, or infringe any intellectual property rights of any third party.",
          '"Public Software" means any software, documentation, or other material that contains, or is derived (in whole or in part) from, any software, documentation, or other material that is distributed as free, open source, or community source software, or under similar licensing or distribution models.',
        ],
      },
      {
        title: "10.4 Disclaimers",
        description: [
          "You understand that we cannot and do not guarantee or warrant that files available for downloading from the internet or the Site shall be free of viruses or other destructive code.",
          "You are responsible for implementing sufficient procedures and checkpoints to satisfy your particular requirements for anti-virus protection and accuracy of data input and output, and for maintaining a means external to the Site for any reconstruction of any lost data.",
          "TO THE FULLEST EXTENT PROVIDED BY LAW, WE SHALL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SITE OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED TO IT. YOUR USE OF THE EVENTS ON LIFE PROPERTIES, THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE IS AT YOUR OWN RISK.",
          'THE EVENTS ON LIFE PROPERTIES, THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE SITE ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.',
        ],
      },
      {
        title: "10.5 Your Applications are Your Responsibility",
        description: [
          "You agree that you shall, without limitation, be solely responsible for:",
          "10.5.1. The technical operation of your Application and all related software, hardware, and other equipment;",
          "10.5.2. The accuracy and appropriateness of any materials posted on or within your Application;",
          "10.5.3. Ensuring that any materials posted within your Application are not illegal and do not promote illegal activities;",
          "10.5.4. Ensuring that your Application accurately and adequately discloses how you collect, use, store, and disclose data collected from visitors;",
          "10.5.5. Any of your users’ or customers’ claims relating to your Application;",
          "10.5.6. Your election to utilize APIs, Sample Source Code, and Libraries that may be provided by third parties.",
        ],
      },
      {
        title: "10.6 Links",
        description: [
          'The Site and/or the Services may contain links to websites that are not under our control ("Third Party Sites"). We are not responsible for the contents or functionality of any Third Party Sites. The inclusion of such links does not constitute or imply our endorsement or validation of any Third Party Site.',
        ],
      },
      {
        title: "10.7 LIMITATIONS OF LIABILITY",
        description: [
          "TO THE FULLEST EXTENT PROVIDED BY LAW, NEITHER EVENTS ON LIFE, NOR ANY OF ITS AFFILIATES, OR OUR LICENSORS OR SERVICE PROVIDERS SHALL BE LIABLE FOR DAMAGES OF ANY KIND, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE EVENTS ON LIFE PROPERTIES, INCLUDING ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES.",
          "The limitation of liability set out above does not apply to liability resulting from our gross negligence or willful misconduct.",
          "In any case, our aggregate liability under this Agreement shall be limited to the amount actually paid by you to us for the Services.",
          "THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.",
        ],
      },
    ],
  },
  {
    section: "11. INDEMNIFICATION",
    subsections: [
      {
        title: "11.1 General",
        description: (
          <>
            <p>
              You agree to indemnify, defend, and hold us, our licensors, our
              business partners (including third-party sellers on websites
              operated by us or on our behalf), and each of our and their
              respective employees, officers, directors, and representatives,
              harmless from and against any and all claims, losses, damages,
              liabilities, judgments, penalties, fines, costs, and expenses
              (including reasonable attorney’s fees), arising out of or in
              connection with:
            </p>
            <p>
              (a) your use of the Site, the Services, and/or Events On Life
              Properties in a manner not authorized by this Agreement and/or in
              violation of the Acceptable Use Policy, Additional Policies,
              and/or applicable law,
            </p>
            <p>
              (b) Your Content and Applications, or the combination of either
              with other applications, content, or processes, including but not
              limited to any claim involving infringement or misappropriation
              of third-party rights and/or the use, development, design,
              manufacture, production, advertising, promotion, and/or marketing
              of Your Content,
            </p>
            <p>
              (c) your violation of any term or condition of this Agreement or
              any applicable Additional Policies, including without limitation,
              your representations and warranties, or
            </p>
            <p>
              (d) your or your employees’ or personnel’s negligence or willful
              misconduct.
            </p>
          </>
        ),
      },
      {
        title: "11.2 Notification",
        description: (
          <>
            <p>
              We agree to promptly notify you of any claim subject to
              indemnification; provided that our failure to promptly notify you
              shall not affect your obligations hereunder except to the extent
              that our failure delays or prejudices your ability to defend the
              claim.
            </p>
            <p>
              At our option, you shall have the right to defend against any such
              claim with counsel of your own choosing (subject to our written
              consent) and to settle such claim as you deem appropriate,
              provided that you shall not enter into any settlement without our
              prior written consent and provided that we may, at any time,
              elect to take over control of the defense and settlement of the
              claim.
            </p>
          </>
        ),
      },
    ],
  },
  {
    section: "12. INDIAN GOVERNMENT LICENSE RIGHTS; IMPORT AND EXPORT COMPLIANCE",
    subsections: [
      {
        title: "12.1 Indian Government License Rights",
        description:
          "All Services provided to the Indian Government are provided under the commercial license rights and restrictions generally applicable under this Agreement.",
      },
      {
        title: "12.2 Import and Export Compliance and Restrictions",
        description:
          "You shall, in connection with your use of the Site, the Services, and the Events On Life Properties, comply with all export and import laws and regulations of India and other applicable jurisdictions. Without limiting the foregoing: (i) you represent and warrant that you are not listed on any Indian government list of prohibited or restricted parties or located in (or a national of) a country subject to an Indian government embargo, and (ii) you shall not access or use the Site, the Services, or the Events On Life Properties in violation of any Indian export embargo, prohibition, or restriction.",
      },
    ],
  },
  {
    section: "13. NOTICES",
    subsections: [
      {
        title: "13.1 To You",
        description:
          "Notices made by us to you under this Agreement that are applicable to our customers generally (e.g., updates to terms, fees, etc.) shall be posted on the Site. Notices specific to you or your account (e.g., breach notices) shall be sent to the email address you provided during registration. You are responsible for keeping your email address updated and are deemed to have received our emails upon sending, regardless of whether you read them.",
      },
      {
        title: "13.2 To Us",
        description:
          "You may contact us at: support@eventsonlife.com or visit our office at 656, Ayyappa Society, Madhapur, Hyderabad, Telangana 500081.",
      },
      {
        title: "13.3 Language",
        description:
          "All communications and notices under this Agreement shall be in English.",
      },
    ],
  },
  {
    section: "14. MISCELLANEOUS PROVISIONS",
    subsections: [
      {
        title: "14.1 Governing Law and Jurisdiction",
        description:
          "This Agreement and any disputes arising hereunder shall be governed by the laws of India. Any legal action shall be subject to the exclusive jurisdiction of the courts of Hyderabad, Telangana.",
      },
      {
        title: "14.2 Limitation on Time to File Claims",
        description:
          "Any claim you may have arising out of or relating to this Agreement must be filed within one (1) year of its accrual, failing which it will be permanently barred.",
      },
      {
        title: "14.3 Severability",
        description:
          "If any provision of this Agreement is held to be invalid, the remaining provisions shall remain in full force and effect.",
      },
      {
        title: "14.4 Entire Agreement",
        description:
          "This Agreement, along with the policies referenced herein, constitutes the entire agreement between you and us.",
      },
      {
        title: "14.5 No Waiver",
        description:
          "Failure to enforce any provision of this Agreement does not constitute a waiver of that provision.",
      },
    ],
  },
  // {
  //   section: "18. Promo Code Terms and Conditions",
  //   description:
  //     "If you use a promo code in connection with our Services, you agree to abide by our Promo Code Terms and Conditions, which are incorporated into these Terms of Service by reference.",
  // },
];

const Terms = () => {
  return (
    <div>
      <h1 className={styles.textCustom}>Your Agreement</h1>
      <p className={styles.contentDiscription}>
        This is a binding agreement between Events On Life. ( "Events On Life" )
        , a video streaming service provider, and you as an individual and, if
        applicable, the company or other legal entity you represent
        (collectively, "you" ). The following terms and conditions, together
        with any documents they expressly incorporate by reference
        (collectively, the "Agreement" or "Terms of Service" ), govern your
        access to and use of www.eventsonlife.com and any of Events On Life
        other websites that directly link to these Terms of Service
        (collectively, the "Site" ), including any content, functionality, and
        Services offered on or through the Site.
      </p>
      <br />
      <p></p>
      <p className={styles.contentDiscription}>
        Please read these Terms of Service carefully before you start to use the
        Site. By using the Site or by clicking to agree to these Terms of
        Service when this option is made available to you, you accept and agree
        to be bound and abide by these Terms of Service and our Privacy Policy,
        found at{" "}
        <a
          style={{ color: "#1d4ed8" }}
          href="http://www.eventsonlife.com/privacy-policy"
          target="_blank"
        >
          http://www.eventsonlife.com/privacy-policy
        </a>
        . If you do not want to agree to these Terms of Service or the Privacy
        Policy, you must not access or use the Site or any content,
        functionality, or Services offered on or through the Site.
      </p>
      <br />

      <p className={styles.contentDiscription}>
        If you are entering into these Terms of Service on behalf of a company
        or other legal entity, you confirm that you have the legal authority to
        bind the legal entity to these Terms of Service. If you do not have such
        authority, or if you do not agree with these Terms of Service or the
        Privacy Policy, you may not use the Site or any content, functionality,
        or Services offered on or through the Site.
      </p>
      <br />
      <h1>Contents</h1>
      <ol
        style={{
          marginTop: "1rem",
          listStyleType: "decimal",
          paddingLeft: "1.25rem",
          paddingRight: "1.25rem",
        }}
      >
        {contents.map((each, index) => {
          return (
            <li style={{ marginBottom: "0.5rem" }} key={index}>
              <a style={{ color: "#1d4ed8" }} href={`#${index + 1}`}>
                {each.term}
              </a>
            </li>
          );
        })}
      </ol>
      {terms.map((each, index) => {
        return (
          <div
            key={index + each.section}
            id={index + 1}
            className={styles.contentCustom}
          >
            <h1
              style={{
                marginBottom: "0.5rem",
                fontFamily:
                  "font-family: Graphik-Bold-Web, Helvetica, Arial, sans-serif",
              }}
            >
              {each.section}
            </h1>
            {each?.description && (
              <div
                style={{ margin: "0px 0px 10px 30px", padding: "0px" }}
                className={styles.contentDiscription}
              >
                {each?.description}
              </div>
            )}
            {each.subsections &&
              each.subsections.map((eachChild, childIndex) => {
                // const { ref, inView } = useInView({
                //   triggerOnce: true,
                //   threshold: 0.1,
                // });

                return (
                  <motion.div
                    // ref={ref}
                    key={childIndex}
                    // initial={{ opacity: 0, y: 20 }}
                    // animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 0 }}
                    // transition={{ duration: 0.6, delay: index * 0.1 }}
                    style={{ margin: "30px 0px 10px 30px" }}
                  >
                    <h4 style={{ marginBottom: "10px" }}>{eachChild?.title}</h4>
                    <div className={styles.contentDiscription}>
                      {eachChild.description}
                    </div>
                  </motion.div>
                );
              })}
          </div>
        );
      })}
    </div>
  );
};

export default Terms;

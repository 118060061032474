import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useAppDataContext } from "hooks/useappdatacontext";
import { useEffect, useRef } from "react";
import {
  BiSolidVideoRecording,
  BiLogoYoutube,
  BiCloudUpload,
} from "react-icons/bi";

import { ArrowLeft, ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { RiAdvertisementLine } from "react-icons/ri";

const Packages = () => {
  const { pricingData, getPricingData, loading } = useAppDataContext();
  const navigate = useNavigate();
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Infinite scroll
    speed: 500, // Speed of transition
    slidesToShow: 3, // Number of items to show at once
    slidesToScroll: 1, // Number of items to scroll at once
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchPricingData = async () => {
      await getPricingData();
    };
    fetchPricingData();
  }, []);

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  const capitalizeWords = (str1, str2) => {
    if (
      str1.toLowerCase().includes("pack") &&
      str2.toLowerCase().includes("pack")
    ) {
    }
    if (str1.toLowerCase() === str2.toLowerCase()) {
      return str1.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
    } else {
      let word = str1 + " " + str2;
      let splittedWords = word.toLowerCase().split(" ");
      let uniqueWords = [];

      splittedWords.forEach((word) => {
        if (!uniqueWords.includes(word)) {
          uniqueWords.push(word);
        }
      });

      return uniqueWords
        .join(" ")
        .toLowerCase()
        .replace(/\b\w/g, (char) => char.toUpperCase());
    }
  };

  const sliderRef = useRef(null);
  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const renderDiscountPrice = (actual, sale) => {
    let x = ((actual - sale) / actual) % 30;
    return x.toFixed(2) * 100;
  };
  // let pricesall = [];
  // if (pricingData && Array.isArray(pricingData.pricing)) {
  //   const prices = pricingData.pricing;
  //   pricesall = prices.filter((plan) => {
  //     // Exclude plans with ads if REACT_APP_GOOGLE_ADS is not "true"
  //     return !(plan.num_ads > 0 && process.env.REACT_APP_GOOGLE_ADS !== "true");
  //   });
  // }
  let cloudPack =
    pricingData?.pricing &&
    pricingData?.pricing.length > 0 &&
    pricingData?.pricing.filter(
      (each) => each.pack.toLowerCase() === "cloud pack"
    );
  let ytPack =
    pricingData?.pricing &&
    pricingData?.pricing.length > 0 &&
    pricingData?.pricing.filter(
      (each) => each.pack.toLowerCase() === "youtube pack"
    );
  let resellerPack =
    pricingData?.pricing &&
    pricingData?.pricing.length > 0 &&
    pricingData?.pricing.filter(
      (each) => each.pack.toLowerCase() === "reseller pack"
    );
  let comboPack =
    pricingData?.pricing &&
    pricingData?.pricing.length > 0 &&
    pricingData?.pricing.filter(
      (each) => each.pack.toLowerCase() === "combo pack"
    );

  return (
    <section id="pricing" className="custom-services-gradient-section">
      {/* <div className="custom-services-container"> */}
      <div>
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
          className="text-services-section-center"
        >
          <motion.h2
            variants={itemVariants}
            className="service-section-heading"
          >
            Packages
          </motion.h2>
          <motion.p variants={itemVariants} className="service-section-text">
            Choose the Perfect Plan for your Needs.
          </motion.p>
        </motion.div>
        <div>
          {pricingData === null ? (
            <h1
              style={{
                margin: "30px 0px",
                textAlign: "center",
                fontSize: "22px",
                fontWeight: "600",
                color: "#D32F2F", // Red color for error message
                fontFamily: "'Arial', sans-serif",
                lineHeight: "1.4",
                padding: "20px",
                borderRadius: "8px", // Rounded corners for the background
              }}
            >
              Oops...! Got Network Error, Please Try Again Later
            </h1>
          ) : (
            <>
              {cloudPack && cloudPack.length > 0 && cloudPack && (
                <>
                  <h2
                    style={{ marginTop: "0px" }}
                    className="pack-heading-main"
                  >
                    Cloud Pack
                  </h2>
                  <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    className="pricing-card-container-individual"
                  >
                    {cloudPack &&
                      cloudPack.length > 0 &&
                      cloudPack.map((plan, index) => (
                        <div className="pricing-card-container" key={index}>
                          {/* <p className="price-heading">{plan.pack}</p> */}
                          <p className="pricing-head">{plan.name}</p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              flexDirection: "column",
                              height: "200px",
                              justifyContent: "center",
                            }}
                          >
                            {plan.actual_price === plan.Sale_price ? null : (
                              <p
                                style={{
                                  textDecoration:
                                    plan.actual_price < plan.Sale_price
                                      ? "none"
                                      : plan.Sale_price === 0
                                      ? "none"
                                      : "line-through",
                                  color: "rgb(95 92 92)",
                                  fontSize: "20px",
                                }}
                              >
                                ₹{plan.actual_price}
                              </p>
                            )}

                            {plan.Sale_price === 0 ? null : (
                              <p
                                style={{
                                  fontFamily: "cursive",
                                  fontSize:
                                    renderDiscountPrice(
                                      plan.actual_price,
                                      plan.Sale_price
                                    ) > 1
                                      ? "28px"
                                      : "3em",
                                  fontWeight: "bold",
                                  color: "#083c67",
                                  margin: "0",
                                }}
                              >
                                ₹{plan.Sale_price}{" "}
                              </p>
                            )}
                            {plan.actual_price !== 0 &&
                              plan.actual_price !== plan.Sale_price && (
                                <p
                                  style={{
                                    color: "#16fc16",
                                    fontFamily: "cursive",
                                    fontSize: "18px",
                                  }}
                                >
                                  {renderDiscountPrice(
                                    plan.actual_price,
                                    plan.Sale_price
                                  )}
                                  % OFF
                                </p>
                              )}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "space-around",
                              gap: "10px",
                            }}
                          >
                            {/* <p className="pricing-head">{plan.name}</p> */}

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                              }}
                            >
                              {/* {plan?.num_Records > 0 && (
                          <BiSolidVideoRecording className="icon-sizes" />
                        )} */}
                              {plan?.num_Events > 0 && (
                                <>
                                  {" "}
                                  <BiCloudUpload className="icon-sizes" />
                                </>
                              )}

                              {plan?.num_yt_coins > 0 && (
                                <>
                                  {" "}
                                  <BiLogoYoutube className="icon-sizes" />
                                </>
                              )}
                              {plan?.num_ads > 0 && (
                                <>
                                  {" "}
                                  <RiAdvertisementLine className="icon-sizes" />
                                </>
                              )}
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              navigate("/login");
                            }}
                            className="button-started"
                          >
                            Get Started
                          </button>
                        </div>
                      ))}
                  </motion.div>
                </>
              )}
              {ytPack && ytPack.length > 0 && ytPack && (
                <>
                  <h2
                    style={{ marginTop: "50px" }}
                    className="pack-heading-main"
                  >
                    Youtube Pack
                  </h2>
                  <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    className="pricing-card-container-individual"
                  >
                    {ytPack &&
                      ytPack.length > 0 &&
                      ytPack.map((plan, index) => (
                        <div className="pricing-card-container" key={index}>
                          {/* <p className="price-heading">{plan.pack}</p> */}
                          <p className="pricing-head">{plan.name}</p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              flexDirection: "column",
                              height: "200px",
                              justifyContent: "center",
                            }}
                          >
                            {plan.actual_price === plan.Sale_price ? null : (
                              <p
                                style={{
                                  textDecoration:
                                    plan.actual_price < plan.Sale_price
                                      ? "none"
                                      : plan.Sale_price === 0
                                      ? "none"
                                      : "line-through",
                                  color: "rgb(95 92 92)",
                                  fontSize: "20px",
                                }}
                              >
                                ₹{plan.actual_price}
                              </p>
                            )}

                            {plan.Sale_price === 0 ? null : (
                              <p
                                style={{
                                  fontFamily: "cursive",
                                  fontSize:
                                    renderDiscountPrice(
                                      plan.actual_price,
                                      plan.Sale_price
                                    ) > 1
                                      ? "28px"
                                      : "3em",
                                  fontWeight: "bold",
                                  color: "#083c67",
                                  margin: "0",
                                }}
                              >
                                ₹{plan.Sale_price}{" "}
                              </p>
                            )}
                            {plan.actual_price !== 0 &&
                              plan.actual_price !== plan.Sale_price && (
                                <p
                                  style={{
                                    color: "#16fc16",
                                    fontFamily: "cursive",
                                    fontSize: "18px",
                                  }}
                                >
                                  {renderDiscountPrice(
                                    plan.actual_price,
                                    plan.Sale_price
                                  )}
                                  % OFF
                                </p>
                              )}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "space-around",
                              gap: "10px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                              }}
                            >
                              {/* {plan?.num_Records > 0 && (
                          <BiSolidVideoRecording className="icon-sizes" />
                        )} */}
                              {plan?.num_Events > 0 && (
                                <>
                                  {" "}
                                  <BiCloudUpload className="icon-sizes" />
                                </>
                              )}

                              {plan?.num_yt_coins > 0 && (
                                <>
                                  {" "}
                                  <BiLogoYoutube className="icon-sizes" />
                                </>
                              )}
                              {plan?.num_ads > 0 && (
                                <>
                                  {" "}
                                  <RiAdvertisementLine className="icon-sizes" />
                                </>
                              )}
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              navigate("/login");
                            }}
                            className="button-started"
                          >
                            Get Started
                          </button>
                        </div>
                      ))}
                  </motion.div>
                </>
              )}
              {resellerPack && resellerPack.length > 0 && resellerPack && (
                <>
                  <h2
                    style={{ marginTop: "50px" }}
                    className="pack-heading-main"
                  >
                    Reseller Pack
                  </h2>
                  <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    className="pricing-card-container-individual"
                  >
                    {resellerPack &&
                      resellerPack.length > 0 &&
                      resellerPack.map((plan, index) => (
                        <div className="pricing-card-container" key={index}>
                          {/* <p className="price-heading">{plan.pack}</p> */}
                          <p className="pricing-head">{plan.name}</p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              flexDirection: "column",
                              height: "200px",
                              justifyContent: "center",
                            }}
                          >
                            {plan.actual_price === plan.Sale_price ? null : (
                              <p
                                style={{
                                  textDecoration:
                                    plan.actual_price < plan.Sale_price
                                      ? "none"
                                      : plan.Sale_price === 0
                                      ? "none"
                                      : "line-through",
                                  color: "rgb(95 92 92)",
                                  fontSize: "20px",
                                }}
                              >
                                ₹{plan.actual_price}
                              </p>
                            )}

                            {plan.Sale_price === 0 ? null : (
                              <p
                                style={{
                                  fontFamily: "cursive",
                                  fontSize:
                                    renderDiscountPrice(
                                      plan.actual_price,
                                      plan.Sale_price
                                    ) > 1
                                      ? "28px"
                                      : "3em",
                                  fontWeight: "bold",
                                  color: "#083c67",
                                  margin: "0",
                                }}
                              >
                                ₹{plan.Sale_price}{" "}
                              </p>
                            )}
                            {plan.actual_price !== 0 &&
                              plan.actual_price !== plan.Sale_price && (
                                <p
                                  style={{
                                    color: "#16fc16",
                                    fontFamily: "cursive",
                                    fontSize: "18px",
                                  }}
                                >
                                  {renderDiscountPrice(
                                    plan.actual_price,
                                    plan.Sale_price
                                  )}
                                  % OFF
                                </p>
                              )}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "space-around",
                              gap: "10px",
                            }}
                          >
                            {/* <p className="pricing-head">{plan.name}</p> */}

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                              }}
                            >
                              {/* {plan?.num_Records > 0 && (
                          <BiSolidVideoRecording className="icon-sizes" />
                        )} */}
                              {plan?.num_Events > 0 && (
                                <>
                                  {" "}
                                  <BiCloudUpload className="icon-sizes" />
                                </>
                              )}

                              {plan?.num_yt_coins > 0 && (
                                <>
                                  {" "}
                                  <BiLogoYoutube className="icon-sizes" />
                                </>
                              )}
                              {plan?.num_ads > 0 && (
                                <>
                                  {" "}
                                  <RiAdvertisementLine className="icon-sizes" />
                                </>
                              )}
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              navigate("/login");
                            }}
                            className="button-started"
                          >
                            Get Started
                          </button>
                        </div>
                      ))}
                  </motion.div>
                </>
              )}
              {comboPack && comboPack.length > 0 && comboPack && (
                <>
                  <h2
                    style={{ marginTop: "50px" }}
                    className="pack-heading-main"
                  >
                    Combo Pack
                  </h2>
                  <motion.div
                    variants={containerVariants}
                    initial="hidden"
                    animate={inView ? "visible" : "hidden"}
                    className="pricing-card-container-individual"
                  >
                    {comboPack &&
                      comboPack.length > 0 &&
                      comboPack.map((plan, index) => (
                        <div className="pricing-card-container" key={index}>
                          {/* <p className="price-heading">{plan.pack}</p> */}
                          <p className="pricing-head">{plan.name}</p>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              flexDirection: "column",
                              height: "200px",
                              justifyContent: "center",
                            }}
                          >
                            {plan.actual_price === plan.Sale_price ? null : (
                              <p
                                style={{
                                  textDecoration:
                                    plan.actual_price < plan.Sale_price
                                      ? "none"
                                      : plan.Sale_price === 0
                                      ? "none"
                                      : "line-through",
                                  color: "rgb(95 92 92)",
                                  fontSize: "20px",
                                }}
                              >
                                ₹{plan.actual_price}
                              </p>
                            )}

                            {plan.Sale_price === 0 ? null : (
                              <p
                                style={{
                                  fontFamily: "cursive",
                                  fontSize:
                                    renderDiscountPrice(
                                      plan.actual_price,
                                      plan.Sale_price
                                    ) > 1
                                      ? "28px"
                                      : "3em",
                                  fontWeight: "bold",
                                  color: "#083c67",
                                  margin: "0",
                                }}
                              >
                                ₹{plan.Sale_price}{" "}
                              </p>
                            )}
                            {plan.actual_price !== 0 &&
                              plan.actual_price !== plan.Sale_price && (
                                <p
                                  style={{
                                    color: "#16fc16",
                                    fontFamily: "cursive",
                                    fontSize: "18px",
                                  }}
                                >
                                  {renderDiscountPrice(
                                    plan.actual_price,
                                    plan.Sale_price
                                  )}
                                  % OFF
                                </p>
                              )}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "space-around",
                              gap: "10px",
                            }}
                          >
                            {/* <p className="pricing-head">{plan.name}</p> */}

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "5px",
                              }}
                            >
                              {/* {plan?.num_Records > 0 && (
                          <BiSolidVideoRecording className="icon-sizes" />
                        )} */}
                              {plan?.num_Events > 0 && (
                                <>
                                  {" "}
                                  <BiCloudUpload className="icon-sizes" />
                                </>
                              )}

                              {plan?.num_yt_coins > 0 && (
                                <>
                                  {" "}
                                  <BiLogoYoutube className="icon-sizes" />
                                </>
                              )}
                              {plan?.num_ads > 0 && (
                                <>
                                  {" "}
                                  <RiAdvertisementLine className="icon-sizes" />
                                </>
                              )}
                            </div>
                          </div>
                          <button
                            onClick={() => {
                              navigate("/login");
                            }}
                            className="button-started"
                          >
                            Get Started
                          </button>
                        </div>
                      ))}
                  </motion.div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Packages;

import { useEffect, useRef, useState } from "react";
import { RiLiveFill } from "react-icons/ri";
import { useAppDataContext } from "hooks/useappdatacontext";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./global.css";
import styles from "./styles.module.css";
import { Atom } from "react-loading-indicators";
import axios from "axios";
import { Button, Dialog, DialogTitle } from "@mui/material";
import { updateCustomHls } from "../../networking/apis/users";

const HLSPlayer = ({
  setStreamStatus,
  streamStatus,
  singleChannelData,
  weddingPage,
  editPage,
  hider,
}) => {
  // Get video URL from context
  const [loadingView, setLoadingView] = useState(true);
  const [know, setKnow] = useState("");
  const {
    eventData,
    singleEventData,
    openliveComplitionDialog,
    setOpenliveComplitionDialog,
    recordingCheck,
    checkRecorded,
  } = useAppDataContext();
  const hlsVideoUrl = eventData?.live_vidlink || singleEventData?.live_vidlink;
  // States
  const [error, setError] = useState("");

  useEffect(() => {
    if (
      singleChannelData?.recording &&
      know === "present" &&
      streamStatus === "OFFLINE"
    ) {
      recordingCheck(singleChannelData?.id);
    }
  }, [singleChannelData?.recording, know, streamStatus]);

  // console.log(new Date() === new Date());
  useEffect(() => {
    const inputDate = new Date(singleEventData?.venue_date);
    // const inputDate = new Date();
    const today = new Date();
    inputDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    if (inputDate < today) {
      setKnow("past");
    } else if (inputDate > today) {
      setKnow("future");
    } else {
      setKnow("present");
    }
  }, [singleEventData?.venue_date]);

  // References
  const [count, setCount] = useState(0);
  const videoRef = useRef(null);
  const playerRef = useRef(null);
  const [viewersCount, setViewersCount] = useState(0);

  let intervalIdRef;
  let anotherTimer;

  useEffect(() => {
    if (
      (know === "present" &&
        !singleEventData?.live_vidlink.includes("media/hls/master.m3u8")) ||
      !singleEventData?.live_vidlink.includes("cloudfront.net/ivs/v1/")
    ) {
      if (streamStatus === "LIVE") {
        anotherTimer = setInterval(() => {
          fetchViewerCount();
        }, 30000);
      } else if (streamStatus === "OFFLINE") {
        if (anotherTimer) {
          clearInterval(anotherTimer);
        }
      }
    }

    return () => {
      if (anotherTimer) {
        clearInterval(anotherTimer); // Cleanup on component unmount or when streamStatus changes
      }
    };
  }, [streamStatus]);

  const fetchViewerCount = async () => {
    if (singleEventData) {
      try {
        const { id } = singleEventData;
        const url = `${process.env.REACT_APP_BASE_URL}/stream/stream_status/${id}/`;
        const response = await axios.get(url);

        if (response.status === 200) {
          setStreamStatus(response.data.streamState);
          setViewersCount(response.data.viewerCount);
          if (response.data.streamState.toLowerCase() === "offline") {
            let r = await recordingCheck(singleChannelData?.id);
            
            if (r) {
              window.location.reload();
            }
          }
        }
      } catch (error) {
        console.error("Error fetching viewer count:", error);
      }
    }
  };

  async function checkStreamStatus(url) {
    try {
      const response = await fetch(url, { method: "GET" });
      if (response.ok) {
        const text = await response.text();
        // Optional: Check if the manifest contains valid HLS data
        if (text.includes("#EXTM3U")) {
          setStreamStatus("LIVE");
          return;
        } else {
          setCount(count + 1);
          clearInterval(anotherTimer);
          setStreamStatus("OFFLINE");
          return;
        }
      } else {
        setCount(count + 1);
        clearInterval(anotherTimer);
        setStreamStatus("OFFLINE");
        return;
      }
    } catch (error) {
      console.error("Error checking stream status:", error);
      setCount(count + 1);
      clearInterval(anotherTimer);
      setStreamStatus("OFFLINE");
      return;
    }
  }
  useEffect(() => {
    if (
      (know === "present" ||
        know === "past" ||
        singleEventData?.live_vidlink.includes("media/hls/master.m3u8") ||
        singleEventData?.live_vidlink.includes("cloudfront.net/ivs/v1/")) &&
      count === 0
    ) {
      checkStreamStatus(hlsVideoUrl);
    }
  }, [hlsVideoUrl, know]);

  // Start the interval
  useEffect(() => {
    if (
      know === "present" ||
      know === "past" ||
      singleEventData?.live_vidlink.includes("media/hls/master.m3u8") ||
      singleEventData?.live_vidlink.includes("cloudfront.net/ivs/v1/")
    ) {
      intervalIdRef = setInterval(async () => {
        checkStreamStatus(hlsVideoUrl);

        if (count >= 1 || openliveComplitionDialog) {
          if (know === "present" && streamStatus === "OFFLINE") {
            setOpenliveComplitionDialog(true);
          }
          clearInterval(intervalIdRef);
          clearInterval(anotherTimer);
        }
      }, 20000); // Check every second for testing (use 60000 for 60 seconds)
    }
    // Cleanup interval on component unmount
    return () => {
      if (intervalIdRef) {
        clearInterval(intervalIdRef);
      }
    };
  }, [hlsVideoUrl, know, streamStatus]);

  // Player options
  const playerOptions = {
    autoplay: editPage ? false : true,
    muted: true,
    controls: true,
    fluid: true, // This will make the video player responsive to the container
    preload: "auto",
    sources: [
      {
        src: hlsVideoUrl,
        type: "application/x-mpegURL",
      },
    ],
  };

  // let hasRecordingKey = singleChannelData && singleChannelData.hasOwnProperty("recording");

  useEffect(() => {
    const initializePlayer = () => {
      if (videoRef.current && !playerRef.current) {
        playerRef.current = videojs(videoRef.current, playerOptions, () => {
          console.log("Player is ready");
        });

        let retryCount = 0; // Initialize retry counter

        // Add error handling
        playerRef.current.on("error", (error) => {
          console.error("Video.js Error:", error);

          if (error.code === 3) {
            // MEDIA_ERR_DECODE
            if (retryCount < 3) {
              setError("An error occurred. Retrying...");
              retryCount++; // Increment retry counter
              setTimeout(() => {
                playerRef.current.src({
                  type: "application/x-mpegURL",
                  src: hlsVideoUrl,
                });
                playerRef.current.play();
              }, 3000);
            } else {
              setError("Event stream is not live.");
            }
          } else {
            if (retryCount < 3) {
              setTimeout(() => {
                playerRef.current.src({
                  type: "application/x-mpegURL",
                  src: hlsVideoUrl,
                });
                playerRef.current.play();
                retryCount++; // Increment retry counter
              }, 2000);
            } else {
              setError("Event stream is not live Yet.");
            }
          }
        });
        setError("");
      }
    };

    // Delay the player initialization to ensure the DOM is fully rendered
    if ((know === "present" || know === "past") && streamStatus === "LIVE") {
      initializePlayer();
      if (
        !singleEventData?.live_vidlink.includes("media/hls/master.m3u8") ||
        !singleEventData?.live_vidlink.includes("cloudfront.net/ivs/v1/")
      ) {
        fetchViewerCount();
      }
      setLoadingView(false);
    }
  }, [hlsVideoUrl, streamStatus, know]);

  const renderSpecificBlackScreen = () => {
    if (know === "future") {
      return (
        <div
          style={{
            display: "flex",
            placeItems: "center",
            justifyContent: "center",
            backgroundColor: "#000000",
            width: "100%",
            color: "white",
          }}
          className={styles.videoContainer}
        >
          <h1 className="font-size" style={{ marginRight: "7px" }}>
            Live Scheduled On {singleEventData?.venue_date}
          </h1>
        </div>
      );
    } else if (know === "present") {
      const currentTime = new Date();
      const liveEndTime = new Date(
        `${singleEventData?.venue_date}T${singleEventData?.live_time}`
      );
      return (
        <>
          {loadingView && (
            <div
              style={{
                display: "flex",
                placeItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
                backgroundColor: "#000000",
                width: "100%",
                color: "white",
              }}
              className={styles.videoContainer}
            >
              <div
                style={{
                  display: "flex",
                  placeItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <div className="font-size">
                  {editPage && loadingView && streamStatus === "OFFLINE" && (
                    <div>
                      {checkRecorded && editPage && streamStatus !== "LIVE" ? (
                        <div style={{ textAlign: "center" }}>
                          <p
                            style={{
                              fontSize: "24px",
                              fontWeight: "bold",
                              marginBottom: "10px",
                              textTransform: "uppercase",
                              letterSpacing: "1px",
                            }}
                          >
                            You Have Finished Live Streaming
                          </p>
                          <p
                            style={{
                              fontSize: "18px",
                              lineHeight: "1.5",
                              marginTop: "0",
                              color: "#ccc",
                            }}
                          >
                            Please click the{" "}
                            <span
                              onClick={() => {
                                const r = updateCustomHls(
                                  singleChannelData && singleChannelData?.id
                                );
                                if (r) {
                                  setTimeout(() => {
                                    window.location.reload();
                                  }, 2000);
                                }
                              }}
                              style={{
                                color: "#1e90ff",
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                            >
                              Stream Recording
                            </span>{" "}
                            button below to get the streamed link.
                          </p>
                        </div>
                      ) : (
                        <p>Start Streaming</p>
                      )}

                      {/* <RiLiveFill
                        className="font-size"
                        style={{ marginRight: "7px" }}
                      />
                      <Atom
                        style={{ marginLeft: "7px" }}
                        variant="spokes"
                        color="#ffffff"
                        // size="medium"
                        className="loader"
                        size="small"
                        speedPlus="2"
                        text=""
                        textColor=""
                      /> */}
                      {/* <p
                        style={{
                          fontSize: "24px",
                          fontWeight: "bold",
                          marginBottom: "10px",
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                        }}
                      >
                        You Have Finished Live Streaming
                      </p>
                      <p
                        style={{
                          fontSize: "18px",
                          lineHeight: "1.5",
                          marginTop: "0",
                          color: "#ccc",
                        }}
                      >
                        Please click the{" "}
                        <span
                          onClick={() => {
                            const r = updateCustomHls(
                              singleChannelData && singleChannelData?.id
                            );
                            if (r) {
                              setTimeout(() => {
                                window.location.reload();
                              }, 2000);
                            }
                          }}
                          style={{
                            color: "#1e90ff",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                        >
                          Stream Recording
                        </span>{" "}
                        button below to get the streamed link.
                      </p> */}
                    </div>
                  )}
                </div>
              </div>
              {weddingPage && (
                <>
                  {currentTime >= liveEndTime ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                    >
                      <p>Please Wait</p>
                      <RiLiveFill
                        className="font-size"
                        style={{ marginRight: "7px" }}
                      />
                      <Atom
                        style={{ marginLeft: "7px" }}
                        variant="spokes"
                        color="#ffffff"
                        // size="medium"
                        className="loader"
                        size="small"
                        speedPlus="2"
                        text=""
                        textColor=""
                      />
                    </div>
                  ) : (
                    <p>Please Visit the Page @ {singleEventData?.live_time}</p>
                  )}
                </>
              )}
            </div>
          )}
          {!loadingView && streamStatus === "OFFLINE" && (
            <div
              style={{
                display: "flex",
                placeItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
                backgroundColor: "#000000",
                width: "100%",
                color: "white",
              }}
              className={styles.videoContainer}
            >
              <div
                style={{
                  display: "flex",
                  placeItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <h1 className="font-size" style={{ marginRight: "7px" }}>
                  Live Ended
                </h1>
              </div>
            </div>
          )}
          <div>
            <div
              style={{
                placeItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
                backgroundColor: "#000000",
                width: "100%",
                display:
                  !loadingView && streamStatus === "LIVE" ? "flex" : "none",
              }}
              data-vjs-player
            >
              <video
                ref={videoRef}
                className={`video-js vjs-default-skin ${styles.hlsPlayerStyles}`}
                controls
                preload="auto"
              />
              {error && <div className={styles.errorText}>{error}</div>}
            </div>
            {(!singleEventData?.live_vidlink.includes(
              "media/hls/master.m3u8"
            ) ||
              !singleEventData?.live_vidlink.includes(
                "cloudfront.net/ivs/v1/"
              )) &&
              !singleChannelData?.recording_playback_url &&
              singleEventData?.channattahed && (
                <div
                  style={{
                    marginTop: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <button
                    style={{
                      padding: "8px 16px",
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: "#ffffff",
                      backgroundColor: "#007bff",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                      transition:
                        "background-color 0.3s ease, transform 0.2s ease",
                    }}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#0056b3")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "#007bff")
                    }
                    onMouseDown={(e) =>
                      (e.target.style.backgroundColor = "#004080")
                    }
                    onMouseUp={(e) =>
                      (e.target.style.backgroundColor = "#0056b3")
                    }
                  >
                    {streamStatus ? streamStatus : "OFFLINE"}
                  </button>
                  {checkRecorded && editPage && streamStatus === "OFFLINE" ? (
                    <div>
                      <button
                        style={{
                          padding: "8px 16px",
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "#ffffff",
                          backgroundColor: "#28a745",
                          border: "none",
                          borderRadius: "4px",
                          cursor: "pointer",
                          transition:
                            "background-color 0.3s ease, transform 0.2s ease",
                        }}
                        onMouseOver={(e) =>
                          (e.target.style.backgroundColor = "#1e7e34")
                        }
                        onMouseOut={(e) =>
                          (e.target.style.backgroundColor = "#28a745")
                        }
                        onMouseDown={(e) =>
                          (e.target.style.backgroundColor = "#155d27")
                        }
                        onMouseUp={(e) =>
                          (e.target.style.backgroundColor = "#1e7e34")
                        }
                        onClick={() => {
                          const r = updateCustomHls(
                            singleChannelData && singleChannelData?.id
                          );
                          if (r) {
                            setTimeout(() => {
                              window.location.reload();
                            }, 2000);
                          }
                        }}
                      >
                        Stream Recording
                      </button>
                    </div>
                  ) : (
                    <button
                      style={{
                        padding: "8px 16px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        color: "#ffffff",
                        backgroundColor: "#28a745",
                        border: "none",
                        borderRadius: "4px",
                        cursor: "pointer",
                        transition:
                          "background-color 0.3s ease, transform 0.2s ease",
                      }}
                      onMouseOver={(e) =>
                        (e.target.style.backgroundColor = "#1e7e34")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.backgroundColor = "#28a745")
                      }
                      onMouseDown={(e) =>
                        (e.target.style.backgroundColor = "#155d27")
                      }
                      onMouseUp={(e) =>
                        (e.target.style.backgroundColor = "#1e7e34")
                      }
                    >
                      Views: {streamStatus === "OFFLINE" ? 0 : viewersCount}
                    </button>
                  )}
                </div>
              )}
          </div>
        </>
      );
    } else if (know === "past") {
      return (
        <>
          {loadingView && (
            <div
              style={{
                display: "flex",
                placeItems: "center",
                flexDirection: "column",
                justifyContent: "center",
                gap: "10px",
                backgroundColor: "#000000",
                width: "100%",
                color: "white",
              }}
              className={styles.videoContainer}
            >
              <div
                style={{
                  display: "flex",
                  placeItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <h1 className="font-size" style={{ marginRight: "7px" }}>
                  {streamStatus === "OFFLINE"
                    ? "Your Streaming is Ended"
                    : "Fetching the Source"}
                </h1>

                {streamStatus === "OFFLINE" ? null : (
                  <>
                    <RiLiveFill
                      className="font-size"
                      style={{ marginRight: "7px" }}
                    />
                    <Atom
                      style={{ marginLeft: "7px" }}
                      variant="spokes"
                      color="#ffffff"
                      // size="medium"
                      className="loader"
                      size="small"
                      speedPlus="2"
                      text=""
                      textColor=""
                    />
                  </>
                )}
              </div>
            </div>
          )}

          <div
            style={{ display: loadingView ? "none" : "flex" }}
            data-vjs-player
          >
            <video
              ref={videoRef}
              className={`video-js vjs-default-skin ${styles.hlsPlayerStyles}`}
              controls
              preload="auto"
            />
            {error && <div className={styles.errorText}>{error}</div>}
          </div>
        </>
      );
    }
  };

  const [liveTime, setLiveTime] = useState(false);

  useEffect(() => {
    if (
      (know === "present" &&
        !singleEventData?.live_vidlink.includes("media/hls/master.m3u8")) ||
      !singleEventData?.live_vidlink.includes("cloudfront.net/ivs/v1/")
    ) {
      const liveDateTime = new Date(
        `${singleEventData?.venue_date}T${singleEventData?.live_time}`
      );
      // const liveDateTime = new Date("Fri Jan 03 2025 13:23:06");
      // Add 20 minutes to liveTime
      // const extendedLiveTime = new Date(
      //   liveDateTime.getTime() + 40 * 60 * 1000
      // );
      const extendedLiveTime = new Date(liveDateTime.getTime());
      // Get the current time
      const currentTime = new Date();
      // Check if liveTime + 20 minutes is finished
      const isLiveFinished = currentTime > extendedLiveTime;
      setLiveTime(isLiveFinished);
    }
  }, [singleEventData?.live_time, know]);

  // console.log(openliveComplitionDialog, editPage, know === "present", liveTime);

  return (
    <>
      {renderSpecificBlackScreen()}
      {/* <Dialog
        open={
          openliveComplitionDialog &&
          editPage &&
          !singleChannelData?.recording_playback_url &&
          checkRecorded &&
          know === "present" &&
          !singleEventData?.live_vidlink.includes("media/hls/master.m3u8") &&
          !singleEventData?.live_vidlink.includes("cloudfront.net/ivs/v1/") &&
          singleChannelData?.recording &&
          liveTime
        }
        onClose={() => {
          setOpenliveComplitionDialog(false);
        }}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "15px",
            padding: "20px",
            backgroundColor: "#f9f9f9",
          },
        }}
      >
        <DialogTitle
          sx={{
            fontSize: "18px",
            fontWeight: "bold",
            textAlign: "center",
            color: "#333",
          }}
        >
          Your Stream seems to be in Offline. Please Click Yes to Play Recording
        </DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            marginTop: "15px",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const r = updateCustomHls(
                singleChannelData && singleChannelData?.id
              );
              if (r) {
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }
              setOpenliveComplitionDialog(false);
            }}
            sx={{
              borderRadius: "20px",
              padding: "8px 16px",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            Yes
          </Button>
          {!checkRecorded && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                setOpenliveComplitionDialog(false);
              }}
              sx={{
                borderRadius: "20px",
                padding: "8px 16px",
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              No
            </Button>
          )}
        </div>
      </Dialog> */}
    </>
  );
};
export default HLSPlayer;
//{"error":"'dict' object has no attribute 'delete_channel'"}

import React from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import "./homePage.css";
import { Camera, Share2, Shield, Video } from "lucide-react";

const WhiteLabelSolutions = () => {
  const features = [
    {
      icon: null,
      title: "What do we propose",
      description: (
        <p>
          Get an easy launch and setup with <span>Events on Life</span> and its
          efficient configuring features.
        </p>
      ),
    },
    {
      icon: null,
      title: "Go Live, instantly!",
      description: (
        <p>
          Elevate your content with 's white-labelled events streaming solution.
          Launch your platform instantly and captivate your audience with
          seamless, high-quality service. Make your mark in the world of online
          content with <span>Events on Life</span>.
        </p>
      ),
    },

    {
      icon: null,
      title: "No Hidden Cost",
      description: (
        <p>
          Enjoy transparent pricing with <span>Events on Life's</span> clear-cut
          cost model. Choose to launch on our cloud or host on your premise for
          ultimate flexibility. With <span>Events on Life</span>, you can stream
          anywhere, anytime, without any hidden fees.
        </p>
      ),
    },

    {
      icon: null,
      title: "All-Inclusive Platform",
      description: (
        <p>
          Start small or big, <span>Events on Life</span> adapts to you. With
          apps on all platforms and video encoding plus CDN in 40 locations,
          your content is ready to stream with ease. With Zero Code Setup, you
          focus on business.
        </p>
      ),
    },
    // {
    //   icon: null,
    //   title: "Beautifully Crafted, Solid Foundation. Edge Technology.",
    //   description:
    //     "Elevate your events and wedding live streaming platform with www.eventsonlife.com's customizable, cost-effective white-label solution. Reduce go-to-market time and enjoy flexibility with our scalable offering. Drive engagement and revenue with our powerful analytics and monetization tools. Experience the future of events and wedding live streaming platform with www.eventsonlife.com's custom-fit solution.",
    // },
    {
      icon: null,
      title: (
        <p>
          Why <span>Events on Life</span>
        </p>
      ),
      description: (
        <p>
          <span>Events on Life</span> gives you complete end-to-end assistance
          and the best solution for your White label events and wedding live
          streaming platform.
        </p>
      ),
    },
    {
      icon: null,
      title: "Customizable",
      description: (
        <p>
          Make a statement with <span>Events on Life</span> customizable
          white-label solution. Express your brand in every aspect of your
          events and wedding live streaming platform, from features to design.
          Set your branding and change colours in real-time for Website or your
          apps, anytime.
        </p>
      ),
    },

    {
      icon: null,
      title: "Cost Effective",
      description: (
        <p>
          Maximize your revenue with <span>Events on Life</span> solution. Enjoy
          transparent pricing that includes video encoding and save even more
          with no additional costs.
        </p>
      ),
    },
    {
      icon: null,
      title: "Experienced Leadership",
      description: (
        <p>
          Make a statement with <span>Events on Life's</span> customizable
          white-label solution. Express your brand in every aspect of your
          events and wedding live streaming platform, from features to design.
          Set your branding and change colours in real-time for Website or your
          apps, anytime.
        </p>
      ),
    },
    {
      icon: null,
      title: "Get in touch with our representative!",
      description:
        "We are here to help you with any questions you may have about our products and services. Reach out to us and we'll respond as soon as we can.",
    },
  ];
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };
  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  return (
    <section id="white-label-solution" className="features-section">
      <div className="features-container">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
          className="features-text"
        >
          <motion.h2 variants={itemVariants} className="features-heading">
            White Label Solution
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="page-paragraph"
          >
            Experience the highest quality of video streaming with the best
            cloud-based streaming solution for your{" "}
            <strong style={{ color: "black" }}>EVENTS AND WEDDING</strong>{" "}
            needs. Enjoy the ultimate viewing experience with Events on Life.
          </motion.p>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className="label-features-grid"
        >
          {features.map((feature, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="label-feature-card"
            >
              {/* <div className="label-feature-icon-wrapper">{feature.icon}</div> */}
              <div>
                <h3 className="label-feature-title">{feature.title}</h3>
                <p className="labelfeature-description-text">
                  {feature.description}
                </p>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

export default WhiteLabelSolutions;
